<template>
  <div class="flex-1 w-full px-4">
    <div class="table-scroll">
      <nav class="nav-search">
        <div class="group-btn-right">
          <a-input-group class="search" compact>
            <a-select
              class="select-search"
              v-model:value="typesearch"
              :options="options_search"
            />
            <a-input
              v-model:value="search"
              placeholder="Fill in and search"
              class="input-search"
            />
          </a-input-group>
          <a-select
            v-if="options_eventname.length > 1"
            class="select-search"
            style="width: 160px"
            v-model:value="eventName"
            placeholder="Event Name"
            :options="options_eventname"
          />
          <div class="wrap-date-filter">
            <a-button class="btn-date-filter" @click="toggleDateFilter()">
              {{ dateFilterValue }}
              <calendar-outlined />
            </a-button>
            <DateFilter
              v-model:value="showDateFilter"
              :hasCalendar="true"
              @selectRange="selectRange($event)"
              @selectCalendar="selectCalendar($event)"
              @reset="resetDatefilter($event)"
              @handleCloseDaterange="showDateFilter = false"
            />
          </div>
          <a-select
            class="select-search"
            style="width: 150px"
            v-model:value="statussearch"
            placeholder="All Status"
            :options="statusdata_dropdown"
          />
          <a-button
            :disabled="(startValue && !endValue) || (!startValue && endValue)"
            type="primary"
            class="btn-search"
            @click="clicksearch()"
            ><search-outlined />Search</a-button
          >
        </div>
      </nav>
      <div class="wrap-table-level" @scroll="handleScroll">
        <a-table
          row-key="id"
          class="fixed-head"
          :dataSource="dataReceiptList"
          :columns="columnsReceipt"
          :pagination="false"
        >
          <template #user_id="{ record }">
            <div>{{ record.user_id }}</div>
            <div>{{ record.first_name }} {{ record.last_name }}</div>
            <!-- <div>
              {{
                record.contact_number
                  ? contactNumber(record.contact_number)
                  : '-'
              }}
            </div> -->
          </template>
          <template #status="{ record }">
            <template v-for="(item, index) in status">
              <a-tag
                v-if="record.status == item.status"
                :key="index"
                :color="item.color == 'default' ? null : item.color"
                class="tag-status"
              >
                <a-badge :status="item.color" :text="item.name" />
              </a-tag>
            </template>
          </template>
          <template #upload_date="{ record }">
            {{ formatDateWithTime(record.upload_date) }}
          </template>
          <template #receipt_no="{ record }">
            {{ record.receipt_no || '-' }}
          </template>
          <template #amount="{ record }">
            {{ record.amount ? record.amount.toLocaleString() : '-' }}
          </template>
          <template #action="{ record }">
            <a-tooltip placement="bottom">
              <template #title>
                <span>View</span>
              </template>
              <a class="text-link" @click="showDetail(record)"
                ><eye-outlined
              /></a>
            </a-tooltip>
          </template>
        </a-table>
      </div>
    </div>
  </div>
  <DisplayFieldsApproveReceipt
    :value="showDisplayFieldsApproveReceipt"
    :dataDetailReceipt="dataDetailReceipt"
    @callRefresh="callRefresh()"
    @handleClose="showDisplayFieldsApproveReceipt = false"
  >
  </DisplayFieldsApproveReceipt>
</template>
<script>
// import Footer from '@/components/Layout/Footer/Footer.vue'
import ConfigManager from '@/config/ConfigManager.js'
import _ from 'lodash'
import BzbsUser from '@/core/UserManagement/callapi/BzbsUser'
import moment from 'moment'
import dayjs from 'dayjs'
import DateFilter from '@/module/User/component/DateFilter.vue'
import Util from '@/helper/Utility.js'
import DisplayFieldsApproveReceipt from '@/module/Setting/ApproveList/component/DisplayFieldsApproveReceipt.vue'
import { CalendarOutlined, SearchOutlined } from '@ant-design/icons-vue'
import { config } from '@/config/bzbsconfig.js'
import CacheHelper from '@/helper/CacheHelper.js'
var Cache = new CacheHelper(ConfigManager.getCacheType())

export default {
  name: 'UserList',
  components: {
    // Footer,
    DateFilter,
    CalendarOutlined,
    SearchOutlined,
    DisplayFieldsApproveReceipt,
  },
  data: function () {
    return {
      datapermissionbyapp: {},
      detailReceipt: {},
      local_permissionbyapp:
        localStorage.getItem('permissionbyapp') == 'undefined'
          ? null
          : JSON.parse(localStorage.getItem('permissionbyapp')),
      local_detailReceipt:
        localStorage.getItem('detailReceipt') == 'undefined'
          ? null
          : JSON.parse(localStorage.getItem('detailReceipt')),
      showDisplayFieldsApproveReceipt: false,
      dataDetailReceipt: [],
      startValue: null,
      endValue: null,
      startdate: null,
      enddate: null,
      endOpen: false,
      overlaytablelist: false,
      page: 1,
      search: null,
      nomore: false,
      overlaytable: false,
      managepermission: true,
      showDisplayFieldsApproveDataPrivacy: false,
      searchObject: {
        user_id: '',
        contact_number: '',
        receipt_no: '',
        campaign_id: '',
      },
      status: [
        {
          id: 1,
          status: 0,
          name: 'Waiting',
          color: 'yellow',
        },
        {
          id: 2,
          status: 1,
          name: 'Approve',
          color: 'green',
        },
        {
          id: 3,
          status: -1,
          name: 'Reject',
          color: 'red',
        },
      ],
      statusdata: null,
      statusdata_dropdown: [
        {
          label: 'All Status',
          value: '',
        },
        {
          label: 'Waiting',
          value: 'Waiting',
        },
        {
          label: 'Approved',
          value: 'Approved',
        },
        {
          label: 'Rejected',
          value: 'Reject',
        },
      ],
      typesearch: 'user_id',
      options_search: [
        {
          label: 'User ID',
          value: 'user_id',
        },
        {
          label: 'Contact number',
          value: 'contact_number',
        },
        {
          label: 'Receipt No',
          value: 'receipt_no',
        },
        {
          label: 'Campaign ID',
          value: 'campaign_id',
        },
      ],
      columnsReceipt: [
        {
          title: 'Status',
          dataIndex: 'status',
          slots: { customRender: 'status' },
          key: 'status',
          width: 100,
          align: 'left',
        },
        {
          title: 'Upload Date',
          dataIndex: 'upload_date',
          slots: { customRender: 'upload_date' },
          key: 'upload_date',
          width: 100,
          align: 'left',
        },
        {
          title: 'Transaction ID',
          dataIndex: 'row_key',
          slots: { customRender: 'row_key' },
          key: 'row_key',
          width: 100,
          align: 'left',
        },
        {
          title: 'Receipt No. / Invoice No.',
          dataIndex: 'receipt_no',
          slots: { customRender: 'receipt_no' },
          key: 'receipt_no',
          width: 100,
          align: 'left',
        },
        {
          title: 'User',
          dataIndex: 'user_id',
          slots: { customRender: 'user_id' },
          key: 'user_id',
          width: 160,
          align: 'left',
        },
        {
          title: 'Amount (THB)',
          dataIndex: 'amount',
          slots: { customRender: 'amount' },
          key: 'amount',
          width: 100,
          align: 'right',
        },
        {
          title: 'Action',
          dataIndex: 'action',
          slots: { customRender: 'action' },
          key: 'action',
          width: 80,
          align: 'center',
        },
      ],
      datalists: [],
      dataReceiptList: [],
      formatdate: 'DD/MMM/YYYY',
      showDateFilter: false,
      dateFilterValue: 'Date Range',
      dataDetails: [],
      id: '',
      datalist: [],
      showModalConfirm: false,
      options_eventname: [],
      eventName: null,
      statussearch: '',
      selected: [],
      dataDetailItem: [],
      isCheckActive: '',
      activeKeyuser: '1',
      activeKeyCam: '2',
      modalReject: false,
      remark: '',
      dataRemark: [],
      noData: false,
      imgEmpty: config.bzbBlobUrl + '/themebackoffice/assets/3/img/empty.svg',
    }
  },
  computed: {},
  mounted() {
    this.$store.commit('setLoading', false)
    if (this.$route.query.tab == 'receipt') {
      if (this.local_detailReceipt) {
        if (this.local_permissionbyapp) {
          this.datapermissionbyapp = this.local_permissionbyapp
        }
        this.detailReceipt = this.local_detailReceipt
        this.getListEventName().then(() => {
          this.typesearch = this.detailReceipt?.typesearch || ''
          this.search = this.detailReceipt?.search || ''
          this.eventName = this.detailReceipt
            ? this.detailReceipt?.eventName
            : this.eventName
          this.startdate = this.detailReceipt
            ? dayjs(this.detailReceipt.startdate).format('YYYY-MM-DD')
            : moment().format('YYYY-MM-DD')
          this.enddate = this.detailReceipt
            ? dayjs(this.detailReceipt.enddate).format('YYYY-MM-DD')
            : moment().format('YYYY-MM-DD')
          this.statussearch = this.detailReceipt?.statussearch
          this.page = this.detailReceipt?.page || 1
          this.dateFilterValue = this.detailReceipt?.dateFilterValue
          this.clicksearch()
          localStorage.removeItem('detailReceipt')
        })
      } else {
        this.getListEventName().then(() => {
          this.search = ''
          this.startdate = moment().format('YYYY-MM-DD')
          this.enddate = moment().format('YYYY-MM-DD')
          this.dateFilterValue = 'Today'
          this.statussearch = 'Waiting'
          this.clicksearch()
        })
      }
    }
  },
  watch: {
    $route(to) {
      if (to.query.tab == 'receipt') {
        if (localStorage.getItem('detailReceipt')) {
          console.log('this.detailReceipt', this.detailReceipt)
          if (this.local_permissionbyapp) {
            this.datapermissionbyapp = this.local_permissionbyapp
          }
          if (this.local_detailReceipt) {
            this.detailReceipt = this.local_detailReceipt
          }
          this.getListEventName().then(() => {
            this.typesearch = this.detailReceipt?.typesearch || ''
            this.search = this.detailReceipt?.search || ''
            this.eventName = this.detailReceipt
              ? this.detailReceipt?.eventName
              : this.eventName
            this.startdate = this.detailReceipt
              ? dayjs(this.detailReceipt.startdate).format('YYYY-MM-DD')
              : moment().format('YYYY-MM-DD')
            this.enddate = this.detailReceipt
              ? dayjs(this.detailReceipt.enddate).format('YYYY-MM-DD')
              : moment().format('YYYY-MM-DD')
            this.statussearch = this.detailReceipt?.statussearch || 'Waiting'
            this.page = this.detailReceipt?.page || 1
            this.dateFilterValue =
              this.detailReceipt?.dateFilterValue || 'Today'
            this.clicksearch()
            localStorage.removeItem('detailReceipt')
          })
        } else {
          this.getListEventName().then(() => {
            this.search = ''
            this.startdate = moment().format('YYYY-MM-DD')
            this.enddate = moment().format('YYYY-MM-DD')
            this.dateFilterValue = 'Today'
            this.statussearch = 'Waiting'
            this.clicksearch()
          })
        }
      }
    },
    local_permissionbyapp: function () {
      this.datapermissionbyapp = this.local_permissionbyapp
    },
    local_detailReceipt: function () {
      this.detailReceipt = this.local_detailReceipt
    },
  },
  methods: {
    showDetail(record) {
      record.typesearch = this.typesearch || ''
      record.search = this.search || ''
      record.startdate =
        this.startdate || '' ? dayjs(this.startdate).format('YYYY-MM-DD') : ''
      record.enddate = this.enddate
        ? dayjs(this.enddate).format('YYYY-MM-DD')
        : ''
      record.statussearch = this.statussearch || ''
      record.page = this.page
      record.dateFilterValue = this.dateFilterValue
      record.eventName = this.eventName
      localStorage.setItem('detailReceipt', JSON.stringify(record))
      this.$router.push({
        name: 'approvereceiptdetail',
      })
    },
    onSelectList(item) {
      this.$store.commit('setLoading', true)
      this.isCheckActive = item._id
      this.selected = item
      this.dataDetailItem = item
      this.getRemark().then(() => {
        this.$store.commit('setLoading', false)
      })
    },
    callRefresh() {
      this.$store.commit('setLoading', true)
      this.getListEventName().then(() => {
        this.clicksearch()
      })
    },
    convertDate(date) {
      return Util.convertDate(date)
    },
    contactNumber(number) {
      return Util.contactNumberFormat(number, '-')
    },
    toggleApproveReceipt(record) {
      this.dataDetailReceipt = record
      this.showDisplayFieldsApproveReceipt =
        !this.showDisplayFieldsApproveReceipt
    },
    clicksearch() {
      this.$store.commit('setLoading', true)
      this.page = 1
      this.showDateFilter = false
      this.getReceiptslist().then(() => {
        this.$store.commit('setLoading', false)
      })
    },
    getReceiptslist() {
      this.$store.commit('setLoading', true)
      let appid = ConfigManager.getAppId()
      return new Promise((resolve, reject) => {
        BzbsUser.apiGetReceipts(
          appid,
          (this.searchObject.user_id =
            this.typesearch == 'user_id' ? this.search : ''),
          this.eventName,
          (this.searchObject.receipt_no =
            this.typesearch == 'receipt_no' ? this.search : ''),
          (this.searchObject.contact_number =
            this.typesearch == 'contact_number' ? this.search : ''),
          (this.searchObject.campaign_id =
            this.typesearch == 'campaign_id' ? this.search : ''),
          (this.searchObject.startdate = this.startdate
            ? dayjs(this.startdate).format('YYYY-MM-DD')
            : ''),
          (this.searchObject.enddate = this.enddate
            ? dayjs(this.enddate).format('YYYY-MM-DD')
            : ''),
          this.statussearch,
          this.page,
          '',
        ).subscribe(
          data => {
            if (this.page == 1) {
              this.dataReceiptList = []
            }
            _.forEach(data.result, item => {
              this.dataReceiptList.push(item)
            })
            // this.dataReceiptList.sort((a, b) => {
            //   return b.upload_date - a.upload_date
            // })
            let total = data.result.length
            if (total > 20) {
              this.nomore = true
            } else {
              if (data.result.length < 20) {
                this.nomore = true
              } else {
                this.nomore = false
              }
            }
            this.$store.commit('setLoading', false)
            resolve(this.dataReceiptList)
          },
          err => {
            this.noData = true
            this.$store.commit('setLoading', false)
            reject(err)
          },
        )
      })
    },
    handleScroll(event) {
      if (!this.nomore && !this.overlaytable) {
        if (
          event.target.offsetHeight + event.target.scrollTop + 5 >=
          event.target.scrollHeight
        ) {
          this.overlaytable = true
          this.page++
          this.getReceiptslist().then(() => {
            this.overlaytable = false
          })
        }
      }
    },
    getListEventName() {
      let appid = ConfigManager.getAppId()
      return new Promise((resolve, reject) => {
        BzbsUser.apiGetListEventName(appid).subscribe(
          data => {
            this.eventName = data.result[0].event_name
            let dataoption = data.result
            _.forEach(dataoption, (item, index) => {
              this.options_eventname[index] = {
                label: item.event_name,
                value: item.event_name,
              }
            })
            resolve(data)
          },
          err => {
            this.errorApi(err)
            reject(err)
          },
        )
      })
    },
    disabledStartDate(startdate) {
      return startdate && startdate >= moment().endOf('Day')
    },
    disabledEndDate(endValue) {
      if (!endValue || !this.startValue) {
        return false
      }
      return (
        this.startValue.valueOf() >= endValue.valueOf() + 1 ||
        endValue >= moment().endOf('Day')
      )
    },
    handleStartOpenChange(open) {
      if (!open) {
        this.endOpen = true
      }
    },
    handleEndOpenChange(open) {
      this.endOpen = open
    },
    toggleApproveListByID(id) {
      this.id = id
      this.getdataDetails().then(() => {
        this.checkData()
        this.showDisplayFieldsApproveDataPrivacy =
          !this.showDisplayFieldsApproveDataPrivacy
      })
    },
    toggleDateFilter() {
      this.showDateFilter = !this.showDateFilter
    },
    resetDatefilter() {
      this.dateFilterValue = 'Date Range'
      this.startdate = ''
      this.enddate = ''
    },
    selectCalendar(range) {
      if (range.start && range.end) {
        this.startdate = dayjs(range.start).format(this.formatdate)
        this.enddate = dayjs(range.end).format(this.formatdate)
        this.dateFilterValue = this.startdate + ' to ' + this.enddate
      }
    },
    selectRange(range) {
      console.log(range)
      this.dateFilterValue = range.value
      var params = {}
      if (range.value == 'Today') {
        params.startdate = moment().startOf('day').format(this.formatdate)
        params.enddate = moment().endOf('day').format(this.formatdate)
      }
      if (range.value == 'This Week') {
        params.startdate = moment().startOf('week').format(this.formatdate)
        params.enddate = moment().endOf('week').format(this.formatdate)
      }
      if (range.value == 'Last Week') {
        params.startdate = moment()
          .startOf('week')
          .subtract(7, 'days')
          .format(this.formatdate)
        params.enddate = moment()
          .endOf('week')
          .subtract(7, 'days')
          .format(this.formatdate)
      }
      if (range.value == 'This Month') {
        params.startdate = moment().startOf('month').format(this.formatdate)
        params.enddate = moment().endOf('month').format(this.formatdate)
      }
      if (range.value == 'Last Month') {
        params.startdate = moment()
          .startOf('month')
          .subtract(1, 'months')
          .format(this.formatdate)
        params.enddate = moment()
          .add(-1, 'months')
          .endOf('month')
          .format(this.formatdate)
      }
      if (range.value == 'Last 3 Weeks') {
        params.startdate = moment()
          .startOf('week')
          .subtract(21, 'days')
          .format(this.formatdate)
        params.enddate = moment()
          .endOf('week')
          .subtract(7, 'days')
          .format(this.formatdate)
      }
      if (range.value == 'Last 6 Weeks') {
        params.startdate = moment()
          .startOf('week')
          .subtract(42, 'days')
          .format(this.formatdate)
        params.enddate = moment()
          .endOf('week')
          .subtract(7, 'days')
          .format(this.formatdate)
      }
      if (range.value == 'Last 3 Months') {
        params.startdate = moment()
          .startOf('month')
          .subtract(3, 'months')
          .format(this.formatdate)
        params.enddate = moment()
          .endOf('month')
          .subtract(1, 'months')
          .format(this.formatdate)
      }
      if (range.value == 'Last 6 Months') {
        params.startdate = moment()
          .startOf('month')
          .subtract(6, 'months')
          .format(this.formatdate)
        params.enddate = moment()
          .endOf('month')
          .subtract(1, 'months')
          .format(this.formatdate)
      }
      this.startdate = params.startdate
      this.enddate = params.enddate
    },
    setmanagepermission() {
      if (this.datapermission.features.every(x => x.name != 'Manage-Privacy')) {
        return (this.managepermission = false)
      }
    },
    confirmAction(action) {
      this.action = action
      this.remark = ''
      this.modalReject = true
    },
    confirmRejectorResubmit() {
      if (this.action == 'Reject') {
        this.PostReject()
      } else {
        this.PostResubmit()
      }
    },
    Approve() {
      this.$store.commit('setLoading', true)
      this.PostApprove().then(() => {
        this.$store.commit('setLoading', false)
        this.$success({
          title: 'Success',
          okType: 'default',
          centered: true,
          onOk: () => {
            this.remark = ''
            window.location.reload()
          },
        })
      })
    },
    PostApprove() {
      this.$store.commit('setLoading', true)
      var data = {
        appid: Cache.getCache('app').id,
        user_id: this.dataDetailItem.user_id ? this.dataDetailItem.user_id : '',
        event_name: this.dataDetailItem._id
          ? this.dataDetailItem._id.split('|')[1]
          : '',
        receipt_rowkey: this.dataDetailItem.row_key
          ? this.dataDetailItem.row_key
          : '',
      }
      return new Promise((resolve, reject) => {
        BzbsUser.apiPostApproveReceipt(data)
          .then(res => {
            resolve(res.data.data)
          })
          .catch(error => {
            this.errorApi(error)
            reject(error)
          })
      })
    },
    Reject() {
      this.$store.commit('setLoading', true)
      this.PostReject().then(() => {
        this.$store.commit('setLoading', false)
        this.$success({
          title: 'Success',
          okType: 'default',
          centered: true,
          onOk: () => {
            window.location.reload()
            this.remark = ''
          },
        })
      })
    },
    PostReject() {
      this.$store.commit('setLoading', true)
      var data = {
        appid: Cache.getCache('app').id,
        user_id: this.dataDetailItem.user_id ? this.dataDetailItem.user_id : '',
        event_name: this.dataDetailItem._id
          ? this.dataDetailItem._id.split('|')[1]
          : '',
        receipt_rowkey: this.dataDetailItem.row_key
          ? this.dataDetailItem.row_key
          : '',
        remark: this.remark,
      }
      this.modalReject = false
      return new Promise((resolve, reject) => {
        BzbsUser.apiPostRejectReceipt(data)
          .then(res => {
            this.$store.commit('setLoading', false)
            this.$success({
              title: 'Success',
              okType: 'default',
              centered: true,
              onOk: () => {
                this.remark = ''
                window.location.reload()
              },
            })
            resolve(res.data.data)
          })
          .catch(error => {
            this.errorApi(error)
            reject(error)
          })
      })
    },
    PostResubmit() {
      this.$store.commit('setLoading', true)
      var data = {
        appid: Cache.getCache('app').id,
        user_id: this.dataDetailItem.user_id ? this.dataDetailItem.user_id : '',
        event_name: this.dataDetailItem._id
          ? this.dataDetailItem._id.split('|')[1]
          : '',
        receipt_rowkey: this.dataDetailItem.row_key
          ? this.dataDetailItem.row_key
          : '',
        remark: this.remark,
      }
      this.modalReject = false
      return new Promise((resolve, reject) => {
        BzbsUser.apiPostResubmitReceipt(data)
          .then(res => {
            this.$store.commit('setLoading', false)
            this.$success({
              title: 'Success',
              okType: 'default',
              centered: true,
              onOk: () => {
                this.remark = ''
                window.location.reload()
              },
            })
            resolve(res.data.data)
          })
          .catch(error => {
            this.errorApi(error)
            reject(error)
          })
      })
    },
    handleCancel() {
      this.modalReject = false
    },
    getRemark() {
      let appid = ConfigManager.getAppId()
      return new Promise((resolve, reject) => {
        BzbsUser.apiGetReceiptsApproveDetail(
          appid,
          this.dataDetailItem.partition_key,
          this.dataDetailItem.row_key,
        ).subscribe(
          data => {
            this.dataRemark = data.result
            resolve(this.dataRemark)
          },
          err => {
            this.dataRemark = null
            this.$store.commit('setLoading', false)
            console.log(this.dataRemark)
            reject(err)
          },
        )
      })
    },
  },
}
</script>
<style lang="scss" scoped>
@import '@/style/base/base.scss';

.approvepoint {
  overflow-x: hidden;

  .table-scroll {
    // background: #fff;
    overflow-x: hidden;
    overflow-y: hidden;
    border-radius: 6px;
    min-height: calc(100vh - 230px);
    max-height: calc(100vh - 230px);
  }

  .bg-list {
    overflow-x: hidden;
    overflow-y: auto;
    border-radius: 6px;
    min-height: calc(100vh - 300px);
    max-height: calc(100vh - 300px);
  }

  .bg-detail {
    overflow: hidden;
    border-radius: 6px;
    min-height: calc(100vh - 360px);
    max-height: calc(100vh - 360px);
  }

  .h-date {
    padding-top: 15px;
    padding-bottom: 15px;
    border-bottom: 1px solid #e3e3e3;
  }

  .img-detail {
    background-color: #f5f5f5;
  }

  .nav-search {
    background-color: #fff;
    display: block;
    width: 100%;
    border-bottom: 15px solid #f9f9f9;
    padding: 10px 10px;
    float: left;

    .search {
      float: left;
      width: auto;

      .input-search {
        width: 200px;
        border-left: none;
      }

      .select-search {
        width: 130px;
      }
    }

    .wrap-date-filter {
      float: left;
      // min-width: 290px;
      position: relative;
    }

    .wrap-filter {
      float: left;
      position: relative;
    }

    .btn-date-filter {
      width: 260px;
      text-align: left;

      .anticon {
        float: right;
        margin-top: 2px;
      }
    }

    .group-btn-right {
      display: flex;
      float: right;
      gap: 10px;
      flex-wrap: wrap;

      .btn-search {
        margin-right: 10px;
        margin-left: 10px;
      }
    }
  }

  .wrap-table-level {
    display: block;
    width: 100%;
    max-height: calc(100vh - 315px);
    overflow-x: auto;

    .ant-table-thead > tr > th {
      padding: 15px 15px !important;
    }
  }

  .ant-table-tbody {
    > tr td {
      padding: 15px 15px !important;
    }
  }

  .font-level {
    color: #f9a601;
    text-decoration: underline;
  }

  .ant-table-thead {
    .ant-table-cell {
      .title {
        display: inline-block;
      }

      .wrap-sort {
        display: inline-block;
        padding: 0 5px;
        line-height: 0.5em;
        cursor: pointer;

        span {
          display: none;
          padding: 0;
          position: relative;
          top: 3px;
          cursor: pointer;

          &.active {
            display: block;
            color: $color-primary-light-2;
          }
        }
      }

      &:hover {
        .wrap-sort span {
          display: inline-block;

          &:hover {
            color: $color-primary-light-2;
          }
        }
      }
    }
  }
}
</style>
<style lang="scss">
.main-menu {
  .datefilter {
    right: 0;
    left: unset;
  }
}

.approvepoint {
  .ant-table-row {
    vertical-align: top !important;
  }

  .ant-table-thead {
    vertical-align: top !important;
  }
  .ml-10 {
    margin-left: 10px;
  }
}
.item-list-receipt {
  display: flex;
  flex-wrap: wrap;
  margin-top: 4px;
  .title {
    color: #aaaaaa;
    width: 120px;
  }
}
.isactive-list {
  background-color: #fff6e6;
  border-right: 2px solid #feca62;
}
.bg-detail {
  .ant-image {
    position: relative !important;
    display: inline-block !important;
    height: 100% !important;
    width: 100% !important;
  }
  .ant-image-img {
    width: 100% !important;
    height: 100% !important;
  }
}
.detail-con {
  overflow-x: hidden;
  overflow-y: auto;
  border-radius: 6px;
  min-height: calc(100vh - 480px);
  max-height: calc(100vh - 480px);
}
.detail-con-action {
  overflow-x: hidden;
  overflow-y: auto;
  border-radius: 6px;
  min-height: calc(100vh - 295px);
  max-height: calc(100vh - 295px);
}
.receipt {
  @media only screen and (max-width: 1545px) {
    .ant-btn {
      width: 20px !important;
    }
  }
}
</style>
