<template>
  <div
    class="displayfield fixed top-0 right-0 h-screen z-40"
    :style="containerStyle"
  >
    <transition
      enter-active-class="transition ease-out duration-500"
      enter-from-class="transform-gpu translate-x-full"
      enter-to-class="transform-gpu translate-x-0"
      leave-active-class="transition ease-in duration-300"
      leave-from-class="transform-gpu translate-x-0"
      leave-to-class="transform-gpu translate-x-full"
    >
      <div
        class="h-full shadow-xl w-80 bg-white border-l"
        :style="mainBlockStyle"
        v-if="value"
        v-click-away="onClickAway"
      >
        <div class="flex divide-y-2 flex-col h-full wrapper-display-fields">
          <div class="panel-header flex-none">
            <div class="flex-grow font-semibold">Display Fields</div>
            <div class="flex-none btn-dismiss" @click="dismiss(true)">
              <img class="icon" src="@/assets/icons/button/btn-close.svg" alt />
            </div>
          </div>
          <div class="border-b flex-auto overflow-y-auto">
            <div class="p-4">
              <div class="v-list">
                <div class="mb-4 flex">
                  <v-checkbox
                    v-model:value="selectAll"
                    label="All"
                    class="checkbox-display-Fields pointer select-all"
                    @change="toggleSelectAll()"
                  ></v-checkbox>
                </div>
                <draggable
                  v-model="myArray"
                  class="pointer"
                  group="people"
                  @start="drag = true"
                  @end="drag = false"
                  item-key="key"
                >
                  <template #item="{ element, index }">
                    <div
                      class="mb-4 flex"
                      :class="{ 'last:mb-0': index == myArray.length - 1 }"
                    >
                      <!-- <v-checkbox
                        v-model:value="
                          columnStates.find(x => x.key == element.key).value
                        "
                        :label="element.title"
                        class="checkbox-display-Fields pointer"
                        @change="checkUIState()"
                      ></v-checkbox> -->
                      <img class="icon" src="@/assets/icons/dragdrop.svg" alt />
                      <v-checkbox
                        v-model:value="
                          columnStates.find(x => x.key == element.response_name)
                            .value
                        "
                        :label="element.display_text"
                        :class="checkdisable(element.response_name)"
                        @change="checkUIState()"
                      ></v-checkbox>
                    </div>
                  </template>
                </draggable>
                <!-- <template
                  v-for="(header, idx) of headers"
                  :key="`displayFields-${idx}`"
                >
                  <div
                    class="mb-4"
                    :class="{ 'last:mb-0': idx == headers.length - 1 }"
                  >
                    <v-checkbox
                      v-model:value="columnStates[idx].value"
                      :label="header.title"
                      class="checkbox-display-Fields"
                      @change="checkUIState()"
                    ></v-checkbox>
                  </div>
                </template> -->
              </div>
            </div>
          </div>
          <div class="flex-none px-4 py-3">
            <a-button type="primary" @click="applyDF(true)"> Apply </a-button>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import VCheckbox from '@/components/Form/Checkbox.vue'
// helper
import Util from '@/helper/Utility'
import draggable from 'vuedraggable'
import BzbsUser from '@/core/UserManagement/callapi/BzbsUser'
import CacheHelper from '@/helper/CacheHelper.js'
import ConfigManager from '@/config/ConfigManager.js'
var Cache = new CacheHelper(ConfigManager.getCacheType())

export default {
  name: 'DisplayFields',
  components: {
    VCheckbox,
    draggable,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    top: {
      type: Number,
      default: 0,
    },
    headers: {
      type: Array,
      default: () => [],
    },
    permissionmaskdata: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    mainBlockStyle: function () {
      return {
        paddingBottom: `${this.top}px`,
      }
    },
    containerStyle: function () {
      return {
        top: `${this.top}px`,
      }
    },
  },
  data: function () {
    return {
      drag: false,
      ops: {
        vuescroll: {},
        scrollPanel: {},
        rail: {},
        bar: {},
      },
      myArray: [
        {
          name: 'test1',
          id: 1,
        },
        {
          name: 'test2',
          id: 2,
        },
        {
          name: 'test3',
          id: 3,
        },
      ],
      columnStates: [],
      selectAll: true,
      oldStates: [],
      fieldfromapi: [],
    }
  },
  watch: {
    value: function (val) {
      if (val) {
        this.setState()
        this.disableScroll()
      } else {
        this.enableScroll()
      }
    },
  },
  mounted() {
    this.getDisplayFields()
  },
  created: function () {
    // this.myArray = this.headers
    this.init()
    // set state when created
    this.setState()
  },
  methods: {
    init: function (defaultValue = true) {
      this.columnStates = this.headers.map(x => {
        if (
          x.key == 'userid' ||
          x.key == 'profile_last_profile' ||
          x.key == 'birthday' ||
          x.key == 'contact_number' ||
          x.key == 'email' ||
          x.key == 'first_name' ||
          x.key == 'last_name'
        ) {
          return { key: x.key, value: true }
        }
        return { key: x.key, value: defaultValue }
      })
      // this.columnStates = this.listdisplayfields.map(x => {
      //   return { key: x.field_name, value: x.visible }
      // })
    },
    testmyarray() {
      console.log(this.myArray)
    },
    checkdisable(key) {
      return [
        'userid',
        'profile_last_profile',
        'birthday',
        'contact_number',
        'email',
        'first_name',
        'last_name',
        'checked',
      ].includes(key)
        ? 'checkbox-display-Fields-disable pointer-events-none'
        : 'checkbox-display-Fields pointer'
    },
    getDisplayFields() {
      return new Promise((resolve, reject) => {
        BzbsUser.apiGetDisplayFields().subscribe(
          res => {
            this.fieldfromapi = res.result
            this.listdisplayfields = res.result
            this.listdisplayfields = this.listdisplayfields.filter(item => {
              return (
                item.response_name != 'profile_last_profile_timestamp' &&
                item.response_name != 'joined_date_timestamp' &&
                item.response_name != 'last_active_timestamp' &&
                item.response_name != 'last_redeem_timestamp' &&
                item.response_name != 'last_scan_timestamp' &&
                item.response_name != 'appid' &&
                item.response_name != 'prefix' &&
                item.response_name != 'timestamp' &&
                item.response_name != 'birthday_timestamp' &&
                item.response_name != 'user_level_names' &&
                item.response_name != 'checked' &&
                item.response_name != 'age' &&
                (!this.permissionmaskdata || item.response_name != 'age')
              )
            })
            this.myArray = this.listdisplayfields
            this.columnStates = this.listdisplayfields.map(x => {
              return { key: x.response_name, value: x.visible }
            })
            this.applyDF()
            resolve(this.listdisplayfields)
          },
          err => {
            this.errorApi(err)
            reject(err)
          },
        )
      })
    },
    UpdateDisplayFields() {
      var xx = []
      this.myArray.forEach((x, index) => {
        var vv = this.fieldfromapi.find(y => y.response_name == x.response_name)
        vv.sequence = index
        xx.push(vv)
      })

      this.fieldfromapi = xx

      this.fieldfromapi.forEach(x => {
        var z = this.columnStates.find(y => y.key == x.response_name)
        if (z) {
          x.visible = z.value
        }
      })

      var accountupdate = {
        appid: Cache.getCache('app').id,
        items: this.fieldfromapi,
      }
      return new Promise((resolve, reject) => {
        BzbsUser.apiPutDisplayFields(accountupdate)
          .then(res => {
            if (
              res.data.errors &&
              res.data.errors == '401' &&
              res.data.message ==
                'A JWT specified in the request header is expired.'
            ) {
              this.errorResponse(res.data, true)
            }
            resolve(res.data.data)
          })
          .catch(error => {
            this.errorApi(error)
            reject(error)
          })
      })
    },
    setState: function () {
      this.oldStates = Util.copyArray(this.columnStates)
      this.checkUIState()
    },
    resetState: function () {
      this.columnStates = [...this.oldStates]
    },
    disableScroll: function () {
      setTimeout(() => {
        document.body.classList.add('overflow-hidden')
      }, 100)
    },
    enableScroll: function () {
      document.body.classList.remove('overflow-hidden')
    },
    onClickAway: function () {
      this.dismiss()
      this.$nextTick(() => {
        this.$emit('onClickAway')
      })
    },
    dismiss: function (flagResetState = true) {
      if (flagResetState) {
        this.resetState()
      } else {
        this.setState()
      }
      this.$emit('handleClose')
    },
    toggleSelectAll: function () {
      if (this.selectAll) {
        // ...
        this.init(true)
      } else {
        // ...
        this.init(false)
      }
    },
    checkUIState: function () {
      let findDeselect = this.columnStates.filter(x => x.value == false)
      if (findDeselect != null && findDeselect.length > 0) {
        this.selectAll = false
      } else {
        this.selectAll = true
      }

      var xx = []
      this.myArray.forEach(x => {
        if (this.columnStates.find(y => y.key == x.response_name)) {
          xx.push(this.columnStates.find(y => y.key == x.response_name))
        }
      })

      this.columnStates = xx

      // this.columnStates = [
      //   ...this.columnStates.filter(x => x.value == true),
      //   ...this.columnStates.filter(x => x.value == false),
      // ]
      var tt = []
      this.columnStates.forEach(x => {
        tt.push(this.myArray.find(y => y.response_name == x.key))
      })
      this.myArray = tt
    },
    applyDF: function (mustUpdate = false) {
      var sort = []

      this.myArray.forEach(item => {
        sort.push(this.columnStates.find(x => x.key == item.response_name))
      })
      if (mustUpdate) {
        this.UpdateDisplayFields().then(() => {
          this.$emit('apply', [...sort])
        })
        this.dismiss(false)
      } else {
        this.$emit('apply', [...sort])
        this.dismiss(false)
      }
    },
  },
}
</script>

<style lang="scss">
.wrapper-display-fields {
  .checkbox-display-Fields-disable {
    .form-checkbox {
      background-color: white;
    }
    .form-checkbox.checked {
      background: url('~@/assets/icons/checkboxdisable.svg') no-repeat !important;
    }
  }
  .ant-btn {
    width: 100%;
  }
}
.pointer {
  cursor: pointer;
}
.select-all {
  padding-left: 14px;
}
</style>
