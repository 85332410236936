<template>
  <div
    v-if="value"
    class="backdrop fixed top-0 left-0 w-full h-full"
    @click="dismiss()"
  ></div>
  <div
    class="displayfield fixed top-0 right-0 h-screen z-40"
    :style="containerStyle"
  >
    <transition
      enter-active-class="transition ease-out duration-500"
      enter-from-class="transform-gpu translate-x-full"
      enter-to-class="transform-gpu translate-x-0"
      leave-active-class="transition ease-in duration-300"
      leave-from-class="transform-gpu translate-x-0"
      leave-to-class="transform-gpu translate-x-full"
    >
      <div
        class="h-full shadow-xl w-log-tranfer bg-white border-l"
        :style="mainBlockStyle"
        v-if="value"
      >
        <div class="flex divide-y-2 flex-col h-full wrapper-display-fields">
          <div class="panel-header flex-none">
            <div class="flex-grow font-semibold d-flex">
              <h4 class="pr-2">Transfer Details</h4>
              <template v-for="(item, index) in status">
                <a-tag
                  v-if="dataDetailTF.status == item.name"
                  :key="index"
                  :color="item.color == 'default' ? null : item.color"
                  class="tag-status"
                >
                  <a-badge
                    :status="item.color"
                    :text="textStatus(dataDetailTF.status)"
                  />
                </a-tag>
              </template>
            </div>
          </div>
          <div class="border-b flex-auto overflow-y-auto">
            <div class="bg-con">
              <div>
                Create Date :
                {{
                  formatDate(dataDetailTF.created_date, 'DD/MMM/YYYY - HH:mm')
                }}
              </div>
              <div>Create By : {{ dataDetailTF.created_by }}</div>
              <hr class="my-2" />
              <div class="d-flex">
                <div class="mr-2">Send Notification :</div>
                <template v-for="(item, index) in status_noti">
                  <a-tag
                    v-if="
                      dataDetailTF.notification.send_notification == item.name
                    "
                    :key="index"
                    :color="item.color == 'default' ? null : item.color"
                    class="tag-status"
                  >
                    <a-badge
                      v-if="
                        dataDetailTF.notification.send_notification == item.name
                      "
                      :status="item.color"
                      :text="item.text"
                    />
                  </a-tag>
                </template>
              </div>

              <div>
                Notification Message :
                {{ dataDetailTF.notification.message || '-' }}
              </div>
            </div>
            <div class="bg-con">
              <div class="d-flex">
                <div class="pr-1">
                  User Main Transfer :
                  <span class="font-bold"
                    >{{ dataDetailTF.transfer_from.user_id }} -
                  </span>
                </div>
                <div
                  v-if="dataDetailTF.transfer_from.name"
                  class="font-bold ml-1"
                >
                  {{ dataDetailTF.transfer_from.name }}
                </div>
              </div>
              <div class="d-flex justify-content-between mt-2">
                <div>Transfer Expiring Points :</div>
                <div class="ml-1">
                  <div
                    v-for="(item, index) of dataDetailTF.transfer_from
                      .transfer_points"
                    :key="index"
                  >
                    <div class="d-flex justify-end gap-2">
                      <div class="fw-700 text-title-point">
                        {{ item.points.toLocaleString('en-US') }} Points
                      </div>
                      <div class="text-light-grey">
                        in
                        {{ formatDate(item.date, 'DD/MMM/YYYY', -7) }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="d-flex justify-content-between">
                <div>Transfer :</div>
                <div class="font-bold pl-1">
                  {{ dataDetailTF.points.toLocaleString() }} Points
                </div>
              </div>
            </div>
            <div class="px-4 d-flex gap-2">
              <arrow-down-outlined />
              <arrow-down-outlined />
            </div>
            <div class="bg-con">
              <div class="d-flex">
                <div class="pr-1">
                  User Transfer To :
                  <span class="font-bold text-active"
                    >{{ dataDetailTF.transfer_to.user_id }} -
                  </span>
                </div>
                <div
                  v-if="dataDetailTF.transfer_to.name"
                  class="font-bold ml-1 text-active"
                >
                  {{ dataDetailTF.transfer_to.name }}
                </div>
              </div>
              <div>
                <div class="d-flex justify-content-between mt-2">
                  <div>Current Expiring Points :</div>
                  <div class="ml-1">
                    <div
                      v-for="(item, index) of dataDetailTF.transfer_to
                        .current_points"
                      :key="index"
                    >
                      <div class="d-flex justify-end gap-2">
                        <div class="fw-700 text-title-point">
                          {{ item.points.toLocaleString('en-US') }} Points
                        </div>
                        <div class="text-light-grey">
                          in
                          {{ formatDate(item.date, 'DD/MMM/YYYY', -7) }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="d-flex justify-content-between">
                <div>Total Current :</div>
                <div class="font-bold pl-1">
                  {{
                    currentPoint(
                      dataDetailTF.transfer_to.current_points,
                    ).toLocaleString()
                  }}
                  Points
                </div>
              </div>
              <div class="d-flex justify-content-between mt-2">
                <div>New Expiring Points :</div>
                <div class="ml-1">
                  <div
                    v-for="(item, index) of checkTotalNew(
                      dataDetailTF.transfer_to.receive_points,
                    )"
                    :key="index"
                  >
                    <div class="d-flex justify-end gap-2">
                      <div class="fw-700 text-title-point text-active">
                        {{ item.points.toLocaleString('en-US') }} Points
                      </div>
                      <div class="text-light-grey">
                        in
                        {{ formatDate(item.date, 'DD/MMM/YYYY', -7) }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="d-flex justify-content-between">
                <div>Total New :</div>
                <span class="fw-700 text-title-point text-active"
                  >{{ totalNew(dataDetailTF).toLocaleString() }} Points</span
                >
              </div>
            </div>
          </div>
          <div v-if="dataDetailTF.status == 'Create'" class="p-3">
            <div class="h-detail">Remark</div>
            <a-textarea
              v-model:value="remark"
              :maxlength="200"
              :auto-size="{ minRows: 3, maxRows: 3 }"
            />
            <div class="mt-1 h-detail">
              * Rejection require remark, approval is optional.
            </div>
          </div>
          <div v-if="dataDetailTF.status != 'Create'" class="p-4">
            <a-row>
              <a-col :span="6">
                <div class="h-detail">Remark :</div>
              </a-col>
              <a-col :span="18">
                <div>
                  {{
                    dataDetailTF.approve_reject_reason
                      ? dataDetailTF.approve_reject_reason
                      : '-'
                  }}
                </div>
              </a-col>
              <a-col :span="6">
                <div class="h-detail">Responded date :</div>
              </a-col>
              <a-col :span="18">
                <div>
                  {{
                    dataDetailTF.approve_reject_date
                      ? formatDate(
                          dataDetailTF.approve_reject_date,
                          'DD/MMM/YYYY',
                        )
                      : '-'
                  }}
                </div>
              </a-col>
              <a-col :span="6">
                <div class="h-detail">Respondent :</div>
              </a-col>
              <a-col :span="18">
                <div>
                  {{
                    dataDetailTF.approve_reject_by
                      ? dataDetailTF.approve_reject_by.name
                      : '-'
                  }}
                </div>
              </a-col>
            </a-row>
          </div>
          <div
            :class="
              dataDetailTF.status != 'Create'
                ? 'px-4 py-3'
                : 'flex justify-between px-4 py-3'
            "
          >
            <div v-if="dataDetailTF.status == 'Create'">
              <a-button
                type="danger"
                :disabled="remark.length < 1"
                class="btn-create mr-3"
                @click="Reject()"
                >Reject</a-button
              >
            </div>
            <div>
              <a-button
                type="primary-2"
                :class="
                  dataDetailTF.status != 'Create'
                    ? 'w-maxmax btn-create'
                    : 'mr-3 btn-create'
                "
                @click="dismiss(true)"
              >
                Close
              </a-button>
              <a-button
                v-if="dataDetailTF.status == 'Create'"
                type="success"
                class="btn-create"
                @click="Approve()"
              >
                Approve
              </a-button>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
  <a-modal
    class="modal-set-expire"
    :visible="modalReject"
    @cancel="handleCancel"
    okText="Save"
    :width="350"
    :footer="null"
    :centered="true"
  >
    <div class="h-modal">
      <div>Reject reason</div>
    </div>
    <div class="border-t-2">
      <div class="font-modal-delete px-4 mb-5">
        <div class="pt-4">Reason</div>
        <a-textarea
          v-model:value="value2"
          :auto-size="{ minRows: 2, maxRows: 2 }"
        />
      </div>
      <div class="p-4 border-t-2 modal-footer">
        <a-button type="primary" @click="handleCancel()">Save</a-button>
      </div>
    </div>
  </a-modal>
</template>

<script>
// helper
import Util from '@/helper/Utility'
import moment from 'moment'
import BzbsUser from '@/core/UserManagement/callapi/BzbsUser'
import _ from 'lodash'
import CacheHelper from '@/helper/CacheHelper.js'
import ConfigManager from '@/config/ConfigManager.js'
var Cache = new CacheHelper(ConfigManager.getCacheType())

export default {
  name: 'DisplayFields',
  components: {},
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    top: {
      type: Number,
      default: 0,
    },
    headers: {
      type: Array,
      default: () => [],
    },
    dataDetailTF: {
      type: Array,
    },
  },
  computed: {
    mainBlockStyle: function () {
      return {
        paddingBottom: `${this.top}px`,
      }
    },
    containerStyle: function () {
      return {
        top: `${this.top}px`,
      }
    },
  },
  emits: ['clicksearchprivacy'],
  data: function () {
    return {
      demoimg: require('@/assets/imgs/imgdemo-survey.png'),
      modalReject: false,
      newdata: {},
      drag: false,
      ops: {
        vuescroll: {},
        scrollPanel: {},
        rail: {},
        bar: {},
      },
      formatdate: 'DD MMM YYYY',
      formattime: 'HH:mm',
      textrequirelevelname: '',
      setdisable: true,
      remark: '',
      isErrorName: false,
      status: [
        {
          id: 1,
          name: 'Create',
          color: 'yellow',
        },
        {
          id: 2,
          name: 'Reject',
          color: 'red',
        },
        {
          id: 3,
          name: 'L1Approved',
          color: 'green',
        },
      ],
      status_noti: [
        {
          id: 1,
          name: false,
          text: 'No',
          color: 'red',
        },
        {
          id: 2,
          name: true,
          text: 'Yes',
          color: 'green',
        },
      ],
    }
  },
  watch: {
    value: function (val) {
      if (val) {
        this.setState()
        this.disableScroll()
      } else {
        this.enableScroll()
      }
    },
  },
  mounted() {},
  created: function () {
    this.myArray = this.headers
    this.init()
    // set state when created
    this.setState()
  },
  methods: {
    textStatus(status) {
      switch (status) {
        case 'Create':
          return 'Waiting'
        case 'L1Approved':
          return 'Approved'
        case 'Reject':
          return 'Rejected'
      }
    },
    formatDate2(dateString, format = 'DD/MMM/YYYY', calHours = null) {
      if (typeof dateString == 'number') {
        dateString = new Date(dateString * 1000)
      }
      if (!dateString || dateString == '-') return '-'
      return moment(dateString).add(calHours, 'hours').format(format)
    },
    currentPoint(current_points) {
      if (current_points.length > 0) {
        let total = 0
        current_points.forEach(data => {
          if (data.points) {
            total = total + data.points
          }
        })
        return total
      } else {
        return 0
      }
    },
    checkTotalNew() {
      let dataAll = []
      let dataUnique = []
      this.dataDetailTF.transfer_to.receive_points.forEach(item => {
        dataAll.push(item)
      })
      this.dataDetailTF.transfer_to.current_points.forEach(item => {
        dataAll.push(item)
      })
      this.dataDetailTF.transfer_to.receive_points.forEach(item => {
        dataUnique.push(item)
      })

      const uniqueDates = dataAll.reduce(
        (acc, current) => {
          const date = current.date
          if (!acc.seen[date]) {
            acc.seen[date] = 1
            acc.unique.push(current)
          } else {
            acc.seen[date]++
          }
          return acc
        },
        { seen: {}, unique: [] },
      )

      let uniqueData = uniqueDates.unique.filter(
        item => uniqueDates.seen[item.date] === 1,
      )
      uniqueData.forEach(item => {
        dataUnique.push(item)
      })
      dataUnique = _.reduce(
        dataUnique,
        (unique, o) => {
          if (!unique.some(obj => obj.date === o.date)) {
            unique.push(o)
          }
          return unique
        },
        [],
      )
      return dataUnique
    },
    totalNew(data) {
      if (data.transfer_to.receive_points.length > 0) {
        let total = 0
        this.checkTotalNew().forEach(data => {
          if (data.points) {
            total = total + data.points
          }
        })
        return total
      } else {
        return 0
      }
    },
    init: function (defaultValue = true) {
      this.columnStates = this.headers.map(x => {
        return { key: x.key, value: defaultValue }
      })
    },
    onChange() {
      this.setdisable = false
      if (this.levelname) {
        this.isErrorName = false
      }
    },
    Approve() {
      this.$store.commit('setLoading', true)
      this.PostApprove().then(() => {
        this.$store.commit('setLoading', false)
        this.$success({
          title: 'Success',
          okType: 'default',
          centered: true,
          onOk: () => {
            this.$emit('clicksearchadjustwallet')
            this.$emit('handleClose')
            this.remark = ''
          },
        })
      })
    },
    PostApprove() {
      var approvepoint = {
        appid: Cache.getCache('app').id,
        reference_id: this.dataDetailTF.reference_id
          ? this.dataDetailTF.reference_id
          : '',
        remark: this.remark,
      }
      return new Promise((resolve, reject) => {
        BzbsUser.apiPostApproveTranferPoint(approvepoint)
          .then(res => {
            resolve(res.data.data)
          })
          .catch(error => {
            this.errorApi(error)
            reject(error)
          })
      })
    },
    rejectconfirm() {
      this.modalReject = true
    },
    Reject() {
      this.$store.commit('setLoading', true)
      this.PostReject().then(() => {
        this.$store.commit('setLoading', false)
        this.$success({
          title: 'Success',
          okType: 'default',
          centered: true,
          onOk: () => {
            this.$emit('clicksearchadjustwallet')
            this.$emit('handleClose')
            this.remark = ''
          },
        })
      })
    },
    PostReject() {
      var rejectpoint = {
        appid: Cache.getCache('app').id,
        reference_id: this.dataDetailTF.reference_id
          ? this.dataDetailTF.reference_id
          : '',
        remark: this.remark,
      }
      return new Promise((resolve, reject) => {
        BzbsUser.apiPostRejectTranferPoint(rejectpoint)
          .then(res => {
            resolve(res.data.data)
          })
          .catch(error => {
            this.errorApi(error)
            reject(error)
          })
      })
    },
    setState: function () {
      this.oldStates = Util.copyArray(this.columnStates)
      this.checkUIState()
    },
    resetState: function () {
      this.columnStates = [...this.oldStates]
    },
    disableScroll: function () {
      setTimeout(() => {
        document.body.classList.add('overflow-hidden')
      }, 100)
    },
    enableScroll: function () {
      document.body.classList.remove('overflow-hidden')
    },
    onClickAway: function () {
      this.dismiss()
      this.$nextTick(() => {
        this.$emit('onClickAway')
      })
    },
    dismiss: function () {
      this.levelname = ''
      this.description = ''
      this.earnpoint = ''
      this.benefits = ''
      this.remark = ''
      this.$emit('clicksearchprivacy')
      this.$emit('handleClose')
    },
    handleCancel() {
      this.modalReject = false
    },
    toggleSelectAll: function () {
      if (this.selectAll) {
        // ...
        this.init(true)
      } else {
        // ...
        this.init(false)
      }
    },
    checkUIState: function () {
      let findDeselect = this.columnStates.filter(x => x.value == false)
      if (findDeselect != null && findDeselect.length > 0) {
        this.selectAll = false
      } else {
        this.selectAll = true
      }
    },
  },
}
</script>

<style lang="scss">
.wrapper-display-fields {
  .checkbox-display-Fields {
    .form-checkbox {
      background-color: white;
    }
  }
  .ant-btn {
    width: 100%;
  }
  .input-error {
    border-color: #ff5252 !important;
  }
  .text-error {
    color: #ff5252;
  }
  .pd-t-21 {
    padding-top: 21px;
  }
}
.w100 {
  width: 100% !important;
}
.pointer {
  cursor: pointer;
}
.w-log-wallet {
  width: 30rem;
  .bg-con {
    margin: 15px;
    padding: 15px;
    border-radius: 6px;
    background-color: #f5f5f5;
  }
}
.border-content {
  border: 1px solid #ecedf3;
  border-radius: 3px;
}
.h-detail {
  font-size: 13px;
  color: #aaaaaa;
}
.cursor-disabled {
  cursor: not-allowed;
}
</style>
