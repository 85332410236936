<template>
  <div class="page-layout page-redirect">
    <div
      id="modal-error"
      class="ant-modal-root ant-modal-confirm ant-modal-confirm-error ant-modal-error ant-modal-confirm ant-modal-confirm-error ant-modal-error hideModal"
    >
      <div class="ant-modal-mask"></div>
      <div
        tabindex="-1"
        role="dialog"
        class="ant-modal-wrap ant-modal-centered"
      >
        <div role="document" class="ant-modal">
          <div class="ant-modal-content">
            <div class="ant-modal-body">
              <div class="ant-modal-confirm-body-wrapper">
                <div class="ant-modal-confirm-body">
                  <i
                    aria-label="icon: close-circle"
                    class="anticon anticon-close-circle"
                  >
                    <svg
                      viewBox="64 64 896 896"
                      data-icon="close-circle"
                      width="1em"
                      height="1em"
                      fill="currentColor"
                      aria-hidden="true"
                      focusable="false"
                      class=""
                    >
                      <path
                        d="M685.4 354.8c0-4.4-3.6-8-8-8l-66 .3L512 465.6l-99.3-118.4-66.1-.3c-4.4 0-8 3.5-8 8 0 1.9.7 3.7 1.9 5.2l130.1 155L340.5 670a8.32 8.32 0 0 0-1.9 5.2c0 4.4 3.6 8 8 8l66.1-.3L512 564.4l99.3 118.4 66 .3c4.4 0 8-3.5 8-8 0-1.9-.7-3.7-1.9-5.2L553.5 515l130.1-155c1.2-1.4 1.8-3.3 1.8-5.2z"
                      ></path>
                      <path
                        d="M512 65C264.6 65 64 265.6 64 513s200.6 448 448 448 448-200.6 448-448S759.4 65 512 65zm0 820c-205.4 0-372-166.6-372-372s166.6-372 372-372 372 166.6 372 372-166.6 372-372 372z"
                      ></path>
                    </svg>
                  </i>
                  <span id="modal-title" class="ant-modal-confirm-title"
                    >You don't have a permission</span
                  >
                  <div id="modal-content" class="ant-modal-confirm-content">
                    Please login with another account.
                  </div>
                </div>
                <div class="ant-modal-confirm-btns">
                  <button
                    type="button"
                    class="ant-btn ant-btn-primary"
                    @click="close"
                  >
                    <span>OK</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div
            tabindex="0"
            aria-hidden="true"
            style="width: 0px; height: 0px; overflow: hidden"
          ></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import CacheHelper from '@/helper/CacheHelper.js'
import ConfigManager from '@/config/ConfigManager.js'
// import BzbsProfile from '@/core/Account/service/BzbsProfile.js'
import CommonMixin from '@/mixin/CommonMixin'
import Account from '@/helper/AccountHelper'
import BzbsUser from '@/core/UserManagement/callapi/BzbsUser'
import BzbsApp from '@/core/Application/callapi/BzbsApp'
var cache = new CacheHelper(ConfigManager.getCacheType())

import * as Msal from '@azure/msal-browser'
import { config } from '@/config/bzbsconfig.js'
// const myMSAL = new Msal.UserAgentApplication(config.msalConfig);
const myMSAL = new Msal.PublicClientApplication(config.msalConfig)
myMSAL
  .handleRedirectPromise()
  .then(response => {
    if (response) {
      if (
        response.idTokenClaims['tfp'].toUpperCase() ===
        config.b2cPolicies.names.signUpSignIn.toUpperCase()
      ) {
        handleResponse(response)
      }
    }
  })
  .catch(error => {
    console.log(error)
    myMSAL.loginRedirect(config.loginRequest)
  })

function selectAccount() {
  const currentAccounts = myMSAL.getAllAccounts()
  if (currentAccounts.length < 1) {
    return
  } else if (currentAccounts.length > 1) {
    const accounts = currentAccounts.filter(
      account =>
        account.homeAccountId
          .toUpperCase()
          .includes(config.b2cPolicies.names.signUpSignIn.toUpperCase()) &&
        account.idTokenClaims.iss
          .toUpperCase()
          .includes(config.b2cPolicies.authorityDomain.toUpperCase()) &&
        account.idTokenClaims.aud === config.msalConfig.auth.clientId,
    )

    if (accounts.length > 1) {
      if (
        accounts.every(
          account => account.localAccountId != accounts[0].localAccountId,
        )
      ) {
        signOut()
      }
    }
  }
}

selectAccount()
// async function handleResponse(res) {
//   if (res !== null) {
//     console.log(res)
//     document.cookie = 'msToken=' + res.accessToken
//     document.cookie = 'msUserData=' + JSON.stringify(res.account)
//     var jsonAccount = JSON.stringify({
//       name: res.account.name
//         ? res.account.name
//         : res.account.username.split('@')[0] ,
//       shortedname: res.account.name.includes(' ')
//         ? res.account.name.split(' ')[0][0] +
//           ' ' +
//           res.account.name.split(' ')[1][0]
//         : res.account.name[0],
//       email: res.account.username,
//     })
//     document.cookie = 'account=' + jsonAccount
//     BzbsProfile.getPermission()
//       .then(result => {
//         if (
//           !result.data.result.applications ||
//           result.data.result.applications.length ==  0
//         ) {
//           const d = new Date()
//           d.setTime(d.getTime() + -1 * 24 * 60 * 60 * 1000)
//           let expires = 'expires=' + d.toUTCString()

//           document.cookie =
//             'msToken=; max-age=-1;' + expires + ' path=/; SameSite=None; Secure'

//           document.getElementById('modal-error').classList.remove('hideModal')
//         } else {
//           Account.getMSMenu2().then(()=> {
//             window.location = window.location.origin + '/loginSuccess'
//           })
//         }
//       })
//       .catch(err => {
//         console.log(err)
//         if(err.response.data.error[0].id == '401'){
//           window.location = window.location.origin + '/unauthorized '
//         }else{
//            document.getElementById('modal-title').innerHTML =
//           'Error ' + err.response.data.error[0].code
//         document.getElementById('modal-content').innerHTML =
//           err.response.data.error[0].message +
//           (err.response.data.transaction_id
//             ? '<br>(' + err.response.data.transaction_id + ')'
//             : '')
//         const d = new Date()
//         d.setTime(d.getTime() + -1 * 24 * 60 * 60 * 1000)
//         let expires = 'expires=' + d.toUTCString()

//         document.cookie =
//           'msToken=; max-age=-1;' + expires + ' path=/; SameSite=None; Secure'

//         document.getElementById('modal-error').classList.remove('hideModal')
//         }
//       })
//   } else {
//     selectAccount()
//   }
// }

async function handleResponse(res) {
  if (res !== null) {
    const date = new Date()
    date.setHours(date.getHours() + 24)
    var expires =
      '; expires=' + date.toUTCString() + '; path=/; SameSite=None; Secure'
    document.cookie = 'msToken=' + res.accessToken + expires
    document.cookie = 'msUserData=' + JSON.stringify(res.account) + expires
    var jsonAccount = JSON.stringify({
      name: res.account.name
        ? res.account.name
        : res.account.username.split('@')[0],
      shortedname: res.account.name.includes(' ')
        ? res.account.name.split(' ')[0][0] +
          ' ' +
          res.account.name.split(' ')[1][0]
        : res.account.name[0],
      email: res.account.username,
    })
    document.cookie = 'account=' + jsonAccount + expires
    window.location = window.location.origin + '/redirectms'
  } else {
    selectAccount()
  }
}

function signOut() {
  const logoutRequest = {
    postLogoutRedirectUri: config.msalConfig.auth.postLogoutRedirectUri,
  }

  myMSAL.logoutRedirect(logoutRequest)
}

export default {
  name: 'RedirectMs',
  mixins: [CommonMixin],
  data() {
    return {
      modalSelectAccount: false,
      account: {},
      options: [],
      app: {},
      dataad: [],
      dataapp: {},
    }
  },
  metaInfo() {
    return {}
  },
  beforeCreate() {},
  created() {
    this.init()
  },
  mounted() {},
  methods: {
    init() {
      cache.removeCache('logout')
      localStorage.removeItem('applications')
      localStorage.removeItem('rawapp')
      this.handleLoading(true)
      if (this.$route.query.application_id) {
        cache.setCache(
          'selectapp',
          decodeURIComponent(this.$route.query.application_id),
        )
      }
      if (this.$route.query.agency_id) {
        cache.setCache(
          'selectagency',
          decodeURIComponent(this.$route.query.agency_id),
        )
      }
      if (this.$route.query.targetpage) {
        cache.setCache('targetmspage', this.$route.query.targetpage)
        if (cache.getCache('msToken')) {
          this.checkPermission()
        } else {
          myMSAL.loginRedirect(config.loginRequest)
        }
      }
      if (cache.getCache('msStatus')) {
        if (cache.getCache('msStatus') == 'signin') {
          if (cache.getCache('msToken')) {
            this.checkPermission()
          } else {
            myMSAL.loginRedirect(config.loginRequest)
          }
        }
      } else {
        cache.setCache('msStatus', 'signin')
        if (cache.getCache('msToken')) {
          this.checkPermission()
        } else {
          myMSAL.loginRedirect(config.loginRequest)
        }
      }
    },
    checkPermission() {
      this.getPermission().then(agencies => {
        // cache.setCache('is_MSUser', true)
        cache.removeCache('msStatus')
        cache.removeCache('loading')
        this.getad().then(() => {
          if (cache.getCache('selectapp') || cache.getCache('selectagency')) {
            var userAgency = _.filter(agencies, agency => {
              if (
                cache.getCache('selectapp') &&
                agency.application_id == cache.getCache('selectapp')
              )
                return agency
              if (
                cache.getCache('selectagency') &&
                agency.agency_id == cache.getCache('selectagency')
              )
                return agency
            })
            if (userAgency.length == 1) {
              cache.removeCache('selectapp')
              cache.removeCache('selectagency')
              cache.removeCache('targetmspage')
              this.setFeatures(userAgency[0])
              var data = {
                agency_id: userAgency[0].agency_id,
                agency_name: userAgency[0].agency_name,
                application_id: userAgency[0].application_id,
                application_name: userAgency[0].application_name,
                username: Account.getMSUser().username,
              }
              this.callGetApp(data.application_id).then(() => {
                this.getad()
                Account.setUserCache(data)
                var targetpage2 = cache.getCache('targetmspage')
                this.$nextTick(() => {
                  if (targetpage2) {
                    this.$router.push({
                      path: targetpage2,
                    })
                  } else {
                    Account.getMSMenu2(data.application_id).then(() => {
                      Account.setDefaultMenu()
                    })
                  }
                })
              })
            } else {
              this.$store.commit('setLoading', false)
              var msgtext =
                agencies.length == 1
                  ? 'Login with this application ' +
                    agencies[0].agency_name +
                    '.'
                  : 'Please select an application for which you are authorized.'
              this.$error({
                title: 'Unauthorized this application.',
                content: msgtext,
                centered: true,
                autoFocusButton: null,
                okText: 'OK',
                okType: 'default',
                onOk: () => {
                  if (agencies.length == 1) {
                    cache.removeCache('selectapp')
                    cache.removeCache('selectagency')
                    cache.removeCache('targetmspage')
                    this.setFeatures(agencies[0])
                    var data = {
                      agency_id: agencies[0].agency_id,
                      agency_name: agencies[0].agency_name,
                      application_id: agencies[0].application_id,
                      application_name: agencies[0].application_name,
                      username: Account.getMSUser().username,
                    }
                    this.callGetApp(data.application_id).then(() => {
                      this.getad()
                      Account.setUserCache(data)
                      var targetpage = cache.getCache('targetmspage')
                      this.$nextTick(() => {
                        if (targetpage) {
                          this.$router.push({
                            path: targetpage,
                          })
                        } else {
                          Account.getMSMenu2(data.application_id).then(() => {
                            Account.setDefaultMenu()
                          })
                        }
                      })
                    })
                  } else {
                    Account.getMSMenu2().then(() => {
                      this.$router.push({ name: 'select_application' })
                    })
                  }
                },
              })
            }
          } else {
            if (agencies.length == 1) {
              cache.removeCache('selectapp')
              cache.removeCache('selectagency')
              cache.removeCache('targetmspage')
              this.setFeatures(agencies[0])
              var data1 = {
                agency_id: agencies[0].agency_id,
                agency_name: agencies[0].agency_name,
                application_id: agencies[0].application_id,
                application_name: agencies[0].application_name,
                username: Account.getMSUser().username,
              }
              this.callGetApp(data1.application_id).then(() => {
                this.getad()
                Account.setUserCache(data1)
                var targetpage2 = cache.getCache('targetmspage')
                this.$nextTick(() => {
                  if (targetpage2) {
                    this.$router.push({
                      path: targetpage2,
                    })
                  } else {
                    Account.getMSMenu2(data1.application_id).then(() => {
                      Account.setDefaultMenu()
                    })
                  }
                })
              })
            } else {
              Account.getMSMenu2().then(() => {
                this.$store.commit('setLoading', false)
                this.$router.push({ name: 'select_application' })
              })
            }
          }
        })
      })
    },
    getPermission() {
      return new Promise((resolve, reject) => {
        Account.getMSPermission()
          .then(response => {
            if (response.data.result.applications.length) {
              const apps = _.map(response.data.result.applications, item => {
                return {
                  agency_id: item.agency.id,
                  agency_name: item.agency.name,
                  application_id: item.application_id,
                  application_name: item.name,
                }
              })
              resolve(apps)
            } else {
              this.$router.push({ name: 'unauthorized' })
            }
          })
          .catch(objerror => {
            myMSAL.loginRedirect(config.loginRequest)
            reject(objerror)
          })
      })
    },
    getToken(agency_id) {
      return new Promise(resolve => {
        Account.signinMstoken(cache.getCache('msToken'), agency_id)
          .then(() => {
            var targetpage = cache.getCache('targetmspage')
            cache.removeCache('selectapp')
            cache.removeCache('selectagency')
            cache.removeCache('targetmspage')
            this.$store.commit('setLoading', false)
            Account.setMenu()
              .then(() => {
                if (targetpage) {
                  this.$router.push({
                    path: targetpage,
                  })
                } else {
                  Account.setDefaultMenu()
                }
                resolve()
              })
              .catch(error => {
                Account.clearAuth()
                Account.clearCache()
                this.errorResponse(error, true)
              })
          })

          .catch(objerror => {
            this.$store.commit('setLoading', false)
            this.errorResponse(objerror, true)
          })
      })
    },
    setFeatures(agency) {
      return new Promise((resolve, reject) => {
        BzbsUser.apiGetaduser().subscribe(
          res => {
            this.dataad = res.result
            this.dataapp = this.dataad.applications.find(
              x => x.application_id == agency?.application_id,
            )
            if (this.dataapp) {
              this.permissionbyapp = this.dataapp.features?.map(x => x.name)
            }
            Account.setFeatures(this.permissionbyapp)
            resolve(this.dataad)
          },
          err => {
            this.errorApi(err)
            reject(err)
          },
        )
      })
    },
    getad() {
      return new Promise((resolve, reject) => {
        BzbsUser.apiGetaduser().subscribe(
          res => {
            this.dataad = res.result
            this.dataapp = this.dataad.applications.find(
              x => x.application_id == cache.getCache('app')?.id,
            )
            if (this.dataapp) {
              this.permissionbyapp = this.dataapp.features?.map(x => x.name)
            }
            localStorage.setItem(
              'checkcreateticket',
              JSON.stringify(this.dataad.applications),
            )
            if (this.dataapp) {
              localStorage.setItem('permission', JSON.stringify(this.dataapp))
            }
            if (this.permissionbyapp) {
              localStorage.setItem(
                'permissionbyapp',
                JSON.stringify(this.permissionbyapp),
              )
            }
            resolve(this.dataad)
          },
          err => {
            this.errorApi(err)
            reject(err)
          },
        )
      })
    },
    callGetApp(appid) {
      return new Promise((resolve, reject) => {
        BzbsApp.apiGetApplication().subscribe(
          res => {
            var app = res.result.filter(x => x.application.appid == appid)
            cache.setCache('app', {
              id: app[0].application.appid,
              name: app[0].application.name,
              logo: app[0].application.logo,
              purchase_type: app[0].application.purchase_type,
              has_coupon: app[0].application.has_coupon,
            })
            resolve(res)
          },
          err => {
            this.errorApi(err)
            reject(err)
          },
        )
      })
    },
    close() {
      const logoutRequest = {
        postLogoutRedirectUri: config.msalConfig.auth.postLogoutRedirectUri,
      }

      myMSAL.logoutRedirect(logoutRequest)
    },
  },
}
</script>

<style lang="scss" scope>
body.body {
  min-width: 100%;
  background: #333;
  width: 100%;
  min-height: 100vh;

  .page-redirect {
    .btn-redirect {
      display: block;
      margin: 20vh auto;
    }

    .ant-select {
      min-width: 100%;
    }
  }

  .anticon svg {
    display: inline-block;
  }
}

.hideModal {
  visibility: hidden;
  opacity: 0;
}
</style>
