<template>
  <div class="flex-1 w-full px-4 approveimport">
    <div class="table-scroll">
      <nav class="nav-search">
        <a-select
          class="select-search"
          style="width: 150px"
          v-model:value="statusdata"
          placeholder="All Status"
          :options="statusdata_dropdown"
        />
        <div class="wrap-date-filter">
          <a-button class="btn-date-filter" @click="toggleDateFilter()">
            {{ dateFilterValue }}
            <calendar-outlined />
          </a-button>
          <DateFilter
            v-model:value="showDateFilter"
            :hasCalendar="true"
            @selectRange="selectRange($event)"
            @selectCalendar="selectCalendar($event)"
            @reset="resetDatefilter($event)"
            @handleCloseDaterange="showDateFilter = false"
          />
        </div>
        <a-button
          :disabled="(startValue && !endValue) || (!startValue && endValue)"
          type="primary"
          class="btn-search"
          @click="clicksearchImportsPoints()"
          ><search-outlined /> Search</a-button
        >
      </nav>
      <div class="wrap-table-level" @scroll="handleScroll">
        <VLoadingTable :value="overlaytable" />
        <a-table
          row-key="id"
          class="fixed-head"
          :dataSource="dataApproveList"
          :columns="columnsDataprivacy"
          :pagination="false"
        >
          <template #original_file_name="{ record }">
            <a class="font-level" @click="toggleApproveImportPointByID(record)">
              <div>{{ record.original_file_name }}</div>
            </a>
          </template>
          <template #total_user="{ record }">
            <div>{{ record.data_items.length.toLocaleString('en-US') }}</div>
          </template>
          <template #created_date="{ record }">
            <div>
              {{
                formatDate(
                  record.created_date,
                  'DD/MMM/YYYY',
                  this.isTimezone * -1,
                )
              }}
            </div>
          </template>
          <template #queue_status="{ record }">
            <div
              v-if="
                record.action_result.status == 'Failed' &&
                record.import_result.totals_success > 0
              "
            >
              <template v-for="(item, index) in status">
                <a-tag
                  v-if="record.action_result.status == item.name"
                  :key="index"
                  :color="item.color == 'default' ? null : 'green'"
                  class="tag-status"
                >
                  <a-badge
                    status="green"
                    text="
                    Approved
                  "
                  />
                </a-tag>
              </template>
            </div>
            <div v-else>
              <template v-for="(item, index) in status">
                <a-tag
                  v-if="record.action_result.status == item.name"
                  :key="index"
                  :color="item.color == 'default' ? null : item.color"
                  class="tag-status"
                >
                  <a-badge
                    :status="item.color"
                    :text="textStatus(record.action_result.status)"
                  />
                </a-tag>
              </template>
            </div>
          </template>
          <template #remark="{ record }">
            <div v-if="record.action_result.status == 'Failed'">
              Successfully: {{ record.import_result.totals_success }}, Failed:
              {{ record.import_result.totals_fail }}
              <a-tooltip placement="bottom">
                <template #title>
                  <span>Dowload failed record.</span>
                </template>
                <a class="text-link ml-3" @click="exportFailedRecord(record)"
                  ><download-outlined
                /></a>
              </a-tooltip>
            </div>
            <div v-else>-</div>
          </template>
          <template #created_by="{ record }">
            <div>{{ record.auditable ? record.auditable.name : '-' }}</div>
          </template>
        </a-table>
      </div>
    </div>
  </div>
  <DisplayFieldsApproveImportPoint
    :value="showDisplayFieldsApproveImportPoint"
    :dataImportpointbyid="dataImportpointbyid"
    @clicksearchImportsPoints="clicksearchImportsPoints()"
    @handleClose="showDisplayFieldsApproveImportPoint = false"
  >
  </DisplayFieldsApproveImportPoint>
</template>
<script>
// import Footer from '@/components/Layout/Footer/Footer.vue'
// import ConfigManager from '@/config/ConfigManager.js'
import _ from 'lodash'
import BzbsUser from '@/core/UserManagement/callapi/BzbsUser'
import moment from 'moment'
import dayjs from 'dayjs'
import DateFilter from '@/module/User/component/DateFilter.vue'
import DisplayFieldsApproveImportPoint from '@/module/Setting/ApproveList/component/DisplayFieldsApproveImportPoint.vue'
import { config } from '@/config/bzbsconfig.js'

export default {
  name: 'UserList',
  components: {
    // Footer,
    DateFilter,
    DisplayFieldsApproveImportPoint,
  },
  data: function () {
    return {
      isTimezone: config.businessRule.timeZone.hours,
      datapermission: JSON.parse(localStorage.getItem('permission')),
      startValue: null,
      endValue: null,
      startdate: null,
      enddate: null,
      endOpen: false,
      overlaytablelist: false,
      page: 1,
      search: '',
      nomore: false,
      overlaytable: false,
      managepermission: true,
      showDisplayFieldsApproveImportPoint: false,
      searchObject: {
        startdate: '',
        enddate: '',
        statusdata: '',
      },
      statusdata: null,
      statusdata_dropdown: [
        {
          label: 'All Status',
          value: '',
        },
        {
          label: 'Waiting',
          value: 'Create',
        },
        {
          label: 'Processing',
          value: 'L1Approved',
        },
        {
          label: 'Approved',
          value: 'Completed ',
        },
        {
          label: 'Failed',
          value: 'Failed',
        },
        {
          label: 'Rejected',
          value: 'Reject',
        },
      ],
      columnsDataprivacy: [
        {
          title: 'File Name',
          dataIndex: 'original_file_name',
          slots: { customRender: 'original_file_name' },
          key: 'original_file_name',
          width: 160,
          align: 'left',
        },
        {
          title: 'Total user',
          dataIndex: 'total_user',
          slots: { customRender: 'total_user' },
          key: 'total_user',
          width: 100,
          align: 'right',
        },
        {
          title: 'Status',
          dataIndex: 'queue_status',
          slots: { customRender: 'queue_status' },
          key: 'queue_status',
          width: 140,
          align: 'left',
        },
        {
          title: 'Uploaded file details',
          dataIndex: 'remark',
          slots: { customRender: 'remark' },
          key: 'remark',
          width: 190,
          align: 'left',
        },
        {
          title: 'Created Date',
          dataIndex: 'created_date',
          slots: { customRender: 'created_date' },
          key: 'created_date',
          width: 150,
          align: 'left',
        },
        {
          title: 'Created by',
          dataIndex: 'created_by',
          slots: { customRender: 'created_by' },
          key: 'created_by',
          width: 150,
          align: 'left',
        },
      ],
      status: [
        {
          id: 1,
          name: 'Create',
          color: 'yellow',
        },
        {
          id: 2,
          name: 'Reject',
          color: 'red',
        },
        {
          id: 3,
          name: 'Completed',
          color: 'green',
        },
        {
          id: 4,
          name: 'L1Approved',
          color: 'yellow',
        },
        {
          id: 5,
          name: 'Failed',
          color: 'red',
        },
      ],
      datalists: [],
      dataApproveList: [],
      formatdate: 'DD/MMM/YYYY',
      showDateFilter: false,
      dateFilterValue: 'Date Range',
      dataDetails: [],
      id: '',
      datalist: [],
      dataImportpointbyid: {},
    }
  },
  computed: {
    isOnChangeSelectType() {
      return this.TopBarStore.state.isOnChangeSelectType
    },
    searchbToobar() {
      return this.TopBarStore.state.SearchUserlist
    },
    bcItems: function () {
      return [
        {
          text: 'Setting',
        },
        {
          text: '<div class="font-normal text-sm text-gray-400">Approve list</div>',
        },
      ]
    },
  },
  mounted() {
    this.$store.commit('setLoading', true)
    if (this.$route.query.tab == 'importpoint') {
      this.clicksearchImportsPoints()
    }
  },
  watch: {
    $route(to) {
      if (to.query.tab == 'importpoint') {
        this.clicksearchImportsPoints()
      }
    },
  },
  methods: {
    exportFailedRecord(record) {
      window.location = record.import_result.fail_blob_url
    },
    clicksearchImportsPoints() {
      this.$store.commit('setLoading', true)
      this.searchObject.statusdata = this.statusdata
      this.page = 1
      this.showDateFilter = false
      this.getImportsPoints().then(() => {
        this.$store.commit('setLoading', false)
      })
    },
    getImportsPoints() {
      return new Promise((resolve, reject) => {
        BzbsUser.apiGetImportsPoints(
          (this.searchObject.statusdata = this.statusdata
            ? this.statusdata
            : ''),
          (this.searchObject.startdate = this.startdate
            ? dayjs(this.startdate).format('YYYY-MM-DD')
            : ''),
          (this.searchObject.enddate = this.enddate
            ? dayjs(this.enddate).format('YYYY-MM-DD')
            : ''),
          this.page,
        ).subscribe(
          data => {
            if (this.page == 1) {
              this.dataApproveList = []
            }
            _.forEach(data.result, item => {
              this.dataApproveList.push(item)
            })
            if (data.result.length < 20) {
              this.nomore = true
            } else {
              this.nomore = false
            }
            resolve(this.dataApproveList)
          },
          err => {
            this.errorApi(err)
            reject(err)
          },
        )
      })
    },
    getdataDetails() {
      return new Promise((resolve, reject) => {
        BzbsUser.apiGetApproveDetaillist(this.id).subscribe(
          res => {
            this.dataDetails = res.result
            resolve(this.dataDetails)
          },
          err => {
            this.errorApi(err)
            reject(err)
          },
        )
      })
    },
    getDatalist(record) {
      if (
        record.data.data_privacy == -1 &&
        record.data.term_and_condition == -1 &&
        record.data.consent_age == -1 &&
        record.data.marketing_option == -1
      ) {
        return 'Unconsent'
      } else {
        return 'Marketing Channel'
      }
    },
    textStatus(status) {
      switch (status) {
        case 'Create':
          return 'Waiting'
        case 'L1Approved':
          return 'Processing'
        case 'Completed':
          return 'Approved'
        case 'Failed':
          return 'Failed'
        case 'Reject':
          return 'Rejected'
      }
    },
    checkData() {
      this.datalist = []
      Object.keys(this.dataDetails.original_data).forEach(element => {
        if (
          this.dataDetails.new_data[element] == -1 &&
          !this.datalist.includes('Unconsent')
        ) {
          return this.datalist.push('Unconsent')
        }
        if (
          this.dataDetails.original_data[element] !=
          this.dataDetails.new_data[element]
        ) {
          switch (element) {
            case 'line_marketing':
              return this.datalist.push('Line Marketing')
            case 'sms_marketing':
              return this.datalist.push('SMS Marketing')
            case 'notification_marketing':
              return this.datalist.push('Notification Marketing')
            case 'email_marketing':
              return this.datalist.push('Email Marketing')
          }
        }
      })

      if (this.datalist.length > 0) {
        this.datalist = this.datalist.join(', ')
      } else {
        this.datalist = '-'
      }
    },
    handleScroll(event) {
      if (!this.nomore && !this.overlaytable) {
        if (
          event.target.offsetHeight + event.target.scrollTop + 5 >=
          event.target.scrollHeight
        ) {
          this.overlaytable = true
          this.page++
          this.getImportsPoints().then(() => {
            this.overlaytable = false
          })
        }
      }
    },
    disabledStartDate(startdate) {
      return startdate && startdate >= moment().endOf('Day')
    },
    disabledEndDate(endValue) {
      if (!endValue || !this.startValue) {
        return false
      }
      return (
        this.startValue.valueOf() >= endValue.valueOf() + 1 ||
        endValue >= moment().endOf('Day')
      )
    },
    handleStartOpenChange(open) {
      if (!open) {
        this.endOpen = true
      }
    },
    handleEndOpenChange(open) {
      this.endOpen = open
    },
    toggleApproveImportPointByID(record) {
      this.dataImportpointbyid = record
      this.showDisplayFieldsApproveImportPoint =
        !this.showDisplayFieldsApproveImportPoint
    },
    toggleDateFilter() {
      this.showDateFilter = !this.showDateFilter
    },
    resetDatefilter() {
      this.dateFilterValue = 'Date Range'
      this.startdate = ''
      this.enddate = ''
    },
    selectCalendar(range) {
      if (range.start && range.end) {
        this.startdate = dayjs(range.start).format(this.formatdate)
        this.enddate = dayjs(range.end).format(this.formatdate)
        this.dateFilterValue = this.startdate + ' to ' + this.enddate
      }
    },
    selectRange(range) {
      this.dateFilterValue = range.value
      var params = {}
      if (range.value == 'Today') {
        params.startdate = moment().startOf('day').format(this.formatdate)
        params.enddate = moment().endOf('day').format(this.formatdate)
      }
      if (range.value == 'This Week') {
        params.startdate = moment().startOf('week').format(this.formatdate)
        params.enddate = moment().endOf('week').format(this.formatdate)
      }
      if (range.value == 'Last Week') {
        params.startdate = moment()
          .startOf('week')
          .subtract(7, 'days')
          .format(this.formatdate)
        params.enddate = moment()
          .endOf('week')
          .subtract(7, 'days')
          .format(this.formatdate)
      }
      if (range.value == 'This Month') {
        params.startdate = moment().startOf('month').format(this.formatdate)
        params.enddate = moment().endOf('month').format(this.formatdate)
      }
      if (range.value == 'Last Month') {
        params.startdate = moment()
          .startOf('month')
          .subtract(1, 'months')
          .format(this.formatdate)
        params.enddate = moment()
          .endOf('month')
          .subtract(1, 'months')
          .format(this.formatdate)
      }
      if (range.value == 'Last 3 Weeks') {
        params.startdate = moment()
          .startOf('week')
          .subtract(21, 'days')
          .format(this.formatdate)
        params.enddate = moment()
          .endOf('week')
          .subtract(7, 'days')
          .format(this.formatdate)
      }
      if (range.value == 'Last 6 Weeks') {
        params.startdate = moment()
          .startOf('week')
          .subtract(42, 'days')
          .format(this.formatdate)
        params.enddate = moment()
          .endOf('week')
          .subtract(7, 'days')
          .format(this.formatdate)
      }
      if (range.value == 'Last 3 Months') {
        params.startdate = moment()
          .startOf('month')
          .subtract(3, 'months')
          .format(this.formatdate)
        params.enddate = moment()
          .endOf('month')
          .subtract(1, 'months')
          .format(this.formatdate)
      }
      if (range.value == 'Last 6 Months') {
        params.startdate = moment()
          .startOf('month')
          .subtract(6, 'months')
          .format(this.formatdate)
        params.enddate = moment()
          .endOf('month')
          .subtract(1, 'months')
          .format(this.formatdate)
      }
      this.startdate = params.startdate
      this.enddate = params.enddate
    },
    setmanagepermission() {
      if (this.datapermission.features.every(x => x.name != 'Manage-Privacy')) {
        return (this.managepermission = false)
      }
    },
  },
}
</script>
<style lang="scss" scoped>
@import '@/style/base/base.scss';

.approveimport {
  overflow-x: hidden;

  .table-scroll {
    background: #fff;
    overflow-x: auto;
    overflow-y: hidden;
    border-radius: 6px;
    min-height: calc(100vh - 240px);
    max-height: calc(100vh - 240px);
  }

  .nav-search {
    display: flex;
    width: 100%;
    border-bottom: 15px solid #f9f9f9;
    padding: 10px 10px;
    float: left;

    .search {
      float: left;
      width: auto;

      .input-search {
        width: 200px;
        border-right: none;
      }

      .select-search {
        width: 130px;
      }
    }

    .wrap-date-filter {
      // min-width: 290px;
      position: relative;
      padding-right: 10px;
      padding-left: 10px;
    }

    .wrap-filter {
      float: left;
      position: relative;
      margin-left: 10px;
      padding-left: 10px;
    }

    .btn-date-filter {
      width: 260px;
      text-align: left;

      .anticon {
        float: right;
        margin-top: 2px;
      }
    }

    .group-btn-right {
      display: flex;
      float: right;

      .btn-search {
        margin-right: 10px;
        margin-left: 10px;
      }
    }
  }

  .wrap-table-level {
    display: block;
    width: 100%;
    max-height: calc(100vh - 315px);
    overflow-x: auto;

    .ant-table-thead > tr > th {
      padding: 15px 15px !important;
    }
  }

  .ant-table-tbody {
    > tr td {
      padding: 15px 15px !important;
    }
  }

  .font-level {
    color: #f9a601;
    text-decoration: underline;
  }

  .ant-table-thead {
    .ant-table-cell {
      .title {
        display: inline-block;
      }

      .wrap-sort {
        display: inline-block;
        padding: 0 5px;
        line-height: 0.5em;
        cursor: pointer;

        span {
          display: none;
          padding: 0;
          position: relative;
          top: 3px;
          cursor: pointer;

          &.active {
            display: block;
            color: $color-primary-light-2;
          }
        }
      }

      &:hover {
        .wrap-sort span {
          display: inline-block;

          &:hover {
            color: $color-primary-light-2;
          }
        }
      }
    }
  }
}
</style>
<style lang="scss">
.approveimport {
  .datefilter {
    right: unset !important;
  }
}

.approvepoint {
  .ant-table-row {
    vertical-align: top !important;
  }

  .ant-table-thead {
    vertical-align: top !important;
  }
}
</style>
