<template>
  <div class="flex-1 w-full px-4 import-user">
    <div class="mt-3">
      <div v-if="datapermission.includes('Import-List-User-Import')" class="">
        <div class="bg-upload">
          <a-upload-dragger
            v-model:fileList="fileList"
            name="file"
            :multiple="false"
            :openFileDialogOnClick="false"
            :showUploadList="false"
            :maxCount="1"
            :beforeUpload="[
              function () {
                false
              },
            ]"
            @change="handleChange"
            @drop="handleDrop"
            style="height: 50%"
          >
            <div class="d-flex justify-center">
              <p class="ant-upload-drag-icon text-webkit-center">
                <img src="@/assets/imgs/CloudUpload.svg" />
              </p>
              <p class="mt-3 pl-3">Drag and Drop File Here</p>
            </div>
            <a-upload
              v-model:file-list="fileList"
              name="file"
              :maxCount="1"
              :beforeUpload="[
                function () {
                  false
                },
              ]"
              :showUploadList="false"
              @change="handleChange"
            >
              <a-button type="defalut" class="ml-3" style="width: 180px">
                Upload File
              </a-button>
            </a-upload>
            <p class="ant-upload-hint">File format support : .csv</p>
          </a-upload-dragger>
          <div
            v-if="datapermission.includes('Import-List-User-Download')"
            class="text-center lock mt-2"
          >
            *Limit 1,000 items.
          </div>
        </div>
        <div class="d-flex justify-between mt-3">
          <div class="d-flex gap-2">
            <a-select
              class="select-search"
              style="width: 150px"
              v-model:value="statusdata"
              placeholder="All Status"
              :options="statusdata_dropdown"
            />
            <div class="wrap-date-filter">
              <a-button class="btn-date-filter" @click="toggleDateFilter()">
                {{ dateFilterValue }}
                <calendar-outlined />
              </a-button>
              <DateFilter
                v-model:value="showDateFilter"
                :hasCalendar="true"
                @selectRange="selectRange($event)"
                @selectCalendar="selectCalendar($event)"
                @reset="resetDatefilter($event)"
                @handleCloseDaterange="showDateFilter = false"
              />
            </div>
            <a-button
              type="primary"
              class="btn-search"
              @click="clicksearchimportslist()"
              ><search-outlined /> Search</a-button
            >
          </div>
          <div class="d-flex">
            <a-button
              v-if="datapermission.includes('Import-List-User-Download')"
              class=""
              type="defalut"
              @click="gettemplate()"
            >
              <div class="d-flex justify-content-center">
                <download-outlined class="pt-1" />
                <div class="pl-2">Download Template</div>
              </div>
            </a-button>
          </div>
        </div>
      </div>
      <div>
        <div class="table-scroll mt-3">
          <div class="wrap-table-level" @scroll="handleScroll">
            <VLoadingTable :value="overlaytable" />
            <a-table
              row-key="id"
              class="fixed-head"
              :dataSource="dataImportsList"
              :columns="columnsImports"
              :pagination="false"
            >
              <template #status="{ text }">
                <template v-for="(item, index) in status">
                  <a-tag
                    v-if="text == item.name"
                    :key="index"
                    :color="item.color == 'default' ? null : item.color"
                    class="tag-status"
                  >
                    <a-badge :status="item.color" :text="text" />
                  </a-tag>
                </template>
              </template>
              <template #original_file_name="{ record }">
                <a class="text-link" @click="toggleDetail(record)">{{
                  record.original_file_name
                }}</a>
              </template>
              <template #total_user="{ record }">
                <div>{{ record.import_result.totals.toLocaleString() }}</div>
              </template>
              <template #success="{ record }">
                <div>
                  {{ record.import_result.totals_success.toLocaleString() }}
                </div>
              </template>
              <template #failed="{ record }">
                <div class="d-flex justify-end">
                  <div
                    :class="record.import_result.totals_fail > 0 ? 'lock' : ''"
                  >
                    {{ record.import_result.totals_fail.toLocaleString() }}
                  </div>
                  <a-tooltip placement="bottom">
                    <template #title>
                      <span>Dowload failed record.</span>
                    </template>
                    <a
                      v-if="record.import_result.fail_blob_url"
                      class="text-link ml-3"
                      @click="exportFailedRecord(record)"
                      ><download-outlined
                    /></a>
                  </a-tooltip>
                </div>
              </template>
              <template #created_date="{ record }">
                {{
                  record.created_date
                    ? formatDate(record.created_date, 'DD/MMM/YYYY - HH:mm')
                    : '-'
                }}
              </template>
              <template #import_by="{ record }">
                <div>{{ record.auditable.name }}</div>
              </template>
            </a-table>
          </div>
        </div>
      </div>
    </div>
  </div>
  <DisplayFieldsImportUsers
    :value="showDetailImportUser"
    :dataDetails="dataDetails"
    @handleClose="showDetailImportUser = false"
  >
  </DisplayFieldsImportUsers>
</template>
<script>
// import Footer from '@/components/Layout/Footer/Footer.vue'
import ConfigManager from '@/config/ConfigManager.js'
import _ from 'lodash'
import BzbsUser from '@/core/UserManagement/callapi/BzbsUser'
import moment from 'moment'
import dayjs from 'dayjs'
import DateFilter from '@/module/User/component/DateFilter.vue'
import DisplayFieldsImportUsers from '@/module/Import/component/DisplayFieldsImportUsers.vue'

export default {
  name: 'UserList',
  components: {
    // Footer,
    DateFilter,
    DisplayFieldsImportUsers,
  },
  data: function () {
    return {
      datapermission: JSON.parse(localStorage.getItem('permissionbyapp')),
      startValue: null,
      endValue: null,
      startdate: null,
      enddate: null,
      endOpen: false,
      overlaytablelist: false,
      page: 1,
      search: '',
      nomore: false,
      overlaytable: false,
      managepermission: true,
      showDisplayFieldsApproveDataPrivacy: false,
      fileList: [],
      searchObject: {
        startdate: '',
        enddate: '',
        statusdata: '',
      },
      statusdata: null,
      statusdata_dropdown: [
        {
          label: 'All Status',
          value: '',
        },
        {
          label: 'Waiting',
          value: 'Waiting',
        },
        {
          label: 'Processing',
          value: 'Processing',
        },
        {
          label: 'Success',
          value: 'Success',
        },
        {
          label: 'Error',
          value: 'Error',
        },
      ],
      columnsImports: [
        {
          title: 'Status',
          dataIndex: 'queue_status',
          slots: { customRender: 'status' },
          key: 'queue_status',
          width: 120,
          align: 'left',
        },
        {
          title: 'File Name',
          dataIndex: 'original_file_name',
          slots: { customRender: 'original_file_name' },
          key: 'original_file_name',
          width: 220,
          align: 'left',
        },
        {
          title: 'Total User',
          dataIndex: 'total_user',
          slots: { customRender: 'total_user' },
          key: 'total_user',
          width: 100,
          align: 'right',
        },
        {
          title: 'Success',
          dataIndex: 'success',
          slots: { customRender: 'success' },
          key: 'success',
          width: 100,
          align: 'right',
        },
        {
          title: 'Failed',
          dataIndex: 'failed',
          slots: { customRender: 'failed' },
          key: 'failed',
          width: 100,
          align: 'right',
        },
        {
          title: 'Import Date',
          dataIndex: 'created_date',
          slots: { customRender: 'created_date' },
          key: 'created_date',
          width: 220,
          align: 'left',
        },
        {
          title: 'Import By',
          dataIndex: 'import_by',
          slots: { customRender: 'import_by' },
          key: 'import_by',
          width: 220,
          align: 'left',
        },
      ],
      status: [
        {
          id: 1,
          name: 'Waiting',
          color: 'yellow',
        },
        {
          id: 2,
          name: 'Processing',
          color: 'yellow',
        },
        {
          id: 3,
          name: 'Cancel',
          color: 'red',
        },
        {
          id: 4,
          name: 'Error',
          color: 'red',
        },
        {
          id: 5,
          name: 'Success',
          color: 'green',
        },
      ],
      dataImportsList: [],
      formatdate: 'DD/MMM/YYYY',
      showDateFilter: false,
      dateFilterValue: 'Date Range',
      dataDetails: [],
      id: '',
      dataTemplate: [],
      modalDowload: false,
      template_csv: '',
      template_xlsx: '',
      showDetailImportUser: false,
      template:
        'https://devbzbsusermgr.blob.core.windows.net/files/templates/import_user/Import%20User.csv',
    }
  },
  computed: {},
  watch: {
    $route(to) {
      if (to.query.tab == 'users') {
        this.$store.commit('setLoading', true)
        this.clicksearchimportslist()
      }
    },
  },
  mounted() {
    if (this.$route.query.tab == 'users') {
      this.$store.commit('setLoading', true)
      this.clicksearchimportslist()
    }
  },
  methods: {
    clicksearchimportslist() {
      this.$store.commit('setLoading', true)
      this.page = 1
      this.getImportsUserslist().then(() => {
        this.$store.commit('setLoading', false)
      })
    },
    getImportsUserslist() {
      return new Promise((resolve, reject) => {
        BzbsUser.apiGetImportUserList(
          (this.searchObject.statusdata = this.statusdata
            ? this.statusdata
            : ''),
          (this.searchObject.startdate = this.startdate
            ? dayjs(this.startdate).format('YYYY-MM-DD')
            : ''),
          (this.searchObject.enddate = this.enddate
            ? dayjs(this.enddate).format('YYYY-MM-DD')
            : ''),
          this.page,
        ).subscribe(
          data => {
            if (this.page == 1) {
              this.dataImportsList = []
            }
            _.forEach(data.result, item => {
              this.dataImportsList.push(item)
            })
            if (data.result.length < 20) {
              this.nomore = true
            } else {
              this.nomore = false
            }
            this.$store.commit('setLoading', false)
            resolve(this.dataImportsList)
          },
          err => {
            this.errorApi(err)
            reject(err)
          },
        )
      })
    },
    handleScroll(event) {
      if (!this.nomore && !this.overlaytable) {
        if (
          event.target.offsetHeight + event.target.scrollTop + 5 >=
          event.target.scrollHeight
        ) {
          this.overlaytable = true
          this.page++
          this.getImportsUserslist().then(() => {
            this.overlaytable = false
          })
        }
      }
    },
    gettemplate() {
      window.location = this.template
    },
    getImportsTemplates() {
      this.$store.commit('setLoading', true)
      return new Promise((resolve, reject) => {
        BzbsUser.apiGetImportsTemplates().subscribe(
          res => {
            this.dataTemplate = res.result
            this.template_csv = this.dataTemplate.csv_file_url
            this.template_xlsx = this.dataTemplate.excel_file_url
            this.$store.commit('setLoading', false)
            resolve(this.dataprivacy)
          },
          err => {
            this.errorApi(err)
            reject(err)
          },
        )
      })
    },
    handleChange() {
      this.PostImportsUsers().then(() => {
        this.$store.commit('setLoading', true)
        this.getImportsUserslist()
      })
    },
    importfile() {
      if (this.fileList.length > 0) {
        this.PostImportsUsers()
      }
    },
    PostImportsUsers() {
      this.$store.commit('setLoading', true)
      var importadjustpoint = {
        appid: ConfigManager.getAppId(),
        file: this.fileList[0].originFileObj,
      }
      return new Promise((resolve, reject) => {
        BzbsUser.apiPostImportsUsers(importadjustpoint)
          .then(res => {
            this.$store.commit('setLoading', false)
            this.$success({
              title: 'Success',
              okType: 'default',
              centered: true,
              onOk: () => {},
            })
            resolve(res.data.data)
          })
          .catch(error => {
            this.errorApi(error)
            reject(error)
          })
      })
    },
    exportFailedRecord(record) {
      window.location = record.import_result.fail_blob_url
    },
    toggleDetail(record) {
      this.dataDetails = record
      this.showDetailImportUser = !this.showDetailImportUser
    },
    disabledStartDate(startdate) {
      return startdate && startdate >= moment().endOf('Day')
    },
    disabledEndDate(endValue) {
      if (!endValue || !this.startValue) {
        return false
      }
      return (
        this.startValue.valueOf() >= endValue.valueOf() + 1 ||
        endValue >= moment().endOf('Day')
      )
    },
    handleStartOpenChange(open) {
      if (!open) {
        this.endOpen = true
      }
    },
    handleEndOpenChange(open) {
      this.endOpen = open
    },
    toggleDateFilter() {
      this.showDateFilter = !this.showDateFilter
    },
    resetDatefilter() {
      this.dateFilterValue = 'Date Range'
      this.startdate = ''
      this.enddate = ''
    },
    selectCalendar(range) {
      if (range.start && range.end) {
        this.startdate = dayjs(range.start).format(this.formatdate)
        this.enddate = dayjs(range.end).format(this.formatdate)
        this.dateFilterValue = this.startdate + ' to ' + this.enddate
      }
    },
    selectRange(range) {
      this.dateFilterValue = range.value
      var params = {}
      if (range.value == 'Today') {
        params.startdate = moment().startOf('day').format(this.formatdate)
        params.enddate = moment().endOf('day').format(this.formatdate)
      }
      if (range.value == 'This Week') {
        params.startdate = moment().startOf('week').format(this.formatdate)
        params.enddate = moment().endOf('week').format(this.formatdate)
      }
      if (range.value == 'Last Week') {
        params.startdate = moment()
          .startOf('week')
          .subtract(7, 'days')
          .format(this.formatdate)
        params.enddate = moment()
          .endOf('week')
          .subtract(7, 'days')
          .format(this.formatdate)
      }
      if (range.value == 'This Month') {
        params.startdate = moment().startOf('month').format(this.formatdate)
        params.enddate = moment().endOf('month').format(this.formatdate)
      }
      if (range.value == 'Last Month') {
        params.startdate = moment()
          .startOf('month')
          .subtract(1, 'months')
          .format(this.formatdate)
        params.enddate = moment()
          .endOf('month')
          .subtract(1, 'months')
          .format(this.formatdate)
      }
      if (range.value == 'Last 3 Weeks') {
        params.startdate = moment()
          .startOf('week')
          .subtract(21, 'days')
          .format(this.formatdate)
        params.enddate = moment()
          .endOf('week')
          .subtract(7, 'days')
          .format(this.formatdate)
      }
      if (range.value == 'Last 6 Weeks') {
        params.startdate = moment()
          .startOf('week')
          .subtract(42, 'days')
          .format(this.formatdate)
        params.enddate = moment()
          .endOf('week')
          .subtract(7, 'days')
          .format(this.formatdate)
      }
      if (range.value == 'Last 3 Months') {
        params.startdate = moment()
          .startOf('month')
          .subtract(3, 'months')
          .format(this.formatdate)
        params.enddate = moment()
          .endOf('month')
          .subtract(1, 'months')
          .format(this.formatdate)
      }
      if (range.value == 'Last 6 Months') {
        params.startdate = moment()
          .startOf('month')
          .subtract(6, 'months')
          .format(this.formatdate)
        params.enddate = moment()
          .endOf('month')
          .subtract(1, 'months')
          .format(this.formatdate)
      }
      this.startdate = params.startdate
      this.enddate = params.enddate
    },
  },
}
</script>
<style lang="scss" scoped>
@import '@/style/base/base.scss';

.approvepoint {
  overflow-x: hidden;

  .table-scroll {
    background: #fff;
    overflow-x: auto;
    overflow-y: hidden;
    border-radius: 6px;
    min-height: calc(100vh - 500px);
    max-height: calc(100vh - 500px);
  }

  .nav-search {
    display: block;
    width: 100%;
    border-bottom: 15px solid #f9f9f9;
    padding: 10px 10px;
    float: left;

    .search {
      float: left;
      width: auto;

      .input-search {
        width: 200px;
        border-right: none;
      }

      .select-search {
        width: 130px;
      }
    }

    .wrap-date-filter {
      float: left;
      // min-width: 290px;
      position: relative;
      margin-left: 10px;
      padding-left: 10px;
    }

    .wrap-filter {
      float: left;
      position: relative;
      margin-left: 10px;
      padding-left: 10px;
    }

    .btn-date-filter {
      width: 260px;
      text-align: left;

      .anticon {
        float: right;
        margin-top: 2px;
      }
    }

    .group-btn-right {
      display: flex;
      float: right;

      .btn-search {
        margin-right: 10px;
        margin-left: 10px;
      }
    }
  }

  .wrap-table-level {
    display: block;
    width: 100%;
    max-height: calc(100vh - 500px);
    overflow-x: auto;

    .ant-table-thead > tr > th {
      padding: 15px 15px !important;
    }
  }

  .ant-table-tbody {
    > tr td {
      padding: 15px 15px !important;
    }
  }

  .font-level {
    color: #f9a601;
    text-decoration: underline;
  }

  .ant-table-thead {
    .ant-table-cell {
      .title {
        display: inline-block;
      }

      .wrap-sort {
        display: inline-block;
        padding: 0 5px;
        line-height: 0.5em;
        cursor: pointer;

        span {
          display: none;
          padding: 0;
          position: relative;
          top: 3px;
          cursor: pointer;

          &.active {
            display: block;
            color: $color-primary-light-2;
          }
        }
      }

      &:hover {
        .wrap-sort span {
          display: inline-block;

          &:hover {
            color: $color-primary-light-2;
          }
        }
      }
    }
  }
}
</style>
<style lang="scss">
.import-user {
  .button-dowload {
    width: -webkit-fill-available;
    height: 60px !important;
  }
  .ant-upload.ant-upload-drag .ant-upload {
    padding: 12px 0 !important;
  }
  .ant-upload.ant-upload-drag {
    border: 2px dashed #c9c9c9 !important;
  }

  .bg-upload {
    padding: 22px 22px 5px 22px;
    background: #fff;
    border-radius: 6px;
  }
  .ant-upload.ant-upload-drag p.ant-upload-drag-icon {
    width: 40px;
    margin-bottom: 0px !important;
  }
  .select-search {
    width: 200px;
    padding-left: 15px;
  }
  .wrap-date-filter {
    position: relative;
  }
  .datefilter {
    right: unset !important;
  }
  .btn-date-filter {
    width: 260px;
    text-align: left;
    .anticon {
      float: right;
      margin-top: 2px;
    }
  }
}
</style>
