<template>
  <div class="dataprivacy">
    <a-card v-if="dataprivacy">
      <h4>Overview</h4>
      <div class="mt-3">
        <span>Accept Date :</span>
        <span v-if="dataprivacy" class="ml-2">{{
          dataprivacy?.date
            ? formatDate(dataprivacy.date, 'DD MMM YYYY', this.isTimezone * -1)
            : '-'
        }}</span>
      </div>
      <div class="row">
        <div class="col-xl-6 col-md-6">
          <div class="bg-content">
            <h6 class="mt-2">Consent version</h6>
            <div class="row mt-4">
              <div class="col-6">
                <div class="d-flex justify-content-between">
                  <div>Terms and Conditions Version :</div>
                  <div>
                    <a-badge
                      :count="
                        dataprivacy?.term_and_condition
                          ? dataprivacy.term_and_condition
                          : '-'
                      "
                      :showZero="true"
                      :overflow-count="999999"
                      :class="type == 'default' ? null : 'count-' + type"
                    />
                  </div>
                </div>
              </div>
              <div class="col-6">
                <div class="d-flex justify-content-between">
                  <div>Consent Age :</div>
                  <div>
                    <a-badge
                      :count="
                        dataprivacy?.consent_age ? dataprivacy.consent_age : '-'
                      "
                      :showZero="true"
                      :overflow-count="999999"
                      :class="type == 'default' ? null : 'count-' + type"
                    />
                  </div>
                </div>
              </div>
              <div class="col-6 mt-2">
                <div class="d-flex justify-content-between">
                  <div>Data Privacy Version :</div>
                  <div>
                    <a-badge
                      :count="
                        dataprivacy?.data_privacy
                          ? dataprivacy.data_privacy
                          : '-'
                      "
                      :showZero="true"
                      :overflow-count="999999"
                      :class="type == 'default' ? null : 'count-' + type"
                    />
                  </div>
                </div>
              </div>
              <div class="col-6 mt-2">
                <div class="d-flex justify-content-between">
                  <div>Marketing Version :</div>
                  <div>
                    <a-badge
                      :count="
                        dataprivacy?.marketing_option
                          ? dataprivacy.marketing_option
                          : '-'
                      "
                      :showZero="true"
                      :overflow-count="999999"
                      :class="type == 'default' ? null : 'count-' + type"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-6 col-md-6">
          <div class="bg-content">
            <div class="d-flex justify-content-between">
              <div class="d-flex">
                <h6 class="mt-2">Marketing Channel</h6>
                <a
                  v-if="
                    (!dataprivacy.unconsent_date ||
                      dataprivacy.date > dataprivacy.unconsent_date) &&
                    datapermissionbyapp.includes('User-List-Data-Privacy-Edit')
                  "
                  class="text-link ml-2 pt-1"
                  @click="toggleEdit()"
                  ><edit-outlined
                /></a>
              </div>
              <div v-if="!edit">
                <a-button class="btn-dataprivacy" @click="toggleCancel()"
                  >Cancel</a-button
                >
                <a-button
                  class="ml-3 btn-dataprivacy"
                  type="primary"
                  @click="confirmUpdateDataprivacy()"
                  >Save Change</a-button
                >
              </div>
            </div>
            <div v-if="dataprivacy" class="row mt-3">
              <div class="col-3 text-right pt-1">
                Line :
                <a-switch
                  :disabled="!setunconsent || edit"
                  @change="disablebutton()"
                  class="mr-3"
                  v-model:checked="dataprivacy.line_marketing"
                />
              </div>
              <div class="col-2"></div>
              <div class="col-4 text-right pt-1">
                Notification :
                <a-switch
                  class="mr-3"
                  :disabled="!setunconsent || edit"
                  @change="disablebutton()"
                  v-model:checked="dataprivacy.notification_marketing"
                />
              </div>
              <div class="col-3"></div>
              <div class="col-3 text-right mt-2">
                SMS :
                <a-switch
                  class="mr-3"
                  :disabled="!setunconsent || edit"
                  @change="disablebutton()"
                  v-model:checked="dataprivacy.sms_marketing"
                />
              </div>
              <div class="col-3"></div>
              <div class="col-3 text-right mt-2">
                Email :
                <a-switch
                  class="mr-3"
                  :disabled="!setunconsent || edit"
                  @change="disablebutton()"
                  v-model:checked="dataprivacy.email_marketing"
                />
              </div>
              <div class="col-3"></div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="dataprivacy && dataprivacy.unconsent_date">
        <div class="flex false mt-3">
          <div>Unconsented on :</div>
          <div class="pl-3">
            {{
              formatDate(
                dataprivacy.unconsent_date,
                'DD MMM YYYY',
                this.isTimezone * -1,
              )
            }}
          </div>
        </div>
      </div>
      <a-button
        v-if="
          (!dataprivacy.unconsent_date ||
            dataprivacy.date > dataprivacy.unconsent_date) &&
          datapermissionbyapp.includes('User-List-Data-Privacy-Edit')
        "
        type="primary"
        class="mt-3"
        :disabled="!setunconsent || !setperrmission"
        @click="showModalconsent()"
        ghost
      >
        Unconsent
      </a-button>
    </a-card>
    <a-card>
      <div class="d-flex justify-content-between">
        <h4>Data History</h4>
        <nav class="nav-search">
          <div class="group-btn">
            <div class="wrap-date-filter">
              <a-button class="btn-date-filter" @click="toggleDateFilter">
                {{ dateFilterValue }}
                <calendar-outlined />
              </a-button>
              <DateFilter
                v-model:value="showDateFilter"
                :hasCalendar="true"
                @selectRange="selectRange($event)"
                @selectCalendar="selectCalendar($event)"
                @reset="resetDatefilter($event)"
                @handleCloseDaterange="showDateFilter = false"
              />
            </div>
            <div class="wrap-filter">
              <a-button type="primary" class="btn-search" @click="clicksearch"
                ><search-outlined /> Search</a-button
              >
            </div>
          </div>
        </nav>
      </div>
      <div class="wrap-table" @scroll="handleScroll">
        <VLoadingTable :value="overlaytable" />
        <a-table
          row-key="partitionkey"
          class="fixed-head"
          :dataSource="dataHistory"
          :columns="columnsDatahistory"
          :pagination="false"
        >
          <template #date="{ record }">
            {{
              record.date
                ? formatDate(
                    record.date,
                    'DD / MMM / YYYY - HH:mm',
                    this.isTimezone * -1,
                  )
                : '-'
            }}
          </template>
          <template #term_and_condition="{ record }">
            {{ record.term_and_condition ? record.term_and_condition : '-' }}
          </template>
          <template #consent_age="{ record }">
            {{ record.consent_age ? record.consent_age : '-' }}
          </template>
          <template #data_privacy="{ record }">
            {{ record.data_privacy ? record.data_privacy : '-' }}
          </template>
          <template #marketing_option="{ record }">
            {{ record.marketing_option ? record.marketing_option : '-' }}
          </template>
          <template #line="{ record }">
            {{ record.line_marketing }}
          </template>
          <template #sms="{ record }">
            {{ record.sms_marketing }}
          </template>
          <template #notification="{ record }">
            {{ record.notification_marketing }}
          </template>
          <template #email="{ record }">
            {{ record.email_marketing }}
          </template>
        </a-table>
      </div>
    </a-card>
  </div>
  <a-modal
    class="modal-set-expire"
    title="Update Marketing Channel"
    :visible="modalMarketing"
    @cancel="handleCancel"
    @ok="UpdateDataprivacy()"
    okText="Save"
    :width="380"
    :centered="true"
    :closable="false"
  >
    <div class="text-h-input">You are about to change channel :</div>
    <div class="flex">
      <div>{{ data }}</div>
    </div>
    <div class="pt-4"><label class="false mr-1 ml-re">*</label>Reason</div>
    <a-textarea
      v-model:value="reason"
      :maxlength="200"
      :auto-size="{ minRows: 2, maxRows: 2 }"
    />
  </a-modal>
  <a-modal
    class="modal-set-expire"
    title="Unconsent"
    :visible="modalConsent"
    @cancel="handleCancel"
    @ok="updateUnconsent()"
    okText="Save"
    :width="350"
    :centered="true"
    :closable="false"
  >
    <div><label class="false mr-1 ml-re">*</label>Reason</div>
    <a-textarea
      v-model:value="reasonunconsent"
      :auto-size="{ minRows: 2, maxRows: 2 }"
    />
  </a-modal>
</template>
<script>
// import _ from 'lodash'
import BzbsUser from '@/core/UserManagement/callapi/BzbsUser'
import CacheHelper from '@/helper/CacheHelper.js'
import ConfigManager from '@/config/ConfigManager.js'
import { CalendarOutlined, EditOutlined } from '@ant-design/icons-vue'
import DateFilter from '@/module/User/component/DateFilter.vue'
import dayjs from 'dayjs'
import moment from 'moment'
import { FormHelper } from '@/helper/FormHelper'
import { config } from '@/config/bzbsconfig.js'

var Cache = new CacheHelper(ConfigManager.getCacheType())
export default {
  name: '',
  components: {
    DateFilter,
    CalendarOutlined,
    EditOutlined,
  },
  props: {
    dataapp: {
      type: Array,
      default: () => [],
    },
  },
  data: function () {
    return {
      isTimezone: config.businessRule.timeZone.hours,
      datapermissionbyapp: JSON.parse(localStorage.getItem('permissionbyapp')),
      type: 'list',
      errInfo: FormHelper.genErrorInfo(10),
      Unconsent: 'T & C Version , Data Privacy Version',
      tabs: 'overview',
      checked: false,
      line: false,
      sms: false,
      email: false,
      notification: false,
      reason: '',
      reasonunconsent: '',
      version: '1.0',
      dataprivacy: null,
      dataprivacyspare: null,
      formatdate1: 'DD MMM YYYY',
      formatdate: 'DD/MMM/YYYY',
      formatdatetable: 'DD / MMM / YYYY - hh:mm A',
      disable: true,
      showDateFilter: false,
      dateFilterValue: 'This Month',
      modalConsent: false,
      modalMarketing: false,
      setperrmission: true,
      setunconsent: true,
      setunconsentmarketingchannel: true,
      startdate: null,
      enddate: null,
      data: [],
      page: 1,
      nomore: false,
      overlaytable: false,
      searchObject: {
        startdate: '',
        enddate: '',
      },
      columnsDatahistory: [
        {
          title: 'Date',
          dataIndex: 'date',
          slots: { customRender: 'date' },
          key: 'card_id',
          width: 120,
          align: 'left',
        },
        {
          title: 'Term and Condition',
          dataIndex: 'term_and_condition',
          slots: { customRender: 'term_and_condition' },
          key: 't&c',
          width: 120,
          align: 'left',
        },
        {
          title: 'Consent Age',
          dataIndex: 'consent_age',
          slots: { customRender: 'consent_age' },
          key: 'consent_age',
          width: 80,
          align: 'left',
        },
        {
          title: 'Data Privacy',
          dataIndex: 'data_privacy',
          slots: { customRender: 'data_privacy' },
          key: 'data_privacy',
          width: 80,
          align: 'left',
        },
        {
          title: 'Marketing option',
          dataIndex: 'marketing_option',
          slots: { customRender: 'marketing_option' },
          key: 'marketing_option',
          width: 80,
          align: 'left',
        },
        {
          title: 'Line',
          dataIndex: 'line',
          slots: { customRender: 'line' },
          key: 'line',
          width: 80,
          align: 'left',
        },
        {
          title: 'SMS',
          dataIndex: 'sms',
          slots: { customRender: 'sms' },
          key: 'sms',
          width: 80,
          align: 'left',
        },
        {
          title: 'Notification',
          dataIndex: 'notification',
          slots: { customRender: 'notification' },
          key: 'notification',
          width: 80,
          align: 'left',
        },
        {
          title: 'Email',
          dataIndex: 'email',
          slots: { customRender: 'email' },
          key: 'email',
          width: 80,
          align: 'left',
        },
      ],
      dataHistory: [],
      emailcase: false,
      edit: true,
    }
  },
  watch: {
    $route(to) {
      if (to.query.tab == 'dataprivacy') {
        this.$store.commit('setLoading', true)
        this.getdataprivacy()
        this.startdate = moment().startOf('month').format('YYYY-MM-DD')
        this.enddate = moment().format('YYYY-MM-DD')
        this.dateFilterValue =
          moment(this.startdate).format('DD/MM/YYYY') +
          ' to ' +
          moment(this.enddate).format('DD/MM/YYYY')
        this.clicksearch()
      }
    },
  },
  mounted() {
    if (this.$route.query.tab == 'dataprivacy') {
      this.$store.commit('setLoading', true)
      this.getdataprivacy()
      this.startdate = moment().startOf('month').format('YYYY-MM-DD')
      this.enddate = moment().format('YYYY-MM-DD')
      this.dateFilterValue =
        moment(this.startdate).format('DD/MM/YYYY') +
        ' to ' +
        moment(this.enddate).format('DD/MM/YYYY')
      this.clicksearch()
    }
  },
  created() {},
  methods: {
    toggleEdit() {
      this.edit = !this.edit
    },
    toggleCancel() {
      this.edit = true
      window.location.reload()
    },
    switchTabs(tabs) {
      if (tabs == 'datahistory') {
        this.clicksearch()
      }
    },
    clicksearch() {
      this.$store.commit('setLoading', true)
      this.page = 1
      this.showDateFilter = false
      this.getDataHistory().then(() => {
        this.$store.commit('setLoading', false)
      })
      console.log(this.dataHistory)
    },
    getdate(date) {
      if (date != null) return moment(date).format(this.formatdate1)
    },
    onChange(checked) {
      console.log(`a-switch to ${checked}`)
    },
    checkData() {
      this.data = []
      Object.keys(this.dataprivacyspare).forEach(element => {
        if (this.dataprivacyspare[element] != this.dataprivacy[element]) {
          switch (element) {
            case 'line_marketing':
              return this.data.push('Line')
            case 'sms_marketing':
              return this.data.push('SMS')
            case 'notification_marketing':
              return this.data.push('Notification')
            case 'email_marketing':
              return this.data.push('Email')
          }
        }
      })
      // if (!this.dataprivacy.email_marketing) {
      //   this.emailcase = false
      // }
      // if (this.emailcase) {
      //   this.data.push('Email')
      // }
      if (this.data.length > 0) {
        this.data = this.data.join(', ')
      }
    },

    getdataprivacy() {
      this.$store.commit('setLoading', true)
      return new Promise((resolve, reject) => {
        BzbsUser.apiGetDataprivacy(this.$route.params.id).subscribe(
          res => {
            if (res.result) {
              this.dataprivacy = { ...res.result }
              this.dataprivacyspare = { ...res.result }
            } else {
              this.dataprivacy = {
                term_and_condition: 0,
                data_privacy: 0,
                consent_age: 0,
                marketing_option: 0,
                email_marketing: false,
                line_marketing: false,
                sms_marketing: false,
                notification_marketing: false,
              }
            }
            // console.log(this.dataprivacy)
            this.setpermission()
            // this.disablebutton()
            this.$store.commit('setLoading', false)
            resolve(this.dataprivacy)
          },
          err => {
            this.errorApi(err)
            reject(err)
          },
        )
      })
    },
    // getDataHistory() {
    //   return new Promise((resolve, reject) => {
    //     BzbsUser.apiGetDataHistory(
    //       this.$route.params.id,
    //       this.searchObject.startdate,
    //       this.searchObject.enddate,
    //     ).subscribe(
    //       res => {
    //         this.dataHistory = res.result
    //         resolve(this.dataHistory)
    //       },
    //       err => {
    //         this.errorApi(err)
    //         reject(err)
    //       },
    //     )
    //   })
    // },
    getDataHistory() {
      console.log()
      this.$store.commit('setLoading', true)
      return new Promise((resolve, reject) => {
        BzbsUser.apiGetDataHistory(
          this.$route.params.id,
          (this.searchObject.startdate = this.startdate
            ? dayjs(this.startdate).format('YYYY-MM-DD')
            : ''),
          (this.searchObject.enddate = this.enddate
            ? dayjs(this.enddate).format('YYYY-MM-DD')
            : ''),
          this.page,
        ).subscribe(
          data => {
            if (this.page == 1) {
              this.dataHistory = []
            }
            if (data.result.normal_data.length > 0) {
              this.dataHistory.push(...data.result.normal_data)
              if (data.result.normal_data.length < 10) {
                this.nomore = true
              } else {
                this.nomore = false
              }
            }
            if (data.result.special_data.length > 0) {
              this.dataHistory.push(...data.result.special_data)
              if (data.result.special_data.length < 10) {
                this.nomore = true
              } else {
                this.nomore = false
              }
            }
            this.$store.commit('setLoading', false)
            resolve(this.dataHistory)
          },
          err => {
            this.errorApi(err)
            reject(err)
          },
        )
      })
    },
    handleScroll(event) {
      if (!this.nomore && !this.overlaytable) {
        if (
          event.target.offsetHeight + event.target.scrollTop + 5 >=
          event.target.scrollHeight
        ) {
          this.overlaytable = true
          this.page++
          this.getDataHistory().then(() => {
            this.overlaytable = false
          })
        }
      }
    },
    setpermission() {
      if (
        this.dataapp.features.every(
          x => x.name != 'User-List-Data-Privacy-Edit',
        )
      ) {
        return (this.setperrmission = false)
      }
      if (
        this.dataapp.features.every(
          x => x.name != 'User-List-Data-Privacy-Edit',
        )
      ) {
        return (this.setunconsent = false)
      }
      if (
        this.dataprivacy.unconsent_date &&
        Date.parse(this.dataprivacy.date) >
          Date.parse(this.dataprivacy.unconsent_date)
      ) {
        return (this.setunconsent = true)
      } else if (!this.dataprivacy.unconsent_date) {
        return (this.setunconsent = true)
      } else {
        return (this.setunconsent = false)
      }
    },
    confirmUpdateDataprivacy() {
      this.checkData()
      this.reason = ''
      this.modalMarketing = true
    },
    updateUnconsent() {
      var subaccountupdate = {
        appid: Cache.getCache('app').id,
        user_id: this.$route.params.id,
        reason: this.reasonunconsent,
        term_and_condition: -1,
        data_privacy: -1,
        consent_age: -1,
        marketing_option: -1,
        email_marketing: false,
        line_marketing: false,
        sms_marketing: false,
        notification_marketing: false,
      }
      return new Promise((resolve, reject) => {
        BzbsUser.apiPostDataprivacy(subaccountupdate)
          .then(res => {
            this.$success({
              title: 'Success',
              okType: 'default',
              centered: true,
              onOk: () => {
                this.modalConsent = false
              },
            })
            resolve(res.data.data)
          })
          .catch(error => {
            this.errorApi(error)
            reject(error)
          })
      })
    },
    UpdateDataprivacy() {
      var subaccountupdate = {
        appid: Cache.getCache('app').id,
        user_id: this.$route.params.id,
        reason: this.reason,
        term_and_condition: this.dataprivacy.term_and_condition,
        data_privacy: this.dataprivacy.data_privacy,
        consent_age: this.dataprivacy.consent_age,
        marketing_option: this.dataprivacy.marketing_option,
        email_marketing: this.dataprivacy.email_marketing,
        line_marketing: this.dataprivacy.line_marketing,
        sms_marketing: this.dataprivacy.sms_marketing,
        notification_marketing: this.dataprivacy.notification_marketing,
      }
      return new Promise((resolve, reject) => {
        BzbsUser.apiPostDataprivacy(subaccountupdate)
          .then(res => {
            this.modalMarketing = false
            this.$success({
              title: 'Success',
              okType: 'default',
              centered: true,
              onOk: () => {
                this.$store.commit('setLoading', true)
                this.getdataprivacy().then(() => {
                  this.toggleEdit()
                  this.$store.commit('setLoading', false)
                })
              },
            })
            resolve(res.data.data)
          })
          .catch(error => {
            this.errorApi(error)
            reject(error)
          })
      })
    },
    showModalconsent() {
      this.reasonunconsent = ''
      this.modalConsent = !this.modalConsent
    },
    handleCancel() {
      this.modalConsent = false
      this.modalMarketing = false
    },
    resetDatefilter() {
      this.dateFilterValue = 'Date Range'
      this.startdate = ''
      this.enddate = ''
    },
    toggleDateFilter() {
      this.showDateFilter = !this.showDateFilter
    },
    selectCalendar(range) {
      if (range.start && range.end) {
        this.startdate = dayjs(range.start).format(this.formatdate)
        this.enddate = dayjs(range.end).format(this.formatdate)
        this.dateFilterValue = this.startdate + ' to ' + this.enddate
      }
    },
    selectRange(range) {
      this.dateFilterValue = range.value
      var params = {}
      if (range.value == 'Today') {
        params.startdate = moment().startOf('day').format(this.formatdate)
        params.enddate = moment().endOf('day').format(this.formatdate)
      }
      if (range.value == 'This Week') {
        params.startdate = moment().startOf('week').format(this.formatdate)
        params.enddate = moment().endOf('week').format(this.formatdate)
      }
      if (range.value == 'Last Week') {
        params.startdate = moment()
          .startOf('week')
          .subtract(7, 'days')
          .format(this.formatdate)
        params.enddate = moment()
          .endOf('week')
          .subtract(7, 'days')
          .format(this.formatdate)
      }
      if (range.value == 'This Month') {
        params.startdate = moment().startOf('month').format(this.formatdate)
        params.enddate = moment().endOf('month').format(this.formatdate)
      }
      if (range.value == 'Last Month') {
        params.startdate = moment()
          .startOf('month')
          .subtract(1, 'months')
          .format(this.formatdate)
        params.enddate = moment()
          .endOf('month')
          .subtract(1, 'months')
          .format(this.formatdate)
      }
      if (range.value == 'Last 3 Weeks') {
        params.startdate = moment()
          .startOf('week')
          .subtract(21, 'days')
          .format(this.formatdate)
        params.enddate = moment()
          .endOf('week')
          .subtract(7, 'days')
          .format(this.formatdate)
      }
      if (range.value == 'Last 6 Weeks') {
        params.startdate = moment()
          .startOf('week')
          .subtract(42, 'days')
          .format(this.formatdate)
        params.enddate = moment()
          .endOf('week')
          .subtract(7, 'days')
          .format(this.formatdate)
      }
      if (range.value == 'Last 3 Months') {
        params.startdate = moment()
          .startOf('month')
          .subtract(3, 'months')
          .format(this.formatdate)
        params.enddate = moment()
          .endOf('month')
          .subtract(1, 'months')
          .format(this.formatdate)
      }
      if (range.value == 'Last 6 Months') {
        params.startdate = moment()
          .startOf('month')
          .subtract(6, 'months')
          .format(this.formatdate)
        params.enddate = moment()
          .endOf('month')
          .subtract(1, 'months')
          .format(this.formatdate)
      }
      this.startdate = params.startdate
      this.enddate = params.enddate
    },
    disablebutton() {
      // if(!this.dataprivacy.line_marketing && !this.dataprivacy.sms_marketing && !this.dataprivacy.notification_marketing && !this.dataprivacy.email_marketing){
      //   this.disable = true
      // }else{
      this.disable = false
      // }
      if (
        this.dataprivacy.line_marketing ||
        this.dataprivacy.sms_marketing ||
        this.dataprivacy.notification_marketing ||
        this.dataprivacy.email_marketing
      ) {
        this.setunconsentmarketingchannel = false
      } else {
        this.setunconsentmarketingchannel = true
      }
    },
    updatetc(event) {
      this.dataprivacy.term_and_condition = event
    },
    updatedpver(event) {
      this.dataprivacy.data_privacy = event
    },
    updateconsent(event) {
      this.dataprivacy.consent_age = event
    },
    updatemkver(event) {
      this.dataprivacy.marketing_option = event
    },
  },
}
</script>
<style lang="scss">
@import '@/style/base/base.scss';

.dataprivacy {
  .bg-content {
    height: 128px;
    margin-top: 10px;
    background-color: #f5f5f5;
    border-radius: 6px;
    padding: 15px;
  }

  .btn-dataprivacy {
    height: 29px !important;
    padding: 0px 15px !important;
  }

  .bg-input {
    border: 1px solid #ffffff !important;
  }

  .marketing-channel {
    padding-left: 50px;
  }

  .wrap-table {
    .ant-table-wrapper {
      min-width: 1200px !important;
      width: 100%;
    }
  }

  .nav-search {
    border-bottom: none !important;

    .wrap-filter {
      margin-left: unset !important;
    }
  }

  .ant-radio-button-wrapper {
    width: 130px !important;
  }

  .wrap-date-filter {
    margin-left: 0;
    padding-left: 0;
    border-left: none !important;
  }

  .ant-radio-button-wrapper:first-child {
    border-radius: 4px 0 0 4px !important;
  }

  .ant-radio-button-wrapper:last-child {
    border-radius: 0 4px 4px 0 !important;
  }

  .unconsent {
    height: 38px !important;
  }

  .wrap-table {
    max-height: calc(100vh - 670px) !important;
  }

  .datefilter {
    right: 0px !important;
    left: unset !important;
  }

  @media only screen and (max-width: 1640px) {
    .bg-content {
      height: 145px;
    }
  }

  @media only screen and (max-height: 880px) {
    .wrap-table {
      min-height: calc(100vh - 450px) !important;
    }
  }
}

.h-modal {
  padding: 20px;
  font-weight: 600;
  color: #666;
}

.text-h-input {
  color: #aaaaaa;
}

.t-date {
  color: #f79443;
  font-weight: 600;
}

.text-color {
  color: #aaaaaa;
}

.text-switch {
  color: #666666;
}

.p-r-50 {
  padding-right: 50px;
}

.p-l-50 {
  padding-left: 50px;
}

.input-dataP {
  background-color: #f3f6f9 !important;
  border-color: #f3f6f9 !important;
}

.p-update {
  padding: 20px 100px 10px 100px;
}

.b-update {
  width: 100%;
  height: 45px !important;
  border-radius: 6px;
  font-weight: 600;
}

.w-text-switch {
  width: 16%;
}

@media only screen and (max-width: 1700px) {
  .w-text-switch {
    width: 22%;
  }
}
</style>
