<template>
  <AppLayout>
    <template v-slot:breadcrumb>
      <TitleBreadcrumbs class="mt-4" title="Import" />
    </template>
    <div class="main-menu approvepoint overflow-x-hidden flex flex-col">
      <a-tabs v-model:activeKey="activeKey" @change="changeTabs">
        <a-tab-pane
          v-for="tab in tab2"
          :key="tab.key"
          :tab="tab.title"
          force-render
        >
          <template v-if="tab.key == 'point'">
            <ImportPoint />
          </template>
          <template v-if="tab.key == 'users'">
            <ImportUsers />
          </template>
        </a-tab-pane>
      </a-tabs>
    </div>
  </AppLayout>
</template>
<script>
// import Footer from '@/components/Layout/Footer/Footer.vue'
// import ConfigManager from '@/config/ConfigManager.js'
import _ from 'lodash'
import ImportUsers from '@/module/Import/component/ImportUsers'
import ImportPoint from '@/module/Import/component/ImportPoint'
import AppLayout from '@/components/Layout/default.vue'
import TitleBreadcrumbs from '@/components/Breadcrumb/Breadcrumb'

export default {
  name: 'UserLevel',
  components: {
    AppLayout,
    TitleBreadcrumbs,
    // Footer,
    ImportPoint,
    ImportUsers,
  },
  data: function () {
    return {
      breadcrumbs: [
        {
          name: null,
          breadcrumbName: 'User Management',
        },
        {
          name: null,
          breadcrumbName: 'Import',
        },
      ],
      datapermission: JSON.parse(localStorage.getItem('permission')),
      datapermissionbyapp: JSON.parse(localStorage.getItem('permissionbyapp')),
      activeKey: '1',
      startValue: null,
      endValue: null,
      startdate: null,
      enddate: null,
      endOpen: false,
      page: 1,
      search: '',
      nomore: false,
      overlaytable: false,
      statusdropdown: null,
      status_dropdown: [
        {
          label: 'All Status',
          value: '',
        },
        {
          label: 'Create',
          value: 'Create',
        },
        {
          label: 'Completed',
          value: 'Completed',
        },
        {
          label: 'Reject',
          value: 'Reject',
        },
      ],
      status: [
        {
          id: 1,
          name: 'Create',
          color: 'blue',
        },
        {
          id: 2,
          name: 'Reject',
          color: 'red',
        },
        {
          id: 3,
          name: 'Completed',
          color: 'green',
        },
      ],
      formatdate: 'DD/MMM/YYYY',
      showDateFilter: false,
      dateFilterValue: 'Date Range',
      tabs: [
        {
          key: 'point',
          title: 'Point',
          permission: 'Import-List-Points-View',
        },
        {
          key: 'users',
          title: 'Users',
          permission: 'Import-List-User-View',
        },
      ],
      tab2: [],
    }
  },
  computed: {
    isOnChangeSelectType() {
      return this.TopBarStore.state.isOnChangeSelectType
    },
    searchbToobar() {
      return this.TopBarStore.state.SearchUserlist
    },
    bcItems: function () {
      return [
        {
          text: 'Setting',
        },
        {
          text: '<div class="font-normal text-sm text-gray-400">Approve List</div>',
        },
      ]
    },
  },
  mounted() {
    this.$store.commit('setLoading', false)
    this.init()
  },
  watch: {},
  methods: {
    init() {
      this.tabs.forEach(x => this.settabpermission(x.key, x.permission))
      this.tab2 = this.tabs
      if (this.$route.query.tab) {
        this.activeKey = this.$route.query.tab
        this.$store.commit('setLoading', false)
      } else {
        if (this.tab2[0].key) {
          this.activeKey = this.tab2[0].key
          this.changeTabs(this.activeKey)
        }
      }
    },
    settabpermission(tab, keytab) {
      if (this.datapermissionbyapp.every(x => x != keytab)) {
        this.tabs = this.tabs.filter(item => {
          return item.key != tab
        })
      }
    },
    changeTabs(activeKey) {
      this.activeKey = activeKey
      var query = { tab: activeKey }
      _.forEach(this.$route.query, (value, key) => {
        if (key != 'tab') {
          query[key] = value
        }
      })
      this.$router.push({
        name: 'import',
        query: query,
      })
    },
    handleStartOpenChange(open) {
      if (!open) {
        this.endOpen = true
      }
    },
    handleEndOpenChange(open) {
      this.endOpen = open
    },
  },
}
</script>
<style lang="scss" scoped>
@import '@/style/base/base.scss';
.approvepoint {
  overflow-x: hidden;
  .table-scroll {
    background: #fff;
    overflow-x: auto;
    overflow-y: hidden;
    border-radius: 6px;
    min-height: calc(100vh - 240px);
    max-height: calc(100vh - 240px);
  }
  .nav-search {
    display: block;
    width: 100%;
    border-bottom: 15px solid #f9f9f9;
    padding: 10px 10px;
    float: left;
    .search {
      float: left;
      width: auto;
      .input-search {
        width: 200px;
        border-right: none;
      }
      .select-search {
        width: 130px;
      }
    }
    .wrap-date-filter {
      float: left;
      // min-width: 290px;
      position: relative;
      margin-left: 10px;
      padding-left: 10px;
    }
    .wrap-filter {
      float: left;
      position: relative;
      margin-left: 10px;
      padding-left: 10px;
    }
    .btn-date-filter {
      width: 260px;
      text-align: left;
      .anticon {
        float: right;
        margin-top: 2px;
      }
    }
    .group-btn-right {
      display: flex;
      float: right;
      .btn-search {
        margin-right: 10px;
        margin-left: 10px;
      }
    }
  }
  .wrap-table-level {
    display: block;
    width: 100%;
    max-height: calc(100vh - 315px);
    overflow-x: auto;
    .ant-table-thead > tr > th {
      padding: 15px 15px !important;
    }
  }
  .ant-table-tbody {
    > tr td {
      padding: 15px 15px !important;
    }
  }
  .font-level {
    color: #f9a601;
    text-decoration: underline;
  }
  .ant-table-thead {
    .ant-table-cell {
      .title {
        display: inline-block;
      }
      .wrap-sort {
        display: inline-block;
        padding: 0 5px;
        line-height: 0.5em;
        cursor: pointer;
        span {
          display: none;
          padding: 0;
          position: relative;
          top: 3px;
          cursor: pointer;
          &.active {
            display: block;
            color: $color-primary-light-2;
          }
        }
      }
      &:hover {
        .wrap-sort span {
          display: inline-block;
          &:hover {
            color: $color-primary-light-2;
          }
        }
      }
    }
  }
}
</style>
<style lang="scss">
@import '@/style/base/base.scss';
.main-menu {
  .datefilter {
    right: 0;
    left: unset;
  }
}
.approvepoint {
  .ant-table-row {
    vertical-align: top !important;
  }
  .ant-table-thead {
    vertical-align: top !important;
  }
  .ant-tabs-nav-wrap {
    background: #fff;
    border-radius: 6px;
    padding: 0 15px;
    border-bottom-width: 1px;
    margin-left: 1.5rem;
    margin-right: 1.5rem;
    // .ant-tabs-ink-bar {
    //   background: $color-primary;
    // }
  }
  .ant-tabs-top > .ant-tabs-nav {
    margin: unset !important;
  }
}
</style>
