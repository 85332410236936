<template>
  <AppLayout>
    <template v-slot:toolbar>
      <Toolbar
        ref="Toolbar"
        @handleSearch="handleSearch($event)"
        @handleClearSearch="handleClearSearch($event)"
        @loadSavelist="callLoadSavedList($event)"
        :clearall="clearall"
        :toggleFilter="toggleFilter"
        :toggleUsertags="toggleUsertags"
        :showAddnewList="showAddnewList"
        :clearSelectuser="clearSelectuser"
        :Removeuser="Removeuser"
        :dataapp="dataapp"
        :count="count"
        :permission="permission"
        :checkselect="checkselect"
        :checkload="checkload"
        :selectedRowKeys="selectedRowKeys"
        :countbyfilter="countbyfilter"
        :loadnofilter="loadnofilter"
        :selectAll="selectAll"
        :items="items"
        :countbyloadsavelist="countbyloadsavelist"
        :titlebysavelist="titlebysavelist"
        :resetloadsavelist="resetloadsavelist"
        :reset="reset"
        :totalrows="totalrows"
        :sumCheck="sumCheck"
        :descriptionsavelist="descriptionsavelist"
        :totalBadge="totalBadge"
      />
    </template>
    <template v-slot:breadcrumb>
      <div class="d-flex justify-content-between">
        <TitleBreadcrumbs
          class="mt-4"
          :title="currentTitle"
          :count="count"
          :description="'Last update : ' + update"
        />
      </div>
    </template>
    <div class="userlist overflow-x-hidden flex flex-col userlist-main">
      <ExportModal
        :visible="toolbarStates.showExport"
        @handleSubmit="showPrepareModal($event)"
        @handleClose="toolbarStates.showExport = false"
      ></ExportModal>
      <ExportModal
        :visible="toolbarStates.showExportAll"
        @handleSubmit="showPrepareModalExportAll($event)"
        @handleClose="toolbarStates.showExportAll = false"
      ></ExportModal>
      <PrepareModal
        :visible="modalController.prepareModalModal"
        @handleClose="modalController.prepareModalModal = false"
      >
      </PrepareModal>
      <CreateNewListModal
        :visible="toolbarStates.showDialogCreateList"
        @handleSubmit="handleOkCreateNewList($event)"
        @handleClose="toolbarStates.showDialogCreateList = false"
      ></CreateNewListModal>
      <DisplayFields
        v-if="alreadyfilter"
        :permissionmaskdata="permissionmaskdata"
        :value="toolbarStates.showPanelCustomFields"
        :headers="columns"
        @apply="applyDisplayFields($event)"
        @handleClose="toolbarStates.showPanelCustomFields = false"
      >
      </DisplayFields>
      <div class="flex-1 w-full px-4">
        <div v-if="selectAll" class="checkall">
          <a-checkbox v-model:checked="checkAll" @change="onCheckAllChange" />
        </div>
        <div class="table-scroll">
          <VLoadingTable :value="overlaytable" />
          <div ref="tablelist" :class="displayFields.length < 9 ? 'table-wrapper-max' : 'table-wrapper'" @scroll="handleScroll">
            <div class="gototop" @click="scrooltotop">
              <img src="@/assets/imgs/totop.svg" />
            </div>
            <a-table
              row-key="userid"
              :class="
                !datapermissionbyapp.includes('Search-Manual-Data')
                  ? 'fixed-head '
                  : 'fixed-head disablecheck'
              "
              :selections="false"
              :row-selection="{
                selectedRowKeys: selectedRowKeys,
                onChange: onSelectChange,
                onSelectAll: onSelectAll,
                getCheckboxProps: record => ({
                  disabled: this.selectAll, // Column configuration not to be checked
                }),
              }"
              :dataSource="items"
              :columns="displayFields"
              :pagination="false"
            >
              <template v-slot:headerCell="{ column }">
                <div class="title-max">
                  <div class="title">
                    {{ column.title }}
                  </div>
                  <div
                    v-if="!datapermissionbyapp.includes('Search-Manual-Data')"
                    class="wrap-sort"
                  >
                    <span class="sort active" @click="scrooltotop">
                      <img
                        v-if="column.sortMode == 'default'"
                        @click="sort(column, 'asc')"
                        src="@/assets/imgs/sort.svg"
                      />
                      <img
                        v-if="column.sortMode == 'asc'"
                        @click="sort(column, 'desc')"
                        src="@/assets/imgs/sortup.svg"
                      />
                      <img
                        v-if="column.sortMode == 'desc'"
                        @click="sort(column, 'default')"
                        src="@/assets/imgs/sortdown.svg"
                      />
                    </span>
                    <!-- <span
                      class="asc"
                      @click="sort(column.dataIndex, 'asc')"
                      :class="{
                        active: sortMode == 'asc' && sortColumn == column.dataIndex,
                      }"
                      >⏶</span
                    >
                    <span
                      class="desc"
                      @click="sort(column.dataIndex, 'desc')"
                      :class="{
                        active:
                          sortMode == 'desc' && sortColumn == column.dataIndex,
                      }"
                      >⏷</span> -->
                  </div>
                </div>
              </template>
              <template v-slot:bodyCell="{ text, column, record }">
                <template v-if="column.key == 'userid'">
                  <div v-if="checkTabsView()" class="useriddotafter">
                    {{ text }}
                  </div>
                  <div v-else class="useriddotafter">
                    <a class="link" @click="goToUserDetail(record)">{{
                      text
                    }}</a>
                    <!-- <router-link
                      :to="{
                        name: 'user_detail',
                        params: {
                          id: text,
                          first_name: record.first_name,
                          last_name: record.last_name,
                        },
                      }"
                    >
                      {{ text }}
                    </router-link> -->
                  </div>
                </template>
                <template v-else-if="column.typedata == 'formatDate'">
                  {{ formatDate(text) }}
                </template>
                <template v-else-if="column.typedata == 'formatDateTimestamp'">
                  {{ formatDateTimestamp(text) }}
                </template>
                <template v-else-if="column.typedata == 'formatBirthDate'">
                  <div v-show="!permissionmaskdata">
                    <span>{{ formatBirthDate(text) }}</span>
                    <span
                      class="pl-2"
                      v-if="text != '-' && text != '' && text != '*'"
                      >({{ record.age }} years)</span
                    >
                  </div>
                  <div v-show="permissionmaskdata">
                    {{ formatBirthDateMaskData(text) }}
                  </div>
                </template>
                <template v-else-if="column.typedata == 'formatPhone'">
                  <div v-show="!permissionmaskdata">
                    {{ formatPhone(text) }}
                  </div>
                  <div v-show="permissionmaskdata">
                    {{ formatPhoneMaskData(text) }}
                  </div>
                </template>
                <template v-else-if="column.typedata == 'formatNumber'">
                  {{ text.toLocaleString() }}
                </template>
                <template v-else-if="column.typedata == 'formatFraudStatus'">
                  <div v-if="text == 'Fraud'" class="fraud">
                    {{ text.toLocaleString() }}
                  </div>
                  <div v-if="text == '-'">No</div>
                </template>
                <template v-else>
                  {{ text }}
                </template>
              </template>
              <!-- </template> -->
            </a-table>
          </div>
        </div>
      </div>
      <!-- <div class="flex-none">
      <Footer />
    </div> -->
      <div class="flyout-zone">
        <VTableSavedList
          :value="toolbarStates.showPanelSavedList"
          :top="offsetTop"
          :items="itemsSavedList"
          @updatevalue="toolbarStates.showPanelSavedList = false"
          @clickload="loadSaveList($event)"
          @clickrefresh="handleRefresh($event)"
          @clickloadversion="handleConfirmLoadVersion($event)"
          @clickdeleteversion="handleDeleteVersion($event)"
        ></VTableSavedList>
        <VTableFilter
          ref="TableFilter"
          @permissionmaskdata="permissionmaskdata"
          @countbyfilter="countbyfilter = $event"
          :value="toolbarStates.showPanelFilter"
          :top="offsetTop"
          :permission="permission"
          @loadSaveList="loadSaveListCache($event)"
          @filterparam="changefilterparam($event)"
          @reset="resetfilter()"
        >
        </VTableFilter>
        <UsertagModal
          :visible="showUserTagModal"
          :selectedRowKeys="selectedRowKeys"
          :clearall="clearall"
          @handleCloseModalTag="showUserTagModal = false"
        ></UsertagModal>
      </div>
    </div>
    <a-modal
      title="Add user to load save list"
      class="modal-usertags"
      :visible="addnewlist_modal"
      @cancel="handleCancel"
      @ok="CreatenewList"
      okText="Save"
      :width="550"
      :centered="true"
      :closable="false"
      :ok-button-props="{ disabled: !checkcha }"
    >
      <div class="radio-expiry">
        <div><label class="false mr-1">*</label>Create new save list</div>
        <a-input
          class="w-100"
          :class="[!checkcha || settitlesavelist ? 'input-error' : '']"
          v-model:value="newlist_title"
          :maxlength="100"
          @change="checkCharacter(newlist_title)"
        />
        <div v-show="!checkcha" class="fs-12-error">
          <div><exclamation-circle-outlined class="mr-1" />{{ texterror }}</div>
        </div>
        <div v-show="settitlesavelist" class="fs-12-error">
          <div>
            <exclamation-circle-outlined class="mr-1" />Please input your
            filename!
          </div>
        </div>
      </div>
      <div class="radio-expiry mt-3">
        <div>Description</div>
        <a-textarea
          v-model:value="newlist_description"
          :maxlength="200"
          :auto-size="{ minRows: 3, maxRows: 3 }"
        />
      </div>
    </a-modal>
    <a-modal
      title="Update this save list"
      class="modal-usertags"
      :visible="modal_confirm_remove"
      @cancel="handleCancel"
      @ok="ConfirmRemoveuser"
      okText="Confirm"
      :width="400"
      :centered="true"
      :closable="false"
    >
      <div class="d-flex justify-content-between">
        <div>You are updating this save list</div>
        <a class="ml-3" @click="showListUpdate()"
          >{{ TextHide }}
          <span v-if="!ListHide" class="showhide-btn"><down-outlined /></span>
          <span v-else class="showhide-btn"><up-outlined /></span>
        </a>
      </div>
      <div v-if="ListHide">
        <div v-if="textadd">Add user : {{ textadd }}</div>
        <div class="fraud" v-if="textdelete">
          Remove user : {{ textdelete }}
        </div>
      </div>
    </a-modal>
    <a-modal
      title="Update save list"
      class="modal-usertags"
      :visible="modal_refresh"
      @cancel="handleCancel"
      @ok="ConfirmRefresh"
      okText="Confirm"
      :width="400"
      :centered="true"
      :closable="false"
    >
      <div>Are you want to update version this save list ?</div>
    </a-modal>
    <a-modal
      title="Description"
      class="modal-usertags"
      :visible="description_modal"
      @cancel="handleCancel"
      okText="Confirm"
      :ok-button-props="{ style: { display: 'none' } }"
      :width="400"
      :centered="true"
      :closable="false"
    >
      <div>{{ descriptionsavelist ? descriptionsavelist : '-' }}</div>
    </a-modal>
    <a-modal
      title="Load save list"
      class="modal-usertags"
      :visible="modal_confirm_loadversion"
      @cancel="handleCancel"
      @ok="ConfirmLoadVersion"
      okText="Confirm"
      :width="400"
      :centered="true"
      :closable="false"
    >
      <div>Are you sure you want to load this version?</div>
    </a-modal>
  </AppLayout>
</template>
<script>
import _ from 'lodash'
import { computed as vueComputed } from 'vue'
// import TopBar from '@/components/Layout/TopBar/TopBar.vue'
// import Header from '@/components/Layout/Header/index.vue'
// import Footer from '@/components/Layout/Footer/Footer.vue'
import AppLayout from '@/components/Layout/default.vue'
import ExportModal from '@/module/User/component/ExportModal.vue'

import UsertagModal from '../component/UserTagModal.vue'
import VTableFilter from '../component/TableFilter.vue'
import VTableSavedList from '../component/TableSavedList.vue'
import Toolbar from '../component/Toolbar.vue'
import TitleBreadcrumbs from '@/components/Breadcrumb/Breadcrumb'
// helper
import { FormHelper } from '@/helper/FormHelper'
// composable
import useToolbarComposable from '@/composables/user/ToolbarComposable'
import CreateNewListModal from '@/module/User/UserList/page/Modal/CreateNewListModal.vue'
import DisplayFields from '@/module/User/component/DisplayFields.vue'

import PrepareModal from '@/module/User/component/PrepareModal.vue'
import BzbsUser from '@/core/UserManagement/callapi/BzbsUser'
import { Userlistmodel } from '@/core/UserManagement/model/Userlistmodel'
import { Loadusertaglog } from '@/core/UserManagement/model/Loadusertaglog'

import moment from 'moment'
import { config } from '@/config/bzbsconfig.js'
import CacheHelper from '@/helper/CacheHelper.js'
import ConfigManager from '@/config/ConfigManager.js'
var cache = new CacheHelper(ConfigManager.getCacheType())
import {
  DeleteOutlined,
  DownOutlined,
  UpOutlined,
  ExclamationCircleOutlined,
} from '@ant-design/icons-vue'

export default {
  name: 'UserList',
  components: {
    // TopBar,
    // Footer,
    // eslint-disable-next-line vue/no-unused-components
    DownOutlined,
    UpOutlined,
    TitleBreadcrumbs,
    DisplayFields,
    VTableFilter,
    VTableSavedList,
    Toolbar,
    ExportModal,
    PrepareModal,
    CreateNewListModal,
    UsertagModal,
    // TopBar,
    AppLayout,
    ExclamationCircleOutlined,
  },
  inject: ['TopBarStore'],
  data: function () {
    return {
      // filterParams: {},
      texterror: 'Only letters (a-z, ก-ฮ) and numbers (0-9) are allowed.',
      breadcrumbs: [
        {
          name: null,
          breadcrumbName: 'User Management',
        },
        {
          name: null,
          breadcrumbName: 'User List',
        },
      ],
      limit: 50,
      datapermissionbyapp: JSON.parse(localStorage.getItem('permissionbyapp')),
      permissioncheckdatalevel: JSON.parse(localStorage.getItem('permission')),
      isTimezone: config.businessRule.timeZone.hours,
      TextHide: 'Show list update',
      ListHide: false,
      checkload: false,
      modal_confirm_remove: false,
      modal_refresh: false,
      description_modal: false,
      modal_confirm_loadversion: false,
      dataoriversion: [],
      dataorirefresh: [],
      descriptionsavelist: '',
      savelistoptions: [],
      listload: null,
      savelist_version_id: '',
      setcreate: 1,
      currentTitle: 'User List',
      modalController: {
        exportModal: false,
        prepareModalModal: false,
      },
      count: null,
      permission: [],
      selected: [],
      displayFields: [],
      items: [],
      overlaytable: false,
      noMoreData: false,
      page: 1,
      pageUserDetail: 1,
      maxPage: 4,
      itemsPerPage: 25,
      dialogCreateNewList: false,
      model: {
        listName: '',
        description: '',
      },
      errInfo: FormHelper.genErrorInfo(2),
      uiDisplayFilter: false,
      uiDisplaySavedList: false,
      offsetTop: 0,
      sortMode: 'default',
      sortColumn: 'userid',
      columns: [
        {
          title: 'User ID',
          dataIndex: 'userid',
          sortMode: 'default',
          key: 'userid',
          align: 'left',
        },
        {
          title: 'Contact Number',
          dataIndex: 'contact_number',
          sortMode: 'default',
          key: 'contact_number',
          typedata: 'formatPhone',
          align: 'left',
        },
        {
          title: 'Line User ID',
          dataIndex: 'line_user_id',
          sortMode: 'default',
          key: 'line_user_id',
        },
        {
          title: 'Profile Update',
          dataIndex: 'timestamp',
          key: 'profile_last_profile',
          sortMode: 'default',
          typedata: 'formatDateTimestamp',
          align: 'left',
        },
        {
          title: 'Birthday',
          dataIndex: 'birthday',
          key: 'birthday',
          sortMode: 'default',
          typedata: 'formatBirthDate',
          align: 'left',
        },
        {
          title: 'Email',
          dataIndex: 'email',
          key: 'email',
          sortMode: 'default',
          align: 'left',
        },
        {
          title: 'First Name',
          dataIndex: 'first_name',
          key: 'first_name',
          sortMode: 'default',
          align: 'left',
        },
        {
          title: 'Last Name',
          dataIndex: 'last_name',
          key: 'last_name',
          sortMode: 'default',
          align: 'left',
        },
        {
          title: 'Gender',
          dataIndex: 'gender',
          key: 'gender',
        },
        {
          title: 'Age',
          dataIndex: 'age',
          sortMode: 'default',
          key: 'age',
        },
        {
          title: 'Join Date',
          dataIndex: 'joined_date',
          key: 'joined_date',
          typedata: 'formatDate',
          sortMode: 'default',
          align: 'left',
        },
        {
          title: 'Province',
          dataIndex: 'province_name',
          key: 'province_name',
          sortMode: 'default',
          align: 'left',
        },
        {
          title: 'Fraud Status',
          dataIndex: 'fraud_status',
          key: 'fraud_status',
          sortMode: 'default',
          typedata: 'formatFraudStatus',
          align: 'left',
        },
        {
          title: 'Terms and Conditions',
          dataIndex: 'term_and_condition',
          key: 'term_and_condition',
          sortMode: 'default',
        },
        {
          title: 'Data Privacy',
          dataIndex: 'data_privacy',
          key: 'data_privacy',
          sortMode: 'default',
        },
        {
          title: 'Marketing Version',
          dataIndex: 'marketing_option',
          key: 'marketing_option',
          sortMode: 'default',
        },
        {
          title: 'Consent Age',
          dataIndex: 'consent_age',
          key: 'consent_age',
          sortMode: 'default',
        },
        {
          title: 'Line Marketing',
          dataIndex: 'line_marketing',
          key: 'line_marketing',
          sortMode: 'default',
        },
        {
          title: 'Notification Marketing',
          dataIndex: 'notification_marketing',
          key: 'notification_marketing',
          sortMode: 'default',
        },
        {
          title: 'SMS Marketing',
          dataIndex: 'sms_marketing',
          key: 'sms_marketing',
          sortMode: 'default',
        },
        {
          title: 'Email Marketing',
          dataIndex: 'email_marketing',
          key: 'email_marketing',
          sortMode: 'default',
        },
        {
          title: 'Level',
          dataIndex: 'level',
          key: 'level',
          sortMode: 'default',
          align: 'left',
        },
        {
          title: 'Total Earn',
          dataIndex: 'total_earn',
          key: 'total_earn',
          sortMode: 'default',
          align: 'right',
        },
        {
          title: 'Total Burn',
          dataIndex: 'total_burn',
          key: 'total_burn',
          sortMode: 'default',
          align: 'right',
        },
        {
          title: 'Point on hand',
          dataIndex: 'point_onhand',
          key: 'point_onhand',
          sortMode: 'default',
          align: 'right',
        },
        {
          title: 'Last Active',
          dataIndex: 'last_active',
          key: 'last_active',
          typedata: 'formatDate',
          sortMode: 'default',
          align: 'left',
        },
        {
          title: 'Last Redeem',
          dataIndex: 'last_redeem',
          key: 'last_redeem',
          typedata: 'formatDate',
          sortMode: 'default',
          align: 'left',
        },
        {
          title: 'Last Point Earned Date',
          dataIndex: 'last_scan',
          key: 'last_scan',
          typedata: 'formatDate',
          sortMode: 'default',
          align: 'left',
        },
        {
          title: 'Channel',
          dataIndex: 'registration_type',
          key: 'registration_type',
          sortMode: 'default',
          align: 'left',
        },
        {
          title: 'No of referral',
          dataIndex: 'number_of_friends',
          key: 'number_of_friends',
          typedata: 'formatNumber',
          sortMode: 'default',
          align: 'right',
        },
        {
          title: 'Card ID / Passport',
          dataIndex: 'id_card_number',
          key: 'id_card_number',
          sortMode: 'default',
          align: 'left',
        },
        {
          title: 'Landmark',
          dataIndex: 'landmark',
          key: 'landmark',
          sortMode: 'default',
          align: 'left',
        },
        {
          title: 'Inactive Date',
          dataIndex: 'inactive_date',
          key: 'inactive_date',
          typedata: 'formatDate',
          sortMode: 'default',
          align: 'left',
        },
      ],
      itemsSavedList: [],
      search_params: {},
      postuserExport: {},
      dataapp: [],
      permissionmaskdata: false,
      params_filter: null,
      current_tagid: null,
      typeCallAPI: 'userDetail',
      isloadmore: false,
      tableWidth: null,
      formatdate: 'DD / MMM / YYYY - HH:mm',
      update: null,
      alreadyfilter: false,
      permissionbyapp: [],
      checkselect: false,
      selectedRowKeys: [],
      addnewlist_modal: false,
      newlist_title: '',
      newlist_description: '',
      selectAll: false,
      originaldata: [],
      countbyloadsavelist: null,
      deleteitems: [],
      additems: [],
      textadd: '',
      textdelete: '',
      dataoridelete: [],
      titlebysavelist: '',
      dataloadsavelist: {},
      itemVersion: [],
      savelistvesion: [],
      savelistversion: '',
      dataloadsavelistversion: {},
      dataloadsavelistversionitem: {},
      loadnofilter: false,
      modaltags: false,
      totalrows: null,
      sumCheck: 0,
      checkcha: true,
      idload: '',
      setnewload: false,
      oridatasetload: [],
      isselected: [],
      showUserTagModal: false,
      checkAll: true,
      reset: false,
      totalBadge: null,
      settitlesavelist: false,
      permissionTabs: [
        'User-List-Account-View',
        'User-List-Additional-Profile-View',
        'User-List-Coupon-View',
        'User-List-Data-Privacy-View',
        'User-List-Level-View',
        'User-List-Mission-View',
        'User-List-Point-View',
        'User-List-Purchase-View',
        'User-List-Redeem-View',
        'User-List-Tags-View',
        'User-List-Wallet-View',
      ],
    }
  },
  provide: function () {
    return {
      tableHeaders: vueComputed(() => this.headers),
    }
  },
  computed: {
    isOnChangeSelectType() {
      return this.TopBarStore.state.isOnChangeSelectType
    },
    searchbToobar() {
      return this.TopBarStore.state.SearchUserlist
    },
  },
  watch: {
    $route(to) {
      if (to.name != 'user_detail' && to.name != 'user_list') {
        cache.removeCache('loadSaveList')
      }
    },
    searchbToobar(params) {
      this.search_params = params
      this.callApiUserlistSearch(params)
    },
  },
  setup: function () {
    const { states } = useToolbarComposable()
    return {
      toolbarStates: states,
    }
  },
  mounted: function () {
    const table = this.$refs.mainTable
    if (table != null) {
      /**@type HTMLElement */
      let el = table.$el
      this.offsetTop = el.offsetTop
    }
    this.columns = this.columns.filter(x => x.key != 'age')
    this.displayFields = _.cloneDeep(this.columns)
    this.setpermissionmaskdata()
    localStorage.removeItem('Useraccount')
    localStorage.removeItem('userinfo')
    // this.changefilterparam(setfilter)
    this.handleLoading(true)
    if (
      cache.getCache('setFilterTotal') &&
      cache.getCache('setFilterTotal') != 'undefined'
    ) {
      this.onSelectAll(true)
      this.countbyfilter = cache.getCache('setFilterTotal')
    }
  },
  created() {
    this.callGetTotallUser()
    // this.callLoadSavedList()
    // this.setfilter = JSON.parse(localStorage.getItem('setFilter', this.params_filter))
  },
  methods: {
    checkTabsView() {
      if (this.permissionTabs.some(x => this.datapermissionbyapp.includes(x))) {
        return false
      } else {
        return true
      }
    },
    onCheckAllChange() {
      this.selectAll = false
    },
    checkCharacter(cha) {
      let regex = /^[0-9a-zA-Zก-๏ ]+$/
      if (regex.test(cha) || cha.length == 0) {
        this.checkcha = true
      } else {
        this.checkcha = false
      }
      if (cha) {
        this.settitlesavelist = false
      }
    },
    clearall() {
      this.selectedRowKeys = []
      this.checkselect = false
    },
    resetloadsavelist() {
      this.$store.commit('setLoading', true)
      this.selectedRowKeys = []
      this.checkselect = false
      this.checkload = false
      this.loadnofilter = false
      this.savelistversion = ''
      this.listload = ''
      this.params_filter = null
      this.countbyloadsavelist = null
      this.selectAll = false
      this.titlebysavelist = ''
      this.totalrows = null
      this.sumCheck = null
      this.countbyfilter = null
      this.$refs.TableFilter.resetFilter()
      this.callApiPostuserDetail().then(() => {
        this.$store.commit('setLoading', false)
      })
    },
    showListUpdate() {
      if (this.TextHide == 'Show list update') {
        this.TextHide = 'Hide list update'
        this.ListHide = true
      } else {
        this.TextHide = 'Show list update'
        this.ListHide = false
      }
    },
    onSelectAll(selectedRowKeys) {
      if (selectedRowKeys > 0) {
        this.checkAll = true
        this.selectAll = true
      } else {
        this.selectAll = false
      }
      return true
    },
    onSelectChange(selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys
      if (selectedRowKeys.length > 0) {
        this.checkselect = true
      } else {
        this.checkselect = false
      }
      this.originaldata.forEach(x => {
        if (!this.selectedRowKeys.includes(x)) this.deleteitems.push(x)
      })
    },
    toggleFilter: function () {
      this.$store.commit('setLoading', true)
      this.$refs.TableFilter.getBlobApi().then(() => {
        this.toolbarStates.showPanelFilter = !this.toolbarStates.showPanelFilter
        this.$store.commit('setLoading', false)
      })
    },
    formatPhone(dirtyNumber) {
      if (dirtyNumber == '-' || dirtyNumber == null) return '-'
      var arrTel = dirtyNumber.split(',')
      var Phonenumber = []
      arrTel.forEach(item => {
        Phonenumber.push(
          item.replace(/\D+/g, '').replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3'),
        )
      })
      return Phonenumber.join(', ')
    },
    formatPhoneMaskData(dirtyNumber) {
      if (dirtyNumber == '*' || dirtyNumber == null) return '*'
      return (
        dirtyNumber.replace(/\D+/g, '').replace(/(\d{3})(\d{3})/, '$1-$2') +
        '-****'
      )
    },
    formatBirthDate(date) {
      if (date == '' || date == '-' || date == '*') {
        return '-'
      } else {
        return moment(date).format('DD / MMM / yyyy')
      }
    },
    formatBirthDateMaskData(date) {
      if (date == '*' || date == '-') {
        return '*'
      } else {
        return moment(date).format('DD / MMM / ****')
      }
    },
    formatDate(date) {
      if (date == '' || date == '-' || date == null) {
        return '-'
      } else {
        return moment(date).format(this.formatdate)
      }
    },
    formatDateTimestamp(date) {
      if (date == '' || date == '-' || date == null) {
        return '-'
      } else {
        return moment(date)
          .add(this.isTimezone, 'hours')
          .format(this.formatdate)
      }
    },
    scrooltotop() {
      this.$refs.tablelist.scrollTop = 0
    },
    handleScroll(event) {
      if (!this.noMoreData && this.isloadmore) {
        if (this.setnewload) {
          event.target.scrollTop = 0
          this.setnewload = false
        }
        if (
          event.target.offsetHeight + event.target.scrollTop + 2 >=
          event.target.scrollHeight
        ) {
          if (this.overlaytable) return
          this.overlaytable = true
          if (this.typeCallAPI == 'userDetail') {
            this.pageUserDetail++
            this.callApiPostuserDetail(true).then(() => {
              this.overlaytable = false
            })
          } else if (this.typeCallAPI == 'userDetailSort') {
            this.pageUserDetail++
            this.callApiPostuserDetailSort(true).then(() => {
              this.overlaytable = false
            })
          } else if (this.typeCallAPI == 'userVersionlist') {
            this.page++
            this.getSavelistByVersion(
              this.dataoriversion.id,
              this.dataoriversion.item.version_no,
              this.page,
              true,
            ).then(() => {
              this.overlaytable = false
            })
          } else {
            this.page++
            this.getSavelistBytagID(this.current_tagid, this.page, true).then(
              () => {
                this.overlaytable = false
              },
            )
          }
        }
      }
    },
    loadSaveListCache(tag) {
      // this.callloadusertaglist(tag.id, 1, false)
      this.getSavelistBytagID(tag.id, 1, false)
    },
    loadSaveList(tag) {
      this.$store.commit('setLoading', true)
      this.selectedRowKeys = []
      this.dataloadsavelist = tag
      this.$refs.TableFilter.getBlobApi().then(() => {
        this.$refs.TableFilter.loadSaveList(tag)
      })
      cache.setCache('loadSaveList', tag)
      this.checkload = true
      this.checkselect = true
      // this.callloadusertaglist(tag.id, 1, false)
      this.totalrows = null
      this.page = 1
      this.getSavelistBytagID(tag.id, 1, false).then(() => {
        // var item = this.itemsSavedList.find(x => x.id == this.current_tagid)
        //     this.countbyloadsavelist = item.total_rows
        var item = this.itemsSavedList.find(x => x.id == this.current_tagid)
        if (item.criteria) {
          this.callApiCount(JSON.parse(item.criteria))
        } else {
          this.loadnofilter = true
        }
        this.idload = item.id
        this.totalrows = item.savelist_versions[0].total_rows
        this.titlebysavelist = item.title
        this.descriptionsavelist = item.description
        this.$store.commit('setLoading', false)
      })
    },
    callApiCountBySearch(params) {
      return new Promise((resolve, reject) => {
        // BzbsUser.apiPostUserListCount(this.checkparams(params))
        BzbsUser.apiPostUserListCount(params)
          .then(res => {
            this.countbyloadsavelist = null
            this.countbyloadsavelist = res.data.result
            resolve(res.data.result)
          })
          .catch(error => {
            this.errorApi(error)
            reject(error)
          })
      })
    },
    callApiCount(params) {
      return new Promise((resolve, reject) => {
        // BzbsUser.apiPostUserListCount(this.checkparams(params))
        BzbsUser.apiPostUserListCount(params)
          .then(res => {
            this.countbyloadsavelist = null
            this.countbyloadsavelist = res.data.result
            resolve(res.data.result)
          })
          .catch(error => {
            this.errorApi(error)
            reject(error)
          })
      })
    },
    handleClearSearch(params) {
      this.$store.commit('setLoading', true)
      this.countbyloadsavelist = null
      this.checkselect = false
      this.selectAll = false
      this.countbyfilter = null
      this.selectedRowKeys = []
      this.typeCallAPI = 'userDetail'
      this.params_filter = Object.assign({}, this.params_filter, params)
      this.pageUserDetail = 1
      this.isloadmore = false
      // this.$refs.TableFilter.callApiCount(this.params_filter).then(count => {
      //   this.$refs.TableFilter.setcount(count)
      // })
      this.$store.commit('setIsSearch', true)
      if (this.datapermissionbyapp.includes('Search-Manual-Data')) {
        window.location.reload()
      } else {
        this.callApiPostuserDetail(false).then(() => {
          this.isloadmore = true
          this.$store.commit('setLoading', false)
        })
      }
    },
    handleSearch(params) {
      this.typeCallAPI = 'userDetail'
      this.params_filter = Object.assign({}, this.params_filter, params)
      this.$store.commit('setLoading', true)
      this.pageUserDetail = 1
      this.isloadmore = false
      // this.$refs.TableFilter.callApiCount(this.params_filter).then(count => {
      //   this.$refs.TableFilter.setcount(count)
      // })
      this.$store.commit('setIsSearch', true)
      console.log(this.params_filter)
      this.callApiCountBySearch(this.params_filter)
      this.callApiPostuserDetail(false).then(() => {
        this.isloadmore = true
        this.$store.commit('setLoading', false)
      })
    },
    applyDisplayFields(header) {
      var displayFields_select = []
      _.forEach(header, item => {
        if (item.value && this.columns.find(x => x.key == item.key)) {
          displayFields_select.push(this.columns.find(x => x.key == item.key))
        }
      })
      this.$nextTick(() => {
        this.displayFields = _.cloneDeep(displayFields_select)
      })
    },
    showPrepareModal(value) {
      // if (this.params_filter == null) {
      //   this.toolbarStates.showExport = false
      //   // setTimeout(() => {
      //   //   this.$error({
      //   //     title: 'กรุณาเลือก ข้อมูล Fillter',
      //   //     centered: true,
      //   //   })
      //   // }, 500)
      //   // return
      // }
      var params = {
        // appid: ConfigManager.getAppId(),
        filter: {
          appid: ConfigManager.getAppId(),
          savelist_id:
            Object.keys(this.dataloadsavelist).length > 0
              ? this.dataloadsavelist.id
              : this.dataloadsavelistversion.id,
          savelist_version_id:
            Object.keys(this.dataloadsavelist).length > 0
              ? this.dataloadsavelist.savelist_versions[0].savelist_version_id
              : this.dataloadsavelistversion.savelist_versions[0]
                  .savelist_version_id,
        },
        name: value.filename,
        description: value.description,
      }
      this.toolbarStates.showExport = false
      this.callExportUser(params)
        .then(() => {
          setTimeout(() => {
            this.modalController.prepareModalModal = true
          }, 500)
        })
        .catch(() => {
          this.modalController.exportModal = false
        })
    },
    callExportUser(params) {
      return new Promise((resolve, reject) => {
        BzbsUser.apiPostQueuesExportLoadSavelist(params)
          .then(res => {
            resolve(res.data.data)
          })
          .catch(error => {
            this.errorApi(error)
            reject(error)
          })
      })
    },
    showPrepareModalExportAll(value) {
      var params = {
        filter: {
          appid: ConfigManager.getAppId(),
        },
        name: value.filename,
        description: value.description,
      }
      this.toolbarStates.showExportAll = false
      this.callExportUserAll(params)
        .then(() => {
          setTimeout(() => {
            this.modalController.prepareModalModal = true
          }, 500)
        })
        .catch(() => {
          this.modalController.exportModal = false
        })
    },
    callExportUserAll(params) {
      return new Promise((resolve, reject) => {
        BzbsUser.apiPostQueuesExport(params)
          .then(res => {
            resolve(res.data.data)
          })
          .catch(error => {
            this.errorApi(error)
            reject(error)
          })
      })
    },
    callloadusertaglist(tagid, page, isloadmore) {
      return new Promise((resolve, reject) => {
        BzbsUser.apiPostByTagID(tagid, page)
          .then(res => {
            // if(res.data.errors && res.data.errors == '401' &&  res.data.message == 'A JWT specified in the request header is expired.'){
            //     this.errorResponse(res.data, true)
            // }
            if (!isloadmore) {
              this.items = []
              this.page = 1
              // this.success();
            }
            if (this.typeCallAPI != 'userTaglist') {
              this.items = []
              this.page = 1
            }
            this.current_tagid = tagid
            this.typeCallAPI = 'userTaglist'
            res.data.result.map(x => {
              let obj = new Userlistmodel(x)
              // obj.tagid = x.tagid;
              this.items.push(obj)
              console.log('New', this.items)
            })
            if (res.data.result.length < 50) {
              this.noMoreData = true
            } else {
              this.noMoreData = false
            }
            // this.$refs.TableFilter.clearFilter()

            this.$store.commit('setLoading', false)
            resolve(res)
          })
          .catch(error => {
            this.errorApi(error)
            reject(error)
          })
      })
    },
    success() {
      this.$success({
        title: 'Success',
        okType: 'default',

        centered: true,
      })
    },
    sort(column, mode) {
      this.displayFields = this.displayFields.map(x => {
        if (column.dataIndex == x.dataIndex) {
          return { ...x, sortMode: mode }
        } else {
          return { ...x, sortMode: 'default' }
        }
      })
      this.typeCallAPI = 'userDetailSort'
      this.sortColumn = column.dataIndex
      column.sortMode = mode
      this.sortMode = mode
      this.overlaytable = true
      this.page = 1
      this.pageUserDetail = 1
      this.callApiPostuserDetailSort(false).then(() => {
        this.overlaytable = false
      })
    },
    handleOkCreateNewList(event) {
      if (this.params_filter == null) {
        this.toolbarStates.showDialogCreateList = false
        setTimeout(() => {
          this.$error({
            title: 'กรุณาเลือก ข้อมูล Fillter',
            centered: true,
          })
        }, 500)
        return
      }

      var params = {
        appid: ConfigManager.getAppId(),
        filter_criteria: this.params_filter,
        title: event.tagname,
        description: event.tagdescription,
      }
      this.toolbarStates.showDialogCreateList = false
      this.callCreateTaglist(params).then(() => {
        this.success()
        this.callLoadSavedList()
      })
    },
    callCreateTaglist(params) {
      return new Promise((resolve, reject) => {
        BzbsUser.apiPostLoadSavedList(params)
          .then(res => {
            resolve(res)
          })
          .catch(error => {
            this.errorApi(error)
            reject(error)
          })
      })
    },
    resetfilter() {
      this.params_filter = null
      this.clearSelectuser()
      this.$refs.Toolbar.resetFilter()
    },
    changefilterparam(event) {
      console.log('eventevent', event)
      this.$store.commit('setLoading', true)
      if (event.params) {
        this.onSelectAll(true)
      }
      this.selectedRowKeys = []
      this.overlay = true
      this.typeCallAPI = 'userDetail'
      this.params_filter = event.params
      this.pageUserDetail = 1
      this.isloadmore = false
      this.callApiPostuserDetail(false).then(() => {
        this.$store.commit('setLoading', false)
        this.totalBadge = cache.getCache('totalBadge')
        this.isloadmore = true
        this.tableWidth =
          this.$refs.tablelist.querySelector('table').clientWidth
      })
    },
    callLoadSavedList() {
      this.$store.commit('setLoading', true)
      return new Promise((resolve, reject) => {
        BzbsUser.apiGetLoadSavedList().subscribe(
          res => {
            this.itemsSavedList = []
            this.itemVersion = res.result
            res.result.map(item => {
              this.itemsSavedList.push(new Loadusertaglog(item))
            })
            _.forEach(this.itemsSavedList, (item, index) => {
              this.savelistoptions[index] = {
                label: item.title,
                value: item.id,
              }
            })
            this.$store.commit('setLoading', false)
            resolve(this.itemsSavedList)
          },
          err => {
            this.errorApi(err)
            reject(err)
          },
        )
      })
    },
    callGetTotallUser() {
      return new Promise((resolve, reject) => {
        BzbsUser.apiGetTotallUser().subscribe(
          res => {
            this.update = res.result.last_updated
              ? moment(res.result.last_updated).format('DD MMM YY - HH:mm')
              : '-'
            this.count = res.result.total_rows.toLocaleString()
            resolve(res.result)
          },
          err => {
            this.errorApi(err)
            reject(err)
          },
        )
      })
    },
    callApiPostuserDetailSort(isloadmore) {
      // if (this.search_params.text_input) return;
      var datapermission = JSON.parse(localStorage.getItem('permissionbyapp'))
      var params = {
        profile_update: {},
        joindate: {},
        birthday: {},
        age: {},
        gender: {},
        province: {},
        fraud_status: {},
        data_privacy: {},
        marketing_option: {},
        consent_age: {},
        line_marketing: {},
        notification_marketing: {},
        sms_marketing: {},
        email_marketing: {},
        user_level: {},
        total_earn: {},
        total_burn: {},
        point_on_hand: {},
        last_active: {},
        last_redeem: {},
        last_scan: {},
        registration_type: {},
        number_of_friend: {},
      }
      if (this.params_filter) {
        params = this.params_filter
        cache.setCache('setFilter', params)
        cache.setCache('setFilterTotal', this.countbyfilter)
        this.$refs.Toolbar.setCacheFilterTotal()
      }
      if (cache.getCache('setFilter')) {
        params = cache.getCache('setFilter')
        this.params_filter = cache.getCache('setFilter')
      }
      params.limit = 50
      params.page_number = this.pageUserDetail
      if (this.sortMode == 'default') {
        params.sort_by = {}
      } else {
        params.sort_by = {
          sort_type: this.sortMode,
          column_names: [this.sortColumn],
        }
      }
      if (!datapermission.includes('User-List-Filter-Fraud-Users')) {
        delete params.fraud_status
      }
      return new Promise((resolve, reject) => {
        BzbsUser.apiPostUserList(params)
          .then(res => {
            // if(res.data.errors && res.data.errors == '401' &&  res.data.message == 'A JWT specified in the request header is expired.'){
            //     this.errorResponse(res.data, true)
            // }
            if (!isloadmore) {
              this.items = []
              this.pageUserDetail = 1
            }
            if (this.typeCallAPI != 'userDetailSort') {
              this.items = []
              this.pageUserDetail = 1
            }
            this.typeCallAPI = 'userDetailSort'
            if (res.data.result.length < this.limit) {
              this.noMoreData = true
            } else {
              this.noMoreData = false
            }
            const total = res.data.result.map(item => {
              if (this.selectAll == true) {
                this.selectedRowKeys.push(item.userid)
              }
              this.items.push(new Userlistmodel(item))
            })
            resolve(total)
          })
          .catch(error => {
            this.errorApi(error)
            console.log(error)
            reject(error)
          })
      })
    },
    callApiPostuserDetail(isloadmore) {
      // if (this.search_params.text_input) return;
      var datapermission = JSON.parse(localStorage.getItem('permissionbyapp'))
      var params = {
        profile_update: {},
        joindate: {},
        birthday: {},
        age: {},
        gender: {},
        province: {},
        fraud_status: {},
        data_privacy: {},
        marketing_option: {},
        consent_age: {},
        line_marketing: {},
        notification_marketing: {},
        sms_marketing: {},
        email_marketing: {},
        user_level: {},
        total_earn: {},
        total_burn: {},
        point_on_hand: {},
        last_active: {},
        last_redeem: {},
        last_scan: {},
        registration_type: {},
        number_of_friend: {},
      }
      if (this.params_filter) {
        params = this.params_filter
        cache.setCache('setFilter', params)
        cache.setCache('setFilterTotal', this.countbyfilter)
        this.$refs.Toolbar.setCacheFilterTotal()
      }
      if (cache.getCache('setFilter')) {
        params = cache.getCache('setFilter')
        this.params_filter = cache.getCache('setFilter')
      }
      params.limit = 50
      params.page_number = this.pageUserDetail
      params.sort_by = {
        // sort_type: this.sortMode,
        // column_names: [this.sortColumn],
      }
      if (!datapermission.includes('User-List-Filter-Fraud-Users')) {
        delete params.fraud_status
      }
      return new Promise((resolve, reject) => {
        BzbsUser.apiPostUserList(params)
          .then(res => {
            // if(res.data.errors && res.data.errors == '401' &&  res.data.message == 'A JWT specified in the request header is expired.'){
            //     this.errorResponse(res.data, true)
            // }
            if (!isloadmore) {
              this.items = []
              this.pageUserDetail = 1
            }
            if (this.typeCallAPI != 'userDetail') {
              this.items = []
              this.pageUserDetail = 1
            }
            this.typeCallAPI = 'userDetail'
            if (res.data.result.length < this.limit) {
              this.noMoreData = true
            } else {
              this.noMoreData = false
            }
            const total = res.data.result.map(item => {
              if (this.selectAll == true) {
                this.selectedRowKeys.push(item.userid)
              }
              this.items.push(new Userlistmodel(item))
            })
            resolve(total)
          })
          .catch(error => {
            this.$store.commit('setLoading', false)
            this.$error({
              content: error.response.data.error[0].message,
              centered: true,
              okType: 'default',
              autoFocusButton: null,
              onOk: () => {
                this.$refs.Toolbar.clearSearch()
              },
            })
            reject(error)
          })
      })
    },
    callApiUserlistSearch(params) {
      this.overlaytable = true
      if (params.text_input == '') {
        this.callApiPostuserDetail(false).then(() => {
          this.overlaytable = false
        })
      } else {
        BzbsUser.apiPostuserlistSearch(null, params, 1)
          .then(res => {
            if (
              res.data.errors &&
              res.data.errors == '401' &&
              res.data.message ==
                'A JWT specified in the request header is expired.'
            ) {
              this.errorResponse(res.data, true)
            }
            this.items = []
            res.data.data.map(item => {
              this.items.push(new Userlistmodel(item))
            })
            this.overlaytable = false
          })
          .catch(error => {
            this.overlaytable = false
            this.errorApi(error)
          })
      }
    },
    // getad() {
    //   return new Promise((resolve, reject) => {
    //     BzbsUser.apiGetaduser().subscribe(
    //       res => {
    //         this.dataad = res.result
    //         this.dataapp = this.dataad.applications.find(
    //           x => x.application_id == cache.getCache('app')?.id,
    //         )
    //         if (this.dataapp) {
    //           this.permissionbyapp = this.dataapp.features.map(x => x.name)
    //           this.setpermissionmaskdata()
    //         }
    //         resolve(this.dataad)
    //       },
    //       err => {
    //         this.errorApi(err)
    //         reject(err)
    //       },
    //     )
    //   })
    // },
    setpermissionmaskdata() {
      if (!this.datapermissionbyapp.includes('User-List-Filter-Fraud-Users')) {
        this.columns = this.columns.filter(x => x.key != 'fraud_status')
      }
      if (
        this.permissioncheckdatalevel.features.find(
          x =>
            x.data_levels[0] != 'Mask-Data' ||
            x.data_levels[1] == 'Normal-Data',
        )
      ) {
        this.permissionmaskdata = false
      } else {
        this.permissionmaskdata = true
        this.columns = this.columns.filter(x => x.key != 'age')
      }
      this.alreadyfilter = true
    },

    showAddnewList() {
      // this.callLoadSavedList()
      this.newlist_title = ''
      this.newlist_description = ''
      this.addnewlist_modal = true
    },
    clearSelectuser() {
      this.checkselect = false
      this.selectAll = false
      this.selectedRowKeys = []
    },
    Removeuser() {
      this.additems = []
      this.deleteitems = []
      this.selectedRowKeys.forEach(x => {
        if (!this.originaldata.includes(x)) this.additems.push(x)
      })
      this.originaldata.forEach(x => {
        if (!this.selectedRowKeys.includes(x)) this.deleteitems.push(x)
      })
      this.textdelete = this.deleteitems.toString()
      this.textadd = this.additems.toString()
      // if (this.items.every(x => x.checked == true)) {
      //   this.selectAll = true
      // } else {
      //   this.selectAll = false
      // }
      this.modal_confirm_remove = true
    },
    ConfirmRemoveuser() {
      this.setnewload = true
      this.AddUserToSavelist().then(() => {
        this.$refs.TableFilter.resetFilter()
      })
      this.modal_confirm_remove = false
    },
    handleCancel() {
      this.description_modal = false
      this.addnewlist_modal = false
      this.modal_confirm_remove = false
      this.modal_refresh = false
      this.modal_confirm_loadversion = false
      this.modaltags = false
      this.textdelete = ''
      this.textadd = ''
      this.checkcha = true
    },
    CreatenewList() {
      if (this.setcreate == 1) {
        if (this.newlist_title) {
          this.setnewload = true
          this.CreateUserToSavelist()
        } else {
          this.settitlesavelist = true
        }
      } else {
        this.AddUserToSavelist()
      }
    },
    CreateUserToSavelist() {
      if (this.selectedRowKeys.length == this.items.length) {
        this.selectAll = true
      } else {
        this.selectAll = false
      }
      var datanewlist = {
        appid: ConfigManager.getAppId(),
        title: this.newlist_title,
        description: this.newlist_description,
        select_all: this.selectAll,
        filter_criteria: this.params_filter ? this.params_filter : null,
        select_user_ids: this.selectAll ? null : this.selectedRowKeys,
        delete_user_ids: null,
      }
      return new Promise((resolve, reject) => {
        BzbsUser.apiPostCreateUserToSavelist(datanewlist)
          .then(res => {
            // this.visible = false
            this.addnewlist_modal = false
            this.$success({
              title: 'Success',
              okType: 'default',
              centered: true,
              onOk: () => {
                this.selectedRowKeys = []
                this.checkselect = false
                this.selectAll = false
              },
            })
            resolve(res.data.data)
          })
          .catch(error => {
            this.errorApi(error)
            reject(error)
          })
      })
    },
    AddUserToSavelist() {
      var datanewlist = {
        id:
          Object.keys(this.dataloadsavelist).length > 0
            ? this.dataloadsavelist.id
            : this.dataoriversion.id,
        is_active: true,
        savelist_version_id:
          Object.keys(this.dataloadsavelist).length > 0
            ? this.dataloadsavelist.savelist_versions[0].savelist_version_id
            : this.dataoriversion.item.savelist_version_id,
        appid: ConfigManager.getAppId(),
        title:
          Object.keys(this.dataloadsavelist).length > 0
            ? this.dataloadsavelist.title
            : this.dataoriversion.title,
        description: this.newlist_description,
        select_all: this.selectAll,
        filter_criteria:
          Object.keys(this.dataloadsavelist).length > 0
            ? JSON.parse(this.dataloadsavelist.criteria || null)
            : JSON.parse(this.dataoriversion.itemlatest.criteria || null),
        select_user_ids: this.selectedRowKeys,
        delete_user_ids: this.deleteitems,
      }
      return new Promise((resolve, reject) => {
        BzbsUser.apiPutAddtoSavelist(datanewlist)
          .then(res => {
            // this.visible = false
            this.addnewlist_modal = false
            this.$success({
              title: 'Success',
              okType: 'default',
              centered: true,
              onOk: () => {
                this.$store.commit('setLoading', true)
                this.selectedRowKeys = []
                this.checkselect = false
                this.checkload = false
                this.savelistversion = ''
                this.listload = ''
                this.params_filter = null
                this.countbyloadsavelist = null
                this.selectAll = false
                this.titlebysavelist = ''
                window.location.reload()
              },
            })
            resolve(res.data.data)
          })
          .catch(error => {
            this.errorApi(error)
            reject(error)
          })
      })
    },
    getSavelistBytagID(tagid, page, isloadmore) {
      return new Promise((resolve, reject) => {
        BzbsUser.apiGetSavelistBytagID(tagid, page).subscribe(
          res => {
            if (!isloadmore) {
              this.items = []
              this.page = 1
              // this.success();
            }
            if (this.typeCallAPI != 'userTaglist') {
              this.items = []
              this.page = 1
            }
            this.current_tagid = tagid
            this.typeCallAPI = 'userTaglist'
            this.sumCheck = 0
            this.oridatasetload = []
            this.isselected = []
            console.log(res.result)
            if (res.result) {
              res.result.map(x => {
                let obj = new Userlistmodel(x)
                // obj.tagid = x.tagid;
                this.items.push(obj)
                this.oridatasetload.push(obj)
              })
              this.oridatasetload.forEach(x => {
                if (x.checked == true) {
                  this.selectedRowKeys.push(x.userid)
                }
              })
              this.items.forEach(x => {
                if (x.checked == true) {
                  this.isselected.push(x.userid)
                  this.sumCheck = this.sumCheck + 1
                }
              })
              if (this.items.every(x => x.checked == true)) {
                this.selectAll = true
              }
              this.originaldata = this.isselected
              if (res.result.length < 50) {
                this.noMoreData = true
              } else {
                this.noMoreData = false
              }
            } else {
              this.callApiPostuserDetail().then(() => {
                this.$store.commit('setLoading', false)
              })
            }
            this.$store.commit('setLoading', false)
            resolve(res)
          },
          err => {
            this.errorApi(err)
            reject(err)
          },
        )
      })
    },
    handleRefresh(item) {
      this.modal_refresh = true
      this.dataorirefresh = item
      console.log(this.dataorirefresh)
    },
    ConfirmRefresh() {
      this.RefreshSavelist(this.dataorirefresh)
      this.modal_refresh = false
    },
    RefreshSavelist(item) {
      this.$store.commit('setLoading', true)
      var datarefresh = {
        id: item.id,
        is_active: true,
        appid: ConfigManager.getAppId(),
        title: item.title,
        description: item.description,
        filter_criteria: item.criteria ? JSON.parse(item.criteria) : null,
      }
      return new Promise((resolve, reject) => {
        BzbsUser.apiPutRefreshsavelist(datarefresh)
          .then(res => {
            // this.visible = false
            this.addnewlist_modal = false
            this.$success({
              title: 'Success',
              okType: 'default',
              centered: true,
              onOk: () => {
                this.selectedRowKeys = []
                this.checkselect = false
                this.callLoadSavedList()
                if (item.id == this.idload) {
                  window.location.reload()
                }
              },
            })
            this.$store.commit('setLoading', false)
            resolve(res.data.data)
          })
          .catch(error => {
            this.errorApi(error)
            reject(error)
          })
      })
    },
    handleConfirmLoadVersion(tag) {
      this.modal_confirm_loadversion = true
      console.log(tag)
      this.dataoriversion = tag
      console.log(this.dataoriversion)
      console.log(this.dataoriversion.version_no)
    },
    ConfirmLoadVersion() {
      this.$store.commit('setLoading', true)
      this.checkload = true
      this.totalrows = null
      this.selectedRowKeys = []
      this.checkselect = false
      this.checkload = false
      this.savelistversion = ''
      this.listload = ''
      this.params_filter = null
      this.countbyloadsavelist = null
      this.selectAll = false
      this.titlebysavelist = ''
      this.totalrows = null
      this.sumCheck = null
      this.countbyfilter = null
      this.page = 1
      this.setnewload = true
      this.$refs.TableFilter.getBlobApi()
      this.getSavelistByVersion(
        this.dataoriversion.id,
        this.dataoriversion.item.version_no,
        this.page,
        false,
      ).then(() => {
        this.callApiCount(JSON.parse(this.dataoriversion.itemlatest.criteria))
        this.checkload = true
        this.checkselect = true
        this.$store.commit('setLoading', false)
      })
      this.titlebysavelist = this.dataoriversion.itemlatest.title
      this.descriptionsavelist = this.dataoriversion.itemlatest.description
      this.dataloadsavelistversion = this.dataoriversion.itemlatest
      this.totalrows = this.dataoriversion.item.total_rows
      this.dataloadsavelistversionitem =
        this.dataoriversion.item.savelist_version_id
      this.modal_confirm_loadversion = false
      this.toolbarStates.showPanelSavedList = false
    },
    getSavelistByVersion(tagid, version, page, isloadmore) {
      return new Promise((resolve, reject) => {
        BzbsUser.apiGetSavelistByVersion(tagid, version, page).subscribe(
          res => {
            if (!isloadmore) {
              this.items = []
              this.page = 1
              // this.success();
            }
            if (this.typeCallAPI != 'userVersionlist') {
              this.items = []
              this.page = 1
            }
            this.sumCheck = 0
            this.current_tagid = tagid
            this.typeCallAPI = 'userVersionlist'
            this.oridatasetload = []
            this.isselected = []
            res.result.map(x => {
              let obj = new Userlistmodel(x)
              // obj.tagid = x.tagid;
              this.items.push(obj)
              this.oridatasetload.push(obj)
            })
            this.oridatasetload.forEach(x => {
              if (x.checked == true) {
                this.selectedRowKeys.push(x.userid)
              }
            })
            this.items.forEach(x => {
              if (x.checked == true) {
                this.isselected.push(x.userid)
                this.sumCheck = this.sumCheck + 1
              }
            })
            if (this.items.every(x => x.checked == true)) {
              this.selectAll = true
            }
            this.originaldata = this.isselected
            if (res.result.length < 50) {
              this.noMoreData = true
            } else {
              this.noMoreData = false
            }
            this.$store.commit('setLoading', false)
            resolve(res)
          },
          err => {
            this.errorApi(err)
            reject(err)
          },
        )
      })
    },
    handleDeleteVersion(tag) {
      this.dataoridelete = tag
      this.$confirm({
        centered: true,
        autoFocusButton: null,
        title: 'Delete Version?',
        content:
          'Deleting this version will permanently remove. Are you sure to delete this version?',
        okText: 'Yes',
        cancelText: 'No',
        icon: <DeleteOutlined />,
        okButtonProps: {
          type: 'primary',
          danger: true,
        },
        onOk: () => {
          this.DeleteSavelist(
            tag.id,
            tag.item.savelist_version_id,
            tag.itemlatest,
          )
        },
        onCancel() {
          console.log('Cancel')
        },
      })
    },
    DeleteSavelist(id, savelist_version_id, itemlatest) {
      this.$store.commit('setLoading', true)
      return new Promise((resolve, reject) => {
        BzbsUser.apiDeleteSavelist(id, savelist_version_id).subscribe(
          res => {
            this.$store.commit('setLoading', false)
            this.$success({
              title: 'Success',
              okType: 'default',
              centered: true,
              onOk: () => {
                if (itemlatest.length == 1 && this.idload == id) {
                  this.$store.commit('setLoading', true)
                  this.callApiPostuserDetail().then(() => {
                    this.selectedRowKeys = []
                    this.checkselect = false
                    this.savelistversion = ''
                    this.listload = ''
                    this.params_filter = null
                    this.countbyloadsavelist = null
                    this.selectAll = false
                    this.titlebysavelist = ''
                    this.toolbarStates.showPanelSavedList = false
                    this.checkload = false
                    this.$store.commit('setLoading', false)
                  })
                  // } else if (Object.keys(this.dataloadsavelist).length > 0) {
                  //   if (this.dataloadsavelistversionitem == savelist_version_id) {
                  //     this.callLoadSavedList()
                  //     this.selectedRowKeys = []
                  //     this.getSavelistByVersion(
                  //       this.dataloadsavelistversion.id,
                  //       this.dataloadsavelist.savelist_versions[0].version_no,
                  //       1,
                  //       true,
                  //     ).then(() => {
                  //       this.countbyloadsavelist = null
                  //       this.callApiCount(
                  //         JSON.parse(this.dataloadsavelistversion.criteria),
                  //       )
                  //     })
                  //   }
                } else if (
                  Object.keys(this.dataloadsavelistversion).length > 0
                ) {
                  if (this.dataloadsavelistversionitem == savelist_version_id) {
                    this.$store.commit('setLoading', true)
                    this.callApiPostuserDetail().then(() => {
                      this.selectedRowKeys = []
                      this.checkselect = false
                      this.savelistversion = ''
                      this.listload = ''
                      this.params_filter = null
                      this.countbyloadsavelist = null
                      this.selectAll = false
                      this.titlebysavelist = ''
                      this.checkload = false
                      this.toolbarStates.showPanelSavedList = false
                      this.$store.commit('setLoading', false)
                    })
                  } else {
                    this.callLoadSavedList()
                  }
                  // this.$store.commit('setLoading', true)
                  // this.callApiPostuserDetail().then(() => {
                  //   this.selectedRowKeys = []
                  //   this.checkselect = false
                  //   this.savelistversion = ''
                  //   this.listload = ''
                  //   this.params_filter = null
                  //   this.countbyloadsavelist = null
                  //   this.selectAll = false
                  //   this.titlebysavelist = ''
                  //   this.checkload = false
                  //   this.toolbarStates.showPanelSavedList = false
                  //   this.$store.commit('setLoading', false)
                  // })
                } else {
                  this.callLoadSavedList()
                }
              },
            })
            resolve(res)
          },
          err => {
            this.errorApi(err)
            reject(err)
          },
        )
      })
    },
    toggleUsertags() {
      this.showUserTagModal = true
    },
    goToUserDetail(record) {
      localStorage.setItem('userinfo', JSON.stringify(record))
      this.$router.push({
        name: 'user_detail',
        params: {
          id: record.userid,
        },
      })
    },
  },
}
</script>

<style lang="scss">
@import '@/style/base/base.scss';

.userlist {
  overflow-x: hidden;

  .table-scroll {
    background: #fff;
    overflow-x: auto;
    overflow-y: hidden;
    // box-shadow: 0 2px 4px 0 rgb(102 108 139 / 10%);
    min-height: calc(100vh - 245px);
    max-height: calc(100vh - 245px);
  }

  .table-wrapper {
    overflow-y: auto;
    min-height: calc(100vh - 245px);
    max-height: calc(100vh - 245px);

    .useriddotafter {
      width: 350px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .gototop {
      position: absolute;
      right: 2.5%;
      bottom: 7%;
      z-index: 3;
      cursor: pointer;
    }

    .ant-table-content {
      width: max-content;
    }
  }
  .table-wrapper-max {
    overflow-y: auto;
    min-height: calc(100vh - 245px);
    max-height: calc(100vh - 245px);

    .useriddotafter {
      width: 350px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .gototop {
      position: absolute;
      right: 2.5%;
      bottom: 7%;
      z-index: 3;
      cursor: pointer;
    }
  }

  .ant-table-thead {
    .ant-table-cell {
      .title {
        display: inline-block;
      }

      .wrap-sort {
        display: inline-block;
        padding: 0 5px;
        line-height: 0.5em;
        cursor: pointer;

        span {
          display: none;
          padding: 0;
          position: relative;
          top: 3px;
          cursor: pointer;

          &.active {
            display: block;
            color: $color-primary-light-2;
          }
        }
      }
    }
  }

  .checkall {
    margin-top: 10px;
    position: absolute;
    z-index: 10;
    margin-left: 16px;
  }
}

.userlist-main {
  .text-base {
    display: none;
  }
}

.ant-modal-body {
  .ant-radio-group {
    width: -webkit-fill-available;
  }

  .showhide-btn {
    font-size: 13px;
  }
}

.rowSelecttionNone {
  .ant-table-selection-column {
    display: none !important;
  }
}

.title-max {
  width: max-content;
}

.mt5 {
  margin-top: -5px !important;
}
.disablecheck {
  .ant-table-selection-column {
    display: none !important;
  }
}
</style>
