import ConfigManager from '@/config/ConfigManager.js'
import CacheHelper from '@/helper/CacheHelper.js'
import BzbsApi from '@/core/Http/BzbsApi.js'
import { map } from 'rxjs/operators'
// import Account from '@/helper/AccountHelper.js';
var Cache = new CacheHelper(ConfigManager.getCacheType())
/**
 * @method apiGetPDPAVersion
 * @method apiGetPDPAContent
 * @method apiPostuserDetail
 * @method apiPostuserlistSearch
 * @method apiPostuserExport
 * @method apiPostuserExportLog
 */

export default {
  /**
   * Return PDPA version
   */
  config: function () {
    return Cache.getCache('bzbsConfig')
  },
  Dataarray(data) {
    let formData = new FormData()
    Object.keys(data).forEach(item => {
      formData.append(item, data[item])
    })
    return formData
  },
  removenull(obj) {
    console.log(obj)
    Object.keys(obj).forEach(key => {
      if (obj[key] === null || obj[key] === 'undefined') {
        delete obj[key]
      } else if (typeof obj[key] === 'string') {
        obj[key] = obj[key].trim()
      }
    })
    return obj
  },
  apiGetPDPAVersion: function () {
    const appid = Cache.getCache('app').id

    var url = ConfigManager.getBlobUrl() + `/pdpaconsent/${appid}/version`
    return BzbsApi.connectBzbsGetBlob(url).pipe(map(res => res.data))
  },

  /**
   * Return PDPA contents.
   * @param {String} contentType Terms, privacy, etc..
   * @param {Number} version PDPA content version
   * @param {Number} language PDPA language code.
   */
  apiGetPDPAContent: function (contentType, version, language) {
    const appid = Cache.getCache('app').id

    const url =
      ConfigManager.getBlobUrl() +
      `/pdpaconsent/${appid}/${contentType}/${version}/${language}`
    return BzbsApi.connectBzbsGetBlob(url).pipe(map(res => res.data))
  },

  /**
   * @param {string} token
   * @param {number|string} termandcondition
   * @param {number|string} dataprivacy
   */
  apiPostuserDetail: function (token, obj, page) {
    const appid = Cache.getCache('app').id

    var params = {
      profile_update: obj.profile_update,
      joindate: obj.joindate,
      birthday: obj.birthday,
      age: obj.age,
      gender: obj.gender,
      province: obj.province,
      fraud_status: obj.fraud_status,
      data_privacy: obj.data_privacy,
      term_and_condition: obj.term_and_condition,
      user_level: obj.user_level,
      total_earn: obj.total_earn,
      total_burn: obj.total_burn,
      point_on_hand: obj.point_on_hand,
      last_active: obj.last_active,
      last_redeem: obj.last_redeem,
      last_scan: obj.last_scan,
      registration_type: obj.registration_type,
      number_of_friend: obj.number_of_friend,
      staffid: obj.staffid,
    }
    var url =
      ConfigManager.getUsermgrUrl() + '/main/userDetail/' + appid + '/' + page
    return BzbsApi.connectBzbsPostDataSci(url, null, params)
  },
  apiPostuserlistSearch: function (token, params, page) {
    const appid = Cache.getCache('app').id

    var url =
      ConfigManager.getUsermgrUrl() +
      '/search/userlistSearch/' +
      appid +
      '?page=' +
      page
    return BzbsApi.connectBzbsPostDataSci(url, null, params)
  },
  apiPostuserExport: function (obj, appid) {
    var params = {
      profile_update: obj.profile_update,
      joindate: obj.joindate,
      birthday: obj.birthday,
      age: obj.age,
      gender: obj.gender,
      province: obj.province,
      fraud_status: obj.fraud_status,
      data_privacy: obj.data_privacy,
      term_and_condition: obj.term_and_condition,
      user_level: obj.user_level,
      total_earn: obj.total_earn,
      total_burn: obj.total_burn,
      point_on_hand: obj.point_on_hand,
      last_active: obj.last_active,
      last_redeem: obj.last_redeem,
      last_scan: obj.last_scan,
      registration_type: obj.registration_type,
      number_of_friend: obj.number_of_friend,
      columns: obj.columns,
      filename: obj.filename,
      description: obj.description,
      staffid: obj.staffid,
    }

    var url = ConfigManager.getUsermgrUrl() + '/main/userExport/' + appid
    return BzbsApi.connectBzbsPostDataSci(url, null, params)
  },
  apiPostuserExportLog: function (obj, appid) {
    var params = {
      startdate: obj.startdate,
      enddate: obj.enddate,
      clearlist: obj.clearlist,
      search_filename: obj.search_filename,
      staffid: obj.staffid,
    }
    if (obj.uid) params.uid = obj.uid
    var url = ConfigManager.getUsermgrUrl() + '/main/userExportLog/' + appid
    return BzbsApi.connectBzbsPostDataSci(url, null, params)
  },
  apiPostUserExportRefresh: function (obj) {
    const appid = Cache.getCache('app').id

    var params = {
      uid: obj.uid,
      status: obj.status,
      download: obj.download,
      staffid: obj.staffid,
    }
    var url =
      ConfigManager.getUsermgrUrl() + '/main/userExportLogRefresh/' + appid
    return BzbsApi.connectBzbsPostDataSci(url, null, params)
  },
  apiPostUserlistFilter: function (params) {
    const appid = Cache.getCache('app').id

    var url = ConfigManager.getUsermgrUrl() + '/filter/userlistFilter/' + appid
    return BzbsApi.connectBzbsPostDataSci(url, null, params)
  },
  apiPostCreatetaglist: function (params) {
    const appid = Cache.getCache('app').id

    var url = ConfigManager.getUsermgrUrl() + '/usertag/createtaglist/' + appid
    return BzbsApi.connectBzbsPostDataSci(url, null, params)
  },
  apiPostLoadusertaglog: function (params, page) {
    const appid = Cache.getCache('app').id

    params = {
      tagid: '',
      staffid: params.staffid,
    }
    var url =
      ConfigManager.getUsermgrUrl() +
      '/usertag/loadusertaglog/' +
      appid +
      '/' +
      page
    return BzbsApi.connectBzbsPostDataSci(url, null, params)
  },
  apiPostLoadusertaglist: function (params, page) {
    const appid = Cache.getCache('app').id

    var url =
      ConfigManager.getUsermgrUrl() +
      '/usertag/loadusertaglist/' +
      appid +
      '/' +
      page
    return BzbsApi.connectBzbsPostDataSci(url, null, params)
  },
  apiPostDatareportAccount: function (params, page) {
    const appid = Cache.getCache('app').id

    var url =
      ConfigManager.getUsermgrUrl() +
      '/report/datareportaccount/' +
      appid +
      '/' +
      page
    return BzbsApi.connectBzbsPostDataSci(url, null, params)
  },
  apiPostReportCouponHistory: function (obj, page) {
    const appid = Cache.getCache('app').id

    var params = obj
    var url =
      ConfigManager.getUsermgrUrl() +
      '/report/datareportcouponhistory/' +
      appid +
      '/' +
      page
    return BzbsApi.connectBzbsPostDataSci(url, null, params)
  },
  apiPostReportCouponhistoryExport: function (obj) {
    const appid = Cache.getCache('app').id

    var params = obj
    var url =
      ConfigManager.getUsermgrUrl() + '/report/dataexportcouponhistory/' + appid
    return BzbsApi.connectBzbsPostDataSci(url, null, params)
  },
  apiPostReportRedeem(params, appid, page) {
    var url =
      ConfigManager.getUsermgrUrl() +
      '/report/datareportredeem/' +
      appid +
      '/' +
      page
    return BzbsApi.connectBzbsPostDataSci(url, null, params)
  },
  apiPostReportRedeemExport(params, appid) {
    var url =
      ConfigManager.getUsermgrUrl() + '/report/dataexportredeem/' + appid
    return BzbsApi.connectBzbsPostDataSci(url, null, params)
  },
  apiPostponeExpire(params) {
    var url =
      ConfigManager.getUsermgrAPI() +
      '/api/v5.0/campaign/serial/postpone_expire'
    return BzbsApi.connectBzbsPost(url, params)
  },
  apiPostReportPurchase(params, appid, page) {
    var url =
      ConfigManager.getUsermgrUrl() +
      '/report/datareportpurchase/' +
      appid +
      '/' +
      page
    return BzbsApi.connectBzbsPostDataSci(url, null, params)
  },
  apiPostReportPurchaseExport(params, appid) {
    var url =
      ConfigManager.getUsermgrUrl() + '/report/dataexportpurchase/' + appid
    return BzbsApi.connectBzbsPostDataSci(url, null, params)
  },
  apiPostReportPoint(params, appid, page) {
    var url =
      ConfigManager.getUsermgrUrl() +
      '/report/datareportpoint/' +
      appid +
      '/' +
      page
    return BzbsApi.connectBzbsPostDataSci(url, null, params)
  },
  apiPostReportPointExport(params, appid) {
    var url = ConfigManager.getUsermgrUrl() + '/report/dataexportpoint/' + appid
    return BzbsApi.connectBzbsPostDataSci(url, null, params)
  },
  apiPostUserintag: function (obj) {
    const appid = Cache.getCache('app').id

    var params = obj
    var url = ConfigManager.getUsermgrUrl() + '/usertag/userintag/' + appid
    return BzbsApi.connectBzbsPostDataSci(url, null, params)
  },

  //new API

  apiPostUserListCount(params) {
    const appid = Cache.getCache('app').id
    var url =
      ConfigManager.getUsermgrAPI() + '/api/v1.0/userlist/count?appid=' + appid
    return BzbsApi.connectBzbsPost(url, params)
  },
  apiPostUserList(params) {
    const appid = Cache.getCache('app').id
    var url =
      ConfigManager.getUsermgrAPI() + '/api/v2.0/userlist?appid=' + appid
    return BzbsApi.connectBzbsPost(url, params)
  },
  apiGetTotallUser(params) {
    const appid = Cache.getCache('app').id
    var url =
      ConfigManager.getUsermgrAPI() +
      '/api/v1.0/userlist/countallbyappid?appid=' +
      appid
    return BzbsApi.connectBzbsGet(url, params).pipe(
      map(res => {
        return res.data
      }),
    )
  },
  apiGetQueuesExport(params) {
    var url = ConfigManager.getUsermgrAPI() + '/api/v1.0/worker/queues'
    return BzbsApi.connectBzbsGet(url, params).pipe(
      map(res => {
        return res.data
      }),
    )
  },
  apiPostQueuesExport(params) {
    var url =
      ConfigManager.getUsermgrAPI() +
      '/api/v1.0/exportdata/queue/addqueueuserlist'
    return BzbsApi.connectBzbsPost(url, params)
  },
  apiGetLoadSavedList() {
    const appid = Cache.getCache('app').id

    var params = {
      appid: appid,
    }
    var url = ConfigManager.getUsermgrAPI() + '/api/v5.0/savelist'
    return BzbsApi.connectBzbsGet(url, params).pipe(
      map(res => {
        return res.data
      }),
    )
  },
  apiPostLoadSavedList(params) {
    var url = ConfigManager.getUsermgrAPI() + '/api/v5.0/savelist'
    return BzbsApi.connectBzbsPost(url, params)
  },
  apiPostByTagID(tagid, page) {
    const appid = Cache.getCache('app').id
    var url =
      ConfigManager.getUsermgrAPI() +
      '/api/v2.0/userlist/bytagid?id=' +
      tagid +
      appid +
      '&page_number=' +
      page +
      '&limit=50'
    return BzbsApi.connectBzbsPost(url)
  },
  apiGetUserProfile(user_id, appid) {
    var url =
      ConfigManager.getUsermgrAPI() +
      '/api/v2.0/user/accountinfo?user_id=' +
      user_id +
      '&appid=' +
      appid
    return BzbsApi.connectBzbsGet(url).pipe(
      map(res => {
        return res.data
      }),
    )
  },
  apiPostUserPoint(params) {
    const appid = Cache.getCache('app').id

    var url = ConfigManager.getUsermgrAPI() + '/api/v1.0/point?appid=' + appid
    return BzbsApi.connectBzbsPost(url, params)
  },
  // apiPostUserCoupon(params) {
  // 	const appid = Cache.getCache("app").id;

  // 	var url = ConfigManager.getUsermgrAPI() + "/api/v1.0/user/couponhistory?appid=" +appid;
  // 	return BzbsApi.connectBzbsPost(url, params)
  // },
  apiGetUserCouponHistory: function (
    user_id,
    lastid,
    product,
    serialcode,
    from_date,
    to_date,
  ) {
    const appid = Cache.getCache('app').id
    var url =
      ConfigManager.getUsermgrAPI() +
      '/api/v5.0/user/couponhistories?appid=' +
      appid +
      '&user_id=' +
      user_id +
      '&lastid=' +
      lastid +
      '&limit=20&product=' +
      product +
      '&coupon_code=' +
      serialcode +
      '&from_date=' +
      from_date +
      '&to_date=' +
      to_date
    return BzbsApi.connectBzbsGet(url, null).pipe(map(res => res.data))
  },
  apiPostReportCouponExport(params) {
    var url =
      ConfigManager.getUsermgrAPI() +
      '/api/v1.0/exportdata/queue/addqueueusercouponhistory'
    // return BzbsApi.connectBzbsPostDataSci(url, token, params);
    return BzbsApi.connectBzbsPost(url, params)
  },

  apiGetUserPurchase: function (
    user_id,
    page,
    paymentId,
    channel,
    status,
    from_date,
    to_date,
  ) {
    const appid = Cache.getCache('app').id
    var url =
      ConfigManager.getUsermgrAPI() +
      '/api/v1.0/user/purchases?user_id=' +
      user_id +
      '&appid=' +
      appid +
      '&page_number=' +
      page +
      '&limit=20' +
      '&channel=' +
      channel +
      '&payment_status=' +
      status +
      '&start_date=' +
      from_date +
      '&end_date=' +
      to_date +
      '&payment_id=' +
      (paymentId ?? '')
    return BzbsApi.connectBzbsGet(url, null).pipe(map(res => res.data))
  },
  apiPostPurchaseExport(params) {
    const appid = Cache.getCache('app').id
    params.filter.appid = appid
    var url =
      ConfigManager.getUsermgrAPI() +
      '/api/v1.0/exportdata/queue/addqueueuserpurchase'
    return BzbsApi.connectBzbsPost(url, params)
  },
  apiGetPurchasePaymentId: function (paymentid, page) {
    const appid = Cache.getCache('app').id
    var url =
      ConfigManager.getUsermgrAPI() +
      '/api/v1.0/user/purchasedetails?tran_id=' +
      paymentid +
      '&appid=' +
      appid +
      '&page_number=' +
      page +
      '&limit=10'
    return BzbsApi.connectBzbsGet(url).pipe(map(res => res.data))
  },
  apiPostPurchasePaymentIdExport(params) {
    var url =
      ConfigManager.getUsermgrAPI() +
      '/api/v1.0/exportdata/queue/addqueueuserpurchasedetails'
    return BzbsApi.connectBzbsPost(url, params)
  },
  apiGetUserRedeems: function (
    user_id,
    page,
    campaign_id,
    campaign_name,
    agency_name,
    category_name,
    serial,
    parcel_no,
    campaign_type,
    start_date,
    end_date,
  ) {
    console.log(start_date, end_date)
    const appid = Cache.getCache('app').id
    var url =
      ConfigManager.getUsermgrAPI() +
      '/api/v1.0/user/redeems?user_id=' +
      user_id +
      '&appid=' +
      appid +
      '&page_number=' +
      page +
      '&limit=20' +
      '&campaign_id=' +
      (campaign_id ?? '') +
      '&campaign_name=' +
      campaign_name +
      '&agency_name=' +
      agency_name +
      '&category_name=' +
      category_name +
      '&serial=' +
      serial +
      '&parcel_no=' +
      parcel_no +
      '&campaign_type=' +
      campaign_type +
      '&start_date=' +
      start_date +
      '&end_date=' +
      end_date
    return BzbsApi.connectBzbsGet(url, null).pipe(map(res => res.data))
  },
  apiPostRedeemsExport(params) {
    const appid = Cache.getCache('app').id
    params.filter.appid = appid
    var url =
      ConfigManager.getUsermgrAPI() +
      '/api/v1.0/exportdata/queue/addqueueuserredeem'
    // return BzbsApi.connectBzbsPostDataSci(url, token, params);
    return BzbsApi.connectBzbsPost(url, params)
  },
  apiGetUserPointLogs: function (user_id, page, month, year) {
    const appid = Cache.getCache('app').id
    var url =
      ConfigManager.getUsermgrAPI() +
      '/api/v1.0/user/pointlogs?user_id=' +
      user_id +
      '&appid=' +
      appid +
      '&page_number=' +
      page +
      '&limit=20'
    if (month) {
      url = url + '&month=' + month
    }
    if (year) {
      url = url + '&year=' + year
    }
    return BzbsApi.connectBzbsGet(url, null).pipe(map(res => res.data))
  },
  apiGetUserPointLogs2: function (user_id, filter_year_months, page) {
    var data = {
      filter_year_months: filter_year_months,
    }
    const appid = Cache.getCache('app').id
    var url =
      ConfigManager.getUsermgrAPI() +
      '/api/v2.0/user/pointlogs?appid=' +
      appid +
      '&user_id=' +
      user_id +
      '&lastid=' +
      page +
      '&limit=20'
    return BzbsApi.connectBzbsPost(url, data)
  },
  apiPostPointExport(params) {
    const appid = Cache.getCache('app').id
    params.filter.appid = appid
    var url =
      ConfigManager.getUsermgrAPI() +
      '/api/v2.0/exportdata/queue/addqueueuserpointlog'
    // return BzbsApi.connectBzbsPostDataSci(url, token, params);
    return BzbsApi.connectBzbsPost(url, params)
  },
  apiGetAllExportData: function (
    id,
    export_data_type,
    start_created_date,
    end_created_date,
    search_filename,
    page_number,
  ) {
    const appid = Cache.getCache('app').id
    var url =
      ConfigManager.getUsermgrAPI() +
      '/api/v1.0/exportdata/queue/allexportdata?export_data_type=' +
      (export_data_type ?? '') +
      '&id=' +
      (id ?? '') +
      '&appid=' +
      (appid ?? '') +
      '&start_created_date=' +
      (start_created_date ?? '') +
      '&end_created_date=' +
      (end_created_date ?? '') +
      '&search_filename=' +
      (search_filename ?? '') +
      '&page_number=' +
      (page_number ?? '') +
      '&limit=50'
    return BzbsApi.connectBzbsGet(url, null).pipe(map(res => res.data))
  },
  apiGetAllExportDataCallcenter: function (
    id,
    export_data_type,
    start_created_date,
    end_created_date,
    search_filename,
    page_number,
  ) {
    var url =
      ConfigManager.getUsermgrAPI() +
      '/api/v5.0/exportdata/queue/allexportdata/callcenter?export_data_type=' +
      (export_data_type ?? '') +
      '&id=' +
      (id ?? '') +
      '&start_created_date=' +
      (start_created_date ?? '') +
      '&end_created_date=' +
      (end_created_date ?? '') +
      '&search_filename=' +
      (search_filename ?? '') +
      '&page_number=' +
      (page_number ?? '') +
      '&limit=50'
    return BzbsApi.connectBzbsGet(url, null).pipe(map(res => res.data))
  },
  apiPutClearList(params) {
    var url =
      ConfigManager.getUsermgrAPI() + '/api/v5.0/exportdata/queue/deletelist'
    return BzbsApi.connectBzbsPut(url, params)
  },
  //userProfile
  apiGetAccountInfo: function (user_id) {
    const appid = Cache.getCache('app').id
    var url =
      ConfigManager.getUsermgrAPI() +
      '/api/v2.0/user/accountinfo?appid=' +
      appid +
      '&user_id=' +
      user_id
    return BzbsApi.connectBzbsGet(url, null).pipe(map(res => res.data))
  },
  apiGetZipCode: function (zip_code) {
    const appid = Cache.getCache('app').id
    var url =
      ConfigManager.getUsermgrAPI() +
      '/api/v2.0/address?zip_code=' +
      zip_code +
      '&appid=' +
      appid
    return BzbsApi.connectBzbsGet(url, null).pipe(map(res => res.data))
  },
  apiGetHistoryLogs: function (user_id, type) {
    const appid = Cache.getCache('app').id
    var url =
      ConfigManager.getUsermgrAPI() +
      '/api/v2.0/historylogs/user?appid=' +
      appid +
      '&user_id=' +
      user_id +
      '&type=' +
      type
    return BzbsApi.connectBzbsGet(url, null).pipe(map(res => res.data))
  },
  apiPutAccountInfoUpdate(params) {
    var url =
      ConfigManager.getUsermgrAPI() + '/api/v2.0/user/accountinfo/update'
    return BzbsApi.connectBzbsPut(url, params)
  },
  apiPutDeactivateUser(params) {
    var url = ConfigManager.getUsermgrAPI() + '/api/v2.0/user/deactivateuser'
    return BzbsApi.connectBzbsPut(url, params)
  },
  apiPutResetPassword(params) {
    var url = ConfigManager.getUsermgrAPI() + '/api/v2.0/user/resetpassword'
    return BzbsApi.connectBzbsPut(url, params)
  },
  //Additional Profile
  apiGetAdditionalProfile: function (user_id) {
    const appid = Cache.getCache('app').id
    var url =
      ConfigManager.getUsermgrAPI() +
      '/api/v5.0/user/additionalprofile?appid=' +
      appid +
      '&user_id=' +
      user_id
    return BzbsApi.connectBzbsGet(url, null).pipe(map(res => res.data))
  },
  apiPutAdditionalProfile(params) {
    var url = ConfigManager.getUsermgrAPI() + '/api/v5.0/user/additionalprofile'
    return BzbsApi.connectBzbsPut(url, params)
  },

  //Data privacy
  apiGetDataprivacy: function (user_id) {
    const appid = Cache.getCache('app').id
    var url =
      ConfigManager.getUsermgrAPI() +
      '/api/v2.0/user/privacy?appid=' +
      appid +
      '&user_id=' +
      user_id
    return BzbsApi.connectBzbsGet(url, null).pipe(map(res => res.data))
  },
  apiGetDataHistory: function (user_id, from_date, to_date, page_number) {
    const appid = Cache.getCache('app').id
    var url =
      ConfigManager.getUsermgrAPI() +
      '/api/v5.0/user/privacy/histories?appid=' +
      appid +
      '&user_id=' +
      user_id +
      '&from_date=' +
      from_date +
      '&to_date=' +
      to_date +
      '&page_number=' +
      page_number +
      '&limit=10'
    return BzbsApi.connectBzbsGet(url, null).pipe(map(res => res.data))
  },
  apiGetApproveDatalist: function (
    document_status,
    from_create_date,
    to_create_date,
    page,
  ) {
    const appid = Cache.getCache('app').id
    var url =
      ConfigManager.getUsermgrAPI() +
      '/api/v5.0/user/privacy/documents/approvelist?appid=' +
      appid +
      '&document_status=' +
      document_status +
      '&from_create_date=' +
      from_create_date +
      '&to_create_date=' +
      to_create_date +
      '&page_number=' +
      page +
      '&limit=20'
    return BzbsApi.connectBzbsGet(url, null).pipe(map(res => res.data))
  },
  apiGetApproveDetaillist: function (id) {
    const appid = Cache.getCache('app').id
    var url =
      ConfigManager.getUsermgrAPI() +
      '/api/v5.0/user/privacy/documents/details?id=' +
      id +
      '&appid=' +
      appid
    return BzbsApi.connectBzbsGet(url, null).pipe(map(res => res.data))
  },
  DataarrayToformData(dataList) {
    const appid = Cache.getCache('app').id
    let formData = new FormData()
    formData.append(`id`, dataList.id)
    formData.append(`remarks`, dataList.remarks)
    formData.append(`appid`, appid)
    return formData
  },
  apiPostApprovePrivacy(dataList) {
    var url =
      ConfigManager.getUsermgrAPI() +
      '/api/v5.0/user/privacy/documents/approved'
    const params = this.DataarrayToformData(dataList)
    return BzbsApi.connectBzbsPostRawData(url, params)
  },
  apiPostRejectPrivacy(dataList) {
    var url =
      ConfigManager.getUsermgrAPI() +
      '/api/v5.0/user/privacy/documents/rejected'
    const params = this.DataarrayToformData(dataList)
    return BzbsApi.connectBzbsPost(url, params)
  },
  apiGetFilterBlob: function () {
    const appid = Cache.getCache('app').id
    var url =
      ConfigManager.getUsermgrAPI() + '/api/v2.0/userfilter?appid=' + appid
    return BzbsApi.connectBzbsGet(url, null).pipe(map(res => res.data))
  },
  apiPostDataprivacy(params) {
    var url = ConfigManager.getUsermgrAPI() + '/api/v5.0/user/privacy/documents'
    return BzbsApi.connectBzbsPost(url, params)
  },

  //Display Fields
  apiGetDisplayFields: function () {
    const appid = Cache.getCache('app').id
    var url =
      ConfigManager.getUsermgrAPI() +
      '/api/v2.0/user/displayfields?appid=' +
      appid
    return BzbsApi.connectBzbsGet(url, null).pipe(map(res => res.data))
  },
  apiPutDisplayFields(params) {
    var url =
      ConfigManager.getUsermgrAPI() + '/api/v2.0/user/displayfields/userlist'
    return BzbsApi.connectBzbsPut(url, params)
  },

  ///Userlevel Role-straff
  apiGetDataUserlevelRoleStraff: function (user_id) {
    const appid = Cache.getCache('app').id
    var url =
      ConfigManager.getUsermgrAPI() +
      '/api/v3.0/user/levels?appid=' +
      appid +
      '&user_id=' +
      user_id
    return BzbsApi.connectBzbsGet(url, null).pipe(map(res => res.data))
  },
  apiPutDatalevel(params) {
    var url = ConfigManager.getUsermgrAPI() + '/api/v3.0/user/levels'
    return BzbsApi.connectBzbsPut(url, params)
  },
  // apiGetLogHistoryUserlevelRoleStraff: function (user_id) {
  //   const appid = Cache.getCache('app').id
  //   var url =
  //     ConfigManager.getUsermgrAPI() +
  //     '/api/v3.0/user/levels/logs?appid=' +
  //     appid +
  //     '&user_id=' +
  //     user_id +
  //     '&limit=500'
  //   return BzbsApi.connectBzbsGet(url, null).pipe(map(res => res.data))
  // },
  apiGetLogHistoryLog: function (user_id, type) {
    const appid = Cache.getCache('app').id
    var url =
      ConfigManager.getUsermgrAPI() +
      '/api/v2.0/historylogs/user?appid=' +
      appid +
      '&user_id=' +
      user_id +
      '&type=' +
      type +
      '&limit=500'
    return BzbsApi.connectBzbsGet(url, null).pipe(map(res => res.data))
  },

  ///Userlevel Role-admin
  apiGetDataUserlevelRoleAdmin: function (level_name) {
    const appid = Cache.getCache('app').id
    var url =
      ConfigManager.getUsermgrAPI() +
      '/api/v3.0/userlevels?appid=' +
      appid +
      '&level_name=' +
      (level_name ?? '')
    return BzbsApi.connectBzbsGet(url, null).pipe(map(res => res.data))
  },
  apiPutDataUserlevel(params) {
    var url = ConfigManager.getUsermgrAPI() + '/api/v3.0/userlevels'
    return BzbsApi.connectBzbsPost(url, params)
  },
  apiGetLogHistoryUserlevelRoleAdmin: function () {
    const appid = Cache.getCache('app').id
    var url =
      ConfigManager.getUsermgrAPI() +
      '/api/v2.0/historylogs/user?appid=' +
      appid +
      '&type=' +
      'MasterUserLevel' +
      '&limit=500'
    return BzbsApi.connectBzbsGet(url, null).pipe(map(res => res.data))
  },
  apiGetLogHistoryLogMain: function (type) {
    const appid = Cache.getCache('app').id
    var url =
      ConfigManager.getUsermgrAPI() +
      '/api/v2.0/historylogs/user?appid=' +
      appid +
      '&type=' +
      type +
      '&limit=500'
    return BzbsApi.connectBzbsGet(url, null).pipe(map(res => res.data))
  },

  ///timeline
  apiGetDataTimeline: function () {
    var url = ConfigManager.getUsermgrAPI() + '/api/v2.0/user/timelines/filter'
    return BzbsApi.connectBzbsGet(url, null).pipe(map(res => res.data))
  },
  apiPostDatatracelogs(params) {
    var url = ConfigManager.getUsermgrAPI() + '/api/v3.0/user/tracelogs'
    return BzbsApi.connectBzbsPost(url, params)
  },
  apiPostDatatracelogsdetail(params) {
    var url = ConfigManager.getUsermgrAPI() + '/api/v3.0/user/tracelogs/details'
    return BzbsApi.connectBzbsPost(url, params)
  },
  apiGetaduser: function () {
    var url = ConfigManager.getUsermgrAPI() + '/api/v1.0/aduser'
    return BzbsApi.connectBzbsGet(url, null).pipe(map(res => res.data))
  },

  //accountunblockuser
  apiGetUserBlock: function (user_id) {
    const appid = Cache.getCache('app').id
    var url =
      ConfigManager.getUsermgrAPI() +
      '/api/v4.0/user/blocked?appid=' +
      appid +
      '&user_id=' +
      user_id
    return BzbsApi.connectBzbsGet(url, null).pipe(map(res => res.data))
  },
  apiGetUserBlacklist: function (user_id) {
    const appid = Cache.getCache('app').id
    var url =
      ConfigManager.getUsermgrAPI() +
      '/api/v4.0/user/blacklist?appid=' +
      appid +
      '&user_id=' +
      user_id
    return BzbsApi.connectBzbsGet(url, null).pipe(map(res => res.data))
  },
  apiPostUserUnblock(params) {
    var url = ConfigManager.getUsermgrAPI() + '/api/v4.0/user/blockedandunblock'
    return BzbsApi.connectBzbsPost(url, params)
  },
  apiPostUnBlacklist(params) {
    var url =
      ConfigManager.getUsermgrAPI() + '/api/v4.0/user/blacklistandunblacklist'
    return BzbsApi.connectBzbsPost(url, params)
  },
  apiPostUnBlockCoupon(params) {
    var url = ConfigManager.getUsermgrAPI() + '/api/v5.0/user/coupon/unblock'
    return BzbsApi.connectBzbsPost(url, params)
  },
  apiPostUnLinkline(params) {
    var url = ConfigManager.getUsermgrAPI() + '/api/v4.0/user/unlinklineaccount'
    return BzbsApi.connectBzbsPost(url, params)
  },
  ///UserlistRealtime
  apiGetUserlistRealtime: function (
    user_id,
    contact_number,
    first_name,
    last_name,
    email,
    ref_id,
    help_id,
    id_card_number,
    page,
  ) {
    const appid = Cache.getCache('app').id
    var url =
      ConfigManager.getUsermgrAPI() +
      '/api/v4.0/userlist/realtime?appid=' +
      appid +
      '&user_id=' +
      user_id +
      '&contact_number=' +
      contact_number +
      '&first_name=' +
      first_name +
      '&last_name=' +
      last_name +
      '&email=' +
      email +
      '&ref_id=' +
      ref_id +
      '&help_id=' +
      help_id +
      '&id_card_number=' +
      id_card_number +
      '&page_number=' +
      page +
      '&limit=20'
    return BzbsApi.connectBzbsGet(url, null).pipe(map(res => res.data))
  },

  ///Wallet
  apiGetWallets: function (
    user_id,
    card_id,
    issuer,
    type,
    from_expired_date,
    to_expired_date,
  ) {
    const appid = Cache.getCache('app').id
    var url =
      ConfigManager.getUsermgrAPI() +
      '/api/v4.0/user/wallets?appid=' +
      appid +
      '&user_id=' +
      user_id +
      '&card_id=' +
      card_id +
      '&issuer=' +
      issuer +
      '&type=' +
      type +
      '&from_expired_date=' +
      from_expired_date +
      '&to_expired_date=' +
      to_expired_date
    return BzbsApi.connectBzbsGet(url, null).pipe(map(res => res.data))
  },
  apiGetWalletDetail: function (user_id, card_id, page) {
    const appid = Cache.getCache('app').id
    var url =
      ConfigManager.getUsermgrAPI() +
      '/api/v4.0/user/wallets/details?appid=' +
      appid +
      '&user_id=' +
      user_id +
      '&card_id=' +
      card_id +
      '&page_number=' +
      page +
      '&limit=10'
    return BzbsApi.connectBzbsGet(url, null).pipe(map(res => res.data))
  },
  apiPostWalletExport(params) {
    const appid = Cache.getCache('app').id
    params.filter.appid = appid
    var url =
      ConfigManager.getUsermgrAPI() +
      '/api/v4.0/exportdata/queue/addqueueuserwalletcard'
    return BzbsApi.connectBzbsPost(url, params)
  },
  apiPostWalletDetailExport(params) {
    const appid = Cache.getCache('app').id
    params.filter.appid = appid
    var url =
      ConfigManager.getUsermgrAPI() +
      '/api/v4.0/exportdata/queue/addqueueuserwalletcarddetails'
    return BzbsApi.connectBzbsPost(url, params)
  },

  ///Adjustpoint
  apiPostAdjustPoint(params) {
    var url = ConfigManager.getUsermgrAPI() + '/api/v5.0/user/adjustpoints'
    return BzbsApi.connectBzbsPost(url, params)
  },
  apiGetAdjustPoint: function (start_date, end_date, status, page) {
    const appid = Cache.getCache('app').id
    var url =
      ConfigManager.getUsermgrAPI() +
      '/api/v5.0/user/adjustpoints?appid=' +
      appid +
      '&start_date=' +
      start_date +
      '&end_date=' +
      end_date +
      '&status=' +
      status +
      '&page_number=' +
      page +
      '&limit=20'
    return BzbsApi.connectBzbsGet(url, null).pipe(map(res => res.data))
  },
  apiPostApprovePoint(params) {
    var url =
      ConfigManager.getUsermgrAPI() + '/api/v5.0/user/adjustpoints/approved'
    return BzbsApi.connectBzbsPost(url, params)
  },
  apiPostRejectPoint(params) {
    var url =
      ConfigManager.getUsermgrAPI() + '/api/v5.0/user/adjustpoints/reject'
    return BzbsApi.connectBzbsPost(url, params)
  },

  /// Help Chat
  apiGetHelpchatList: function (
    user_id,
    help_id,
    name,
    start_date,
    end_date,
    status,
    page,
  ) {
    if (status) {
      const appid = Cache.getCache('app').id
      var url =
        ConfigManager.getUsermgrAPI() +
        '/api/v5.0/helpchat?appid=' +
        appid +
        '&user_id=' +
        user_id +
        '&help_id=' +
        help_id +
        '&name=' +
        name +
        '&start_date=' +
        start_date +
        '&end_date=' +
        end_date +
        '&status=' +
        status +
        '&page_number=' +
        page +
        '&limit=20'
      return BzbsApi.connectBzbsGet(url, null).pipe(map(res => res.data))
    } else {
      const appid = Cache.getCache('app').id
      var url2 =
        ConfigManager.getUsermgrAPI() +
        '/api/v5.0/helpchat?appid=' +
        appid +
        '&user_id=' +
        user_id +
        '&help_id=' +
        help_id +
        '&name=' +
        name +
        '&start_date=' +
        start_date +
        '&end_date=' +
        end_date +
        '&page_number=' +
        page +
        '&limit=20'
      return BzbsApi.connectBzbsGet(url2, null).pipe(map(res => res.data))
    }
  },
  apiGetHelpChatDetail: function (buzz_key) {
    const appid = Cache.getCache('app').id
    var url =
      ConfigManager.getUsermgrAPI() +
      '/api/v5.0/helpchat/reply?appid=' +
      appid +
      '&buzz_key=' +
      buzz_key +
      '&limit=100'
    return BzbsApi.connectBzbsGet(url, null).pipe(map(res => res.data))
  },
  arrayToformData(dataList) {
    let formData = new FormData()
    formData.append(`buzz_key`, dataList.buzz_key)
    formData.append(`reply`, dataList.reply)
    formData.append(`file`, dataList.file)
    formData.append(`appid`, dataList.appid)
    return formData
  },
  apiPostHelpCahtReply(dataList) {
    var url = ConfigManager.getUsermgrAPI() + '/api/v5.0/helpchat/reply'
    const params = this.arrayToformData(dataList)
    return BzbsApi.connectBzbsPostRawData(url, params)
  },
  apiPutMarkRead(params) {
    var url = ConfigManager.getUsermgrAPI() + '/api/v5.0/helpchat'
    return BzbsApi.connectBzbsPut(url, params)
  },

  ///Config Database
  apiGetConfigTablenamelist: function (page) {
    const appid = Cache.getCache('app').id
    var url =
      ConfigManager.getUsermgrAPI() +
      '/api/v5.0/internal/configurations/tablenamelist?appid=' +
      appid +
      '&page_number=' +
      page +
      '&limit=20'
    return BzbsApi.connectBzbsGet(url, null).pipe(map(res => res.data))
  },
  apiPostConfigTablenamelist(params) {
    var url =
      ConfigManager.getUsermgrAPI() +
      '/api/v5.0/internal/configurations/tablenamelist'
    return BzbsApi.connectBzbsPost(url, params)
  },

  ///Mission & Badge
  apiGetMissionlist: function (user_id, mapping_type, lastid) {
    const appid = Cache.getCache('app').id
    var url =
      ConfigManager.getUsermgrAPI() +
      '/api/v5.0/user/missions?appid=' +
      appid +
      '&user_id=' +
      user_id +
      '&mapping_type=' +
      mapping_type +
      '&lastid=' +
      lastid +
      '&limit=20'
    return BzbsApi.connectBzbsGet(url, null).pipe(map(res => res.data))
  },
  apiGetMissioninfo: function (partition_key, row_key, mapping_type) {
    const appid = Cache.getCache('app').id
    var url =
      ConfigManager.getUsermgrAPI() +
      '/api/v5.0/user/missions/info?appid=' +
      appid +
      '&partition_key=' +
      row_key +
      '&row_key=' +
      partition_key +
      '&mapping_type=' +
      mapping_type
    return BzbsApi.connectBzbsGet(url, null).pipe(map(res => res.data))
  },
  apiGetBadgelist: function (user_id, is_unlock, page) {
    const appid = Cache.getCache('app').id
    var url =
      ConfigManager.getUsermgrAPI() +
      '/api/v5.0/user/badges?appid=' +
      appid +
      '&user_id=' +
      user_id +
      '&is_unlock=' +
      is_unlock +
      '&page_number=' +
      page +
      '&limit=20'
    return BzbsApi.connectBzbsGet(url, null).pipe(map(res => res.data))
  },
  apiGetTracelogTypesMission: function () {
    var url = ConfigManager.getUsermgrAPI() + '/api/v5.0/mapping/tracelogtypes'
    return BzbsApi.connectBzbsGet(url, null).pipe(map(res => res.data))
  },

  ///Savelist(Phase7)
  apiGetSavelistBytagID: function (savelist_id, page) {
    const appid = Cache.getCache('app').id
    var url =
      ConfigManager.getUsermgrAPI() +
      '/api/v5.0/userlist/savelist?appid=' +
      appid +
      '&savelist_id=' +
      savelist_id +
      '&page_number=' +
      page +
      '&limit=50'
    return BzbsApi.connectBzbsGet(url, null).pipe(map(res => res.data))
  },
  apiGetSavelistByVersion: function (savelist_id, version_no, page) {
    const appid = Cache.getCache('app').id
    var url =
      ConfigManager.getUsermgrAPI() +
      '/api/v5.0/userlist/savelist?appid=' +
      appid +
      '&savelist_id=' +
      savelist_id +
      '&version_no=' +
      version_no +
      '&page_number=' +
      page +
      '&limit=50'
    return BzbsApi.connectBzbsGet(url, null).pipe(map(res => res.data))
  },
  apiPostCreateUserToSavelist(params) {
    var url = ConfigManager.getUsermgrAPI() + '/api/v5.0/savelist'
    return BzbsApi.connectBzbsPost(url, params)
  },
  apiPutAddtoSavelist(params) {
    var url = ConfigManager.getUsermgrAPI() + '/api/v5.0/savelist'
    return BzbsApi.connectBzbsPut(url, params)
  },
  apiPutRefreshsavelist(params) {
    var url = ConfigManager.getUsermgrAPI() + '/api/v5.0/savelist/refresh'
    return BzbsApi.connectBzbsPut(url, params)
  },
  // apiDeleteSavelist(params) {
  //   var url = ConfigManager.getUsermgrAPI() + '/api/v5.0/savelist'
  //   return BzbsApi.connectBzbsDelete(url, params)
  // },
  apiDeleteSavelist: function (id, savelist_version_id) {
    const appid = Cache.getCache('app').id
    var url =
      ConfigManager.getUsermgrAPI() +
      '/api/v5.0/savelist?id=' +
      id +
      '&appid=' +
      appid +
      '&savelist_version_id=' +
      savelist_version_id
    return BzbsApi.connectBzbsDelete(url, null).pipe(map(res => res.data))
  },
  apiPostQueuesExportLoadSavelist(params) {
    var url =
      ConfigManager.getUsermgrAPI() +
      '/api/v5.0/exportdata/queue/addqueueuserlistfromsavelist'
    return BzbsApi.connectBzbsPost(url, params)
  },

  ///Usertags(Phase7.1)
  apiPostUserTags(params) {
    var url = ConfigManager.getUsermgrAPI() + '/api/v5.0/user/tags'
    return BzbsApi.connectBzbsPost(url, params)
  },
  apiGetTagGroup: function (active, page_number) {
    if (typeof active == 'boolean') {
      const appid = Cache.getCache('app').id
      var url =
        ConfigManager.getUsermgrAPI() +
        '/api/v5.0/taggroups?appid=' +
        appid +
        '&active=' +
        active +
        '&page_number=' +
        page_number +
        '&limit=20'
      return BzbsApi.connectBzbsGet(url, null).pipe(map(res => res.data))
    } else {
      const appid = Cache.getCache('app').id
      var url2 =
        ConfigManager.getUsermgrAPI() +
        '/api/v5.0/taggroups?appid=' +
        appid +
        '&page_number=' +
        page_number +
        '&limit=20'
      return BzbsApi.connectBzbsGet(url2, null).pipe(map(res => res.data))
    }
  },
  apiGetTagTypes: function (active) {
    if (active == true || active == false) {
      const appid = Cache.getCache('app').id
      var url =
        ConfigManager.getUsermgrAPI() +
        '/api/v5.0/taggroups/tagtypes?appid=' +
        appid +
        '&active=' +
        active +
        '&limit=20'
      return BzbsApi.connectBzbsGet(url, null).pipe(map(res => res.data))
    } else {
      const appid = Cache.getCache('app').id
      var url2 =
        ConfigManager.getUsermgrAPI() +
        '/api/v5.0/taggroups/tagtypes?appid=' +
        appid +
        '&limit=20'
      return BzbsApi.connectBzbsGet(url2, null).pipe(map(res => res.data))
    }
  },
  apiPostTagGroup(params) {
    var url = ConfigManager.getUsermgrAPI() + '/api/v5.0/taggroups/upsert'
    return BzbsApi.connectBzbsPost(url, params)
  },
  apiPostTagTypes(params) {
    var url = ConfigManager.getUsermgrAPI() + '/api/v5.0/tagtypes/upsert'
    return BzbsApi.connectBzbsPost(url, params)
  },
  apiGetTags: function (user_id) {
    const appid = Cache.getCache('app').id
    var url =
      ConfigManager.getUsermgrAPI() +
      '/api/v5.0/user/tags/groups/taggroups?appid=' +
      appid +
      '&user_id=' +
      user_id
    return BzbsApi.connectBzbsGet(url, null).pipe(map(res => res.data))
  },
  apiPostTags(params) {
    var url = ConfigManager.getUsermgrAPI() + '/api/v5.0/user/tags/assigns'
    return BzbsApi.connectBzbsPost(url, params)
  },
  apiPostTagsRemove(params) {
    var url =
      ConfigManager.getUsermgrAPI() + '/api/v5.0/user/tags/assigns/remove'
    return BzbsApi.connectBzbsPost(url, params)
  },

  ///ImportPoint(Phase7.1)
  apiGetImportsPoints: function (
    queue_status,
    start_date,
    end_date,
    page_number,
  ) {
    const appid = Cache.getCache('app').id
    var url =
      ConfigManager.getUsermgrAPI() +
      '/api/v5.0/imports/adjustpoints?appid=' +
      appid +
      '&status=' +
      queue_status +
      '&start_date=' +
      start_date +
      '&end_date=' +
      end_date +
      '&page_number=' +
      page_number +
      '&limit=20'
    return BzbsApi.connectBzbsGet(url, null).pipe(map(res => res.data))
  },
  apiGetImportsTemplates: function () {
    const appid = Cache.getCache('app').id
    var url =
      ConfigManager.getUsermgrAPI() +
      '/api/v5.0/imports/templates/adjustpoints?appid=' +
      appid
    return BzbsApi.connectBzbsGet(url, null).pipe(map(res => res.data))
  },
  DataarrayToformDataImport(dataList) {
    let formData = new FormData()
    formData.append(`appid`, dataList.appid)
    formData.append(`file`, dataList.file)
    return formData
  },
  apiPostImportsAdjustPoints(dataList) {
    var url = ConfigManager.getUsermgrAPI() + '/api/v5.0/imports/adjustpoints'
    const params = this.DataarrayToformDataImport(dataList)
    return BzbsApi.connectBzbsPostRawData(url, params)
  },
  apiGetMSMenu() {
    var url = ConfigManager.getApiadB2CUrl() + '/api/v2.0/user/menus'
    return BzbsApi.connectBzbsGet(url, null)
  },
  apiPostApprovedImportsAdjustpoints(params) {
    var url =
      ConfigManager.getUsermgrAPI() + '/api/v5.0/imports/adjustpoints/approved'
    return BzbsApi.connectBzbsPost(url, params)
  },
  apiPostRejectImportsAdjustpoints(params) {
    var url =
      ConfigManager.getUsermgrAPI() + '/api/v5.0/imports/adjustpoints/reject'
    return BzbsApi.connectBzbsPost(url, params)
  },

  //Announcement
  apiGetListAnnouncement(params) {
    var url =
      ConfigManager.getapiAnnouncement() +
      '/api/v1.0/announcement/get_list_announcement'
    return BzbsApi.connectBzbsGet(url, params).pipe(map(res => res.data))
  },
  DataarrayToformDataAnnouncement(paramsannoun) {
    let formData = new FormData()
    formData.append(`id`, paramsannoun.id)
    formData.append(`is_accept`, paramsannoun.is_accept)
    return formData
  },
  apiReadAnnouncement(paramsannoun) {
    var url =
      ConfigManager.getapiAnnouncement() +
      '/api/v1.0/announcement/add_read_announcement'
    const params = this.DataarrayToformDataAnnouncement(paramsannoun)
    return BzbsApi.connectBzbsPostRawData(url, params)
  },
  apiGetLastAnnouncement(params) {
    var url =
      ConfigManager.getapiAnnouncement() +
      '/api/v1.0/announcement/get_last_announcement'
    return BzbsApi.connectBzbsGet(url, params).pipe(map(res => res.data))
  },
  apiGetTermAndCondition(params) {
    var url =
      ConfigManager.getapiAnnouncement() +
      '/api/v1.0/announcement/get_term_and_condition'
    return BzbsApi.connectBzbsGet(url, params).pipe(map(res => res.data))
  },
  apiGetSLA(params) {
    var url =
      ConfigManager.getapiAnnouncement() + '/api/v1.0/announcement/get_sla'
    return BzbsApi.connectBzbsGet(url, params).pipe(map(res => res.data))
  },

  ///SearchSerial
  apiPostSearchSerialCampaign(params) {
    var url = ConfigManager.getUsermgrAPI() + '/api/v5.0/campaign/serial/search'
    return BzbsApi.connectBzbsPost(url, params)
  },
  apiPostSearchSerialCoupon(params) {
    var url = ConfigManager.getUsermgrAPI() + '/api/v5.0/coupon/serial/search'
    return BzbsApi.connectBzbsPost(url, params)
  },
  apiPostSearchSerialMarkUsed(params) {
    var url =
      ConfigManager.getUsermgrAPI() + '/api/v5.0/campaign/serial/mark_used'
    return BzbsApi.connectBzbsPost(url, params)
  },

  ///FAQ
  apiPostGetFAQ(params) {
    var url =
      ConfigManager.getUsermgrAPI() + '/api/v5.0/callcenterfaqs/faqslist'
    return BzbsApi.connectBzbsPost(url, params)
  },
  DataarrayToformDataFaq(datafaq) {
    let formData = new FormData()
    formData.append(`appid`, datafaq.appid)
    formData.append(`faq`, datafaq.faq)
    // formData.append(`answerdetails`, datafaq.answerdetails)
    datafaq.answerdetails.forEach((item, index) => {
      formData.append(`answerdetails[${index}].answer`, item.answer)
      formData.append(`answerdetails[${index}].files`, item.files)
    })
    return formData
  },
  apiPostCreateFaq(datafaq) {
    var url =
      ConfigManager.getUsermgrAPI() + '/api/v5.0/callcenterfaqs/create_faq'
    const params = this.DataarrayToformDataFaq(datafaq)
    return BzbsApi.connectBzbsPostRawData(url, params)
  },
  DataarrayToformUpdateDataFaq(datafaq) {
    let formData = new FormData()
    formData.append(`id`, datafaq.id)
    formData.append(`appid`, datafaq.appid)
    formData.append(`faq`, datafaq.faq)
    // formData.append(`answerdetails`, datafaq.answerdetails)
    datafaq.answerdetails.forEach((item, index) => {
      formData.append(`answerdetails[${index}].answer`, item.answer)
      formData.append(`answerdetails[${index}].files`, item.files)
      formData.append(`answerdetails[${index}].urlpathimage`, item.urlpathimage)
    })
    return formData
  },
  apiPostUpdateFaq(datafaq) {
    var url =
      ConfigManager.getUsermgrAPI() + '/api/v5.0/callcenterfaqs/update_faq'
    const params = this.DataarrayToformUpdateDataFaq(datafaq)
    return BzbsApi.connectBzbsPostRawData(url, params)
  },
  apiPutDeleteFaq(params) {
    var url =
      ConfigManager.getUsermgrAPI() + '/api/v5.0/callcenterfaqs/delete_faq'
    return BzbsApi.connectBzbsPut(url, params)
  },
  apiGetImportsTemplatesFAQ: function () {
    const appid = Cache.getCache('app').id
    var url =
      ConfigManager.getUsermgrAPI() +
      '/api/v5.0/imports/templates/callcenterfaq?appid=' +
      appid
    return BzbsApi.connectBzbsGet(url, null).pipe(map(res => res.data))
  },
  DataarrayToformDataImportFAQ(dataList) {
    let formData = new FormData()
    formData.append(`appid`, dataList.appid)
    formData.append(`formfile`, dataList.file)
    return formData
  },
  apiPostImportsFAQ(dataList) {
    var url =
      ConfigManager.getUsermgrAPI() + '/api/v5.0/callcenterfaqs/import_faq'
    const params = this.DataarrayToformDataImportFAQ(dataList)
    return BzbsApi.connectBzbsPostRawData(url, params)
  },

  ///Channel
  apiGetChannel: function (status) {
    const appid = Cache.getCache('app').id
    if (status != null) {
      var url =
        ConfigManager.getUsermgrAPI() +
        '/api/v5.0/callcenter/get_master_channel?appid=' +
        appid +
        '&active=' +
        status
      return BzbsApi.connectBzbsGet(url, null).pipe(map(res => res.data))
    } else {
      var url2 =
        ConfigManager.getUsermgrAPI() +
        '/api/v5.0/callcenter/get_master_channel?appid=' +
        appid
      return BzbsApi.connectBzbsGet(url2, null).pipe(map(res => res.data))
    }
  },
  apiGetChannelTicket: function (appid, status) {
    var url =
      ConfigManager.getUsermgrAPI() +
      '/api/v5.0/callcenter/get_master_channel?appid=' +
      appid +
      '&active=' +
      status
    return BzbsApi.connectBzbsGet(url, null).pipe(map(res => res.data))
  },
  apiPostCreateChannel(dataChannel) {
    var url =
      ConfigManager.getUsermgrAPI() + '/api/v5.0/callcenter/upsertchannel'
    const params = this.Dataarray(this.removenull(dataChannel))
    return BzbsApi.connectBzbsPostRawData(url, params)
  },
  apiPostRemoveChannel(dataChannel) {
    var url =
      ConfigManager.getUsermgrAPI() + '/api/v5.0/callcenter/removechannel'
    const params = this.Dataarray(this.removenull(dataChannel))
    return BzbsApi.connectBzbsPostRawData(url, params)
  },

  ///Subject
  apiGetSubject: function () {
    const appid = Cache.getCache('app').id
    var url =
      ConfigManager.getUsermgrAPI() +
      '/api/v5.0/callcenter/get_subject?appid=' +
      appid
    return BzbsApi.connectBzbsGet(url, null).pipe(map(res => res.data))
  },
  apiPostCreateSubject(dataSubject) {
    var url = ConfigManager.getUsermgrAPI() + '/api/v5.0/callcenter/upserttype'
    const params = this.Dataarray(dataSubject)
    return BzbsApi.connectBzbsPostRawData(url, params)
  },
  apiPostUpsertType(dataSubject) {
    var url = ConfigManager.getUsermgrAPI() + '/api/v5.0/callcenter/upserttype'
    const params = this.Dataarray(dataSubject)
    return BzbsApi.connectBzbsPostRawData(url, params)
  },

  ///Joblist
  apiGetJoblist: function (
    appid,
    app_name,
    task_id,
    priority_id,
    start_date,
    end_date,
    status_id,
    page_number,
  ) {
    var url =
      ConfigManager.getUsermgrAPI() +
      '/api/v5.0/callcenter/search_ticket?appid=' +
      appid +
      '&app_name=' +
      app_name +
      '&task_id=' +
      task_id +
      '&priority_id=' +
      priority_id +
      '&start_date=' +
      start_date +
      '&end_date=' +
      end_date +
      '&status_id=' +
      status_id +
      '&page_number=' +
      page_number +
      '&page_size=50'
    return BzbsApi.connectBzbsGet(url, null).pipe(map(res => res.data))
  },
  apiPostJoblistExport(params) {
    var url =
      ConfigManager.getUsermgrAPI() +
      '/api/v5.0/exportdata/queue/export_joblist'
    // return BzbsApi.connectBzbsPostDataSci(url, token, params);
    return BzbsApi.connectBzbsPost(url, params)
  },
  apiPutClearListExportCallcenter(params) {
    var url =
      ConfigManager.getUsermgrAPI() +
      '/api/v5.0/exportdata/queue/deletelist/deletelistcallcenter'
    return BzbsApi.connectBzbsPut(url, params)
  },
  // apiGetJoblistDetail: function (appid, id) {
  //   var url =
  //     ConfigManager.getUsermgrAPI() +
  //     '/api/v5.0/callcenter/get_ticket_detail?appid=' +
  //     appid +
  //     '&id=' +
  //     id
  //   return BzbsApi.connectBzbsGet(url, null).pipe(map(res => res.data))
  // },

  ///OTP
  // apiGetOTP: function (appid, contact_number, page_number) {
  //   var url =
  //     ConfigManager.getUsermgrAPI() +
  //     '/api/v5.0/user/user/get_otp?appid=' +
  //     appid +
  //     '&contact_number=' +
  //     contact_number +
  //     '&page_number=' +
  //     page_number +
  //     '&limit=20'
  //   return BzbsApi.connectBzbsGet(url, null).pipe(map(res => res.data))
  // },

  ///OTP
  apiGetOTP: function (appid, contact_number, page_number) {
    var url =
      ConfigManager.getUsermgrAPI() +
      '/api/v5.0/user/user/get_otpbyapp?appid=' +
      appid +
      '&contact_number=' +
      contact_number +
      '&page_number=' +
      page_number +
      '&limit=20'
    return BzbsApi.connectBzbsGet(url, null).pipe(map(res => res.data))
  },
  apiLogGetOTP: function (appid, contact_number, page_number) {
    var url =
      ConfigManager.getUsermgrAPI() +
      '/api/v5.0/user/user/get_otp?appid=' +
      appid +
      '&contact_number=' +
      contact_number +
      '&page_number=' +
      page_number +
      '&limit=20'
    return BzbsApi.connectBzbsGet(url, null).pipe(map(res => res.data))
  },

  ///AdjustWallet
  apiGetAdjustWallet: function (
    appid,
    status,
    start_date,
    end_date,
    page_number,
  ) {
    var url =
      ConfigManager.getUsermgrAPI() +
      '/api/v5.0/user/adjustwallets?appid=' +
      appid +
      '&status=' +
      status +
      '&start_date=' +
      start_date +
      '&end_date=' +
      end_date +
      '&page_number=' +
      page_number +
      '&limit=20'
    return BzbsApi.connectBzbsGet(url, null).pipe(map(res => res.data))
  },
  DataarrayToformAdjustWallet(dataadjustwallet) {
    let formData = new FormData()
    formData.append(`AppId`, dataadjustwallet.AppId)
    formData.append(`user_id`, dataadjustwallet.user_id)
    formData.append(`Type`, dataadjustwallet.Type)
    formData.append(`Amount`, dataadjustwallet.Amount)
    formData.append(`PaymentDetail.payment_id`, dataadjustwallet.payment_id)
    formData.append(`PaymentDetail.card_id`, dataadjustwallet.card_id)
    formData.append(`Detail`, dataadjustwallet.Detail)
    dataadjustwallet.formfiles.forEach(item => {
      formData.append(`formfiles`, item)
    })
    return formData
  },
  apiPostAdjustWallets(dataadjustwallet) {
    var url = ConfigManager.getUsermgrAPI() + '/api/v5.0/user/adjustwallets'
    const params = this.DataarrayToformAdjustWallet(dataadjustwallet)
    return BzbsApi.connectBzbsPostRawData(url, params)
  },
  DataarrayToformAdjustTrueWallet(dataadjusttruewallet) {
    let formData = new FormData()
    formData.append(`AppId`, dataadjusttruewallet.AppId)
    formData.append(`user_id`, dataadjusttruewallet.user_id)
    formData.append(`Type`, dataadjusttruewallet.Type)
    formData.append(`Amount`, dataadjusttruewallet.Amount)
    formData.append(
      `TrueWalletDetail.transaction_id`,
      dataadjusttruewallet.transaction_id,
    )
    formData.append(
      `TrueWalletDetail.campaign_id`,
      dataadjusttruewallet.campaign_id,
    )
    formData.append(
      `TrueWalletDetail.customer_id`,
      dataadjusttruewallet.customer_id,
    )
    formData.append(`Detail`, dataadjusttruewallet.Detail)
    dataadjusttruewallet.formfiles.forEach(item => {
      formData.append(`formfiles`, item)
    })
    return formData
  },
  apiPostAdjustTrueWallets(dataadjusttruewallet) {
    var url = ConfigManager.getUsermgrAPI() + '/api/v5.0/user/adjustwallets'
    const params = this.DataarrayToformAdjustTrueWallet(dataadjusttruewallet)
    return BzbsApi.connectBzbsPostRawData(url, params)
  },
  apiPostApproveWallet(params) {
    var url =
      ConfigManager.getUsermgrAPI() + '/api/v5.0/user/adjustWallets/approved'
    return BzbsApi.connectBzbsPost(url, params)
  },
  apiPostRejectWallet(params) {
    var url =
      ConfigManager.getUsermgrAPI() + '/api/v5.0/user/adjustWallets/reject'
    return BzbsApi.connectBzbsPost(url, params)
  },

  ///Userlist Tag
  apiPostRemoveTaggroup(params) {
    var url =
      ConfigManager.getUsermgrAPI() +
      '/api/v5.0/user/tags/assigns/remove/taggroup'
    return BzbsApi.connectBzbsPost(url, params)
  },

  ///CreateTicket
  apiGetSubjectForCreateTicket: function (appid, status) {
    if (status != null) {
      var url =
        ConfigManager.getUsermgrAPI() +
        '/api/v5.0/callcenter/get_master_problemType?appid=' +
        appid +
        '&status=' +
        status

      return BzbsApi.connectBzbsGet(url, null).pipe(map(res => res.data))
    } else {
      var url2 =
        ConfigManager.getUsermgrAPI() +
        '/api/v5.0/callcenter/get_master_problemType?appid=' +
        appid

      return BzbsApi.connectBzbsGet(url2, null).pipe(map(res => res.data))
    }
  },
  DataarrayToformCreateTicket(dataticket) {
    let formData = new FormData()
    if (dataticket.files) {
      dataticket.files.forEach(item => {
        formData.append(`files`, item)
      })
    }
    Object.keys(dataticket).forEach(item => {
      if (item != 'files') {
        formData.append(item, dataticket[item])
      }
    })
    // let formData = new FormData()
    // formData.append(`AppId`, dataticket.AppId)
    // formData.append(`user_id`, dataticket.user_id)
    // formData.append(`subject`, dataticket.subject)
    // formData.append(`topic_type`, dataticket.topic_type)
    // formData.append(`topic_detail`, dataticket.topic_detail)
    // formData.append(`problem_type_id`, dataticket.problem_type_id)
    // formData.append(`remark`, dataticket.remark)
    // if (dataticket.expected_date) {
    //   formData.append(`expected_date`, dataticket.expected_date)
    // }
    // formData.append(`ticket_status`, dataticket.ticket_status)
    // if (dataticket.vsts_id) {
    //   formData.append(`vsts_id`, dataticket.vsts_id)
    // }
    // if (dataticket.vsts_name) {
    //   formData.append(`vsts_name`, dataticket.vsts_name)
    // }
    // formData.append(`priority`, dataticket.priority)
    // formData.append(`channel`, dataticket.channel)
    // formData.append(`campaign_id`, dataticket.campaign_id)
    // formData.append(`ticket_status `, 'Open')
    // dataticket.files.forEach((item, index) => {
    //   formData.append(`files[${index}]`, item)
    // })
    return formData
  },
  apiPostCreateTicket(dataticket) {
    var url =
      ConfigManager.getUsermgrAPI() + '/api/v5.0/callcenter/create_ticket'
    const params = this.DataarrayToformCreateTicket(this.removenull(dataticket))
    return BzbsApi.connectBzbsPostRawData(url, params)
  },
  apiGetVSTS: function (appid, pVstsID) {
    var url =
      ConfigManager.getUsermgrAPI() +
      '/api/v5.0/callcenter/get_master_getvsts?appid=' +
      appid +
      '&pVstsID=' +
      pVstsID
    return BzbsApi.connectBzbsGet(url, null).pipe(map(res => res.data))
  },

  ///Joblist
  DataarrayToformMasterJobList(datajoblist) {
    let formData = new FormData()
    datajoblist.appId_list.forEach((item, index) => {
      formData.append(`appId_list[${index}]`, item)
    })
    Object.keys(datajoblist).forEach(item => {
      if (item != 'appId_list') {
        formData.append(item, datajoblist[item])
      }
    })
    return formData
  },
  apiPostMasterJoblist(datajoblist) {
    var url =
      ConfigManager.getUsermgrAPI() + '/api/v5.0/callcenter/get_master_joblist'
    const params = this.DataarrayToformMasterJobList(
      this.removenull(datajoblist),
    )
    return BzbsApi.connectBzbsPostRawData(url, params)
  },
  apiGetJoblistDetail: function (appid, TicketId) {
    var url =
      ConfigManager.getUsermgrAPI() +
      '/api/v5.0/callcenter/get_master_getjobdetail?appid=' +
      appid +
      '&TicketId=' +
      TicketId
    return BzbsApi.connectBzbsGet(url, null).pipe(map(res => res.data))
  },
  apiGetChannelJoblist: function (appid) {
    var url =
      ConfigManager.getUsermgrAPI() +
      '/api/v5.0/callcenter/get_master_channel?appid=' +
      appid +
      '&active=' +
      true
    return BzbsApi.connectBzbsGet(url, null).pipe(map(res => res.data))
  },
  DataarrayToformUpdateJoblist(datajoblistdetail) {
    let formData = new FormData()
    Object.keys(datajoblistdetail).forEach(item => {
      if (item != 'files') {
        formData.append(item, datajoblistdetail[item])
      }
    })
    if (datajoblistdetail.files) {
      datajoblistdetail.files.forEach(item => {
        formData.append(`files`, item)
      })
    }
    return formData
  },
  apiPostUpdateJoblist(appid, datajoblistdetail) {
    var url =
      ConfigManager.getUsermgrAPI() +
      '/api/v5.0/callcenter/update_ticket?AppId=' +
      appid
    const params = this.DataarrayToformUpdateJoblist(
      this.removenull(datajoblistdetail),
    )
    return BzbsApi.connectBzbsPostRawData(url, params)
  },
  DataarrayToformMasterChannel(dataChannel) {
    let formData = new FormData()
    dataChannel.appId_list.forEach((item, index) => {
      formData.append(`appid[${index}]`, item)
    })
    Object.keys(dataChannel).forEach(item => {
      if (item != 'appId_list') {
        formData.append(item, dataChannel[item])
      }
    })
    return formData
  },
  apiPostMasterChannel(dataChannel) {
    var url =
      ConfigManager.getUsermgrAPI() +
      '/api/v5.0/callcenter/get_list_master_channel'
    const params = this.DataarrayToformMasterChannel(
      this.removenull(dataChannel),
    )
    return BzbsApi.connectBzbsPostRawData(url, params)
  },
  DataarrayToformMasterJoblist(dataChannel) {
    let formData = new FormData()
    dataChannel.appId_list.forEach((item, index) => {
      formData.append(`appid[${index}]`, item)
    })
    return formData
  },
  apiPostTicketMasterJoblist(dataChannel) {
    var url =
      ConfigManager.getUsermgrAPI() +
      '/api/v5.0/callcenter/get_list_master_problemType'
    const params = this.DataarrayToformMasterJoblist(dataChannel)
    return BzbsApi.connectBzbsPostRawData(url, params)
  },
  apiPostExportJoblist(params) {
    var url =
      ConfigManager.getUsermgrAPI() +
      '/api/v5.0/exportdata/queue/export_joblist'
    return BzbsApi.connectBzbsPost(url, params)
  },

  ///Receipt
  apiGetReceipts: function (
    appid,
    user_id,
    event_name,
    receipt_no,
    contact_number,
    campaign_id,
    start_date,
    end_date,
    status,
    page_number,
    row_key
  ) {
    if (user_id) {
      var url =
        ConfigManager.getUsermgrAPI() +
        '/api/v5.0/user/receipts?appid=' +
        appid +
        '&user_id=' +
        user_id +
        '&event_name=' +
        event_name +
        '&receipt_no=' +
        receipt_no +
        '&contact_number=' +
        contact_number +
        '&campaign_id=' +
        campaign_id +
        '&start_date=' +
        start_date +
        '&end_date=' +
        end_date +
        '&status=' +
        status +
        '&page_number=' +
        page_number + 
        '&limit=20'
      return BzbsApi.connectBzbsGet(url, null).pipe(map(res => res.data))
    } else {
      var url2 =
        ConfigManager.getUsermgrAPI() +
        '/api/v5.0/user/receipts?appid=' +
        appid +
        '&event_name=' +
        event_name +
        '&receipt_no=' +
        receipt_no +
        '&contact_number=' +
        contact_number +
        '&campaign_id=' +
        campaign_id +
        '&start_date=' +
        start_date +
        '&end_date=' +
        end_date +
        '&status=' +
        status +
        '&page_number=' +
        page_number +
        '&row_key=' +
        row_key +
        '&limit=20'
      return BzbsApi.connectBzbsGet(url2, null).pipe(map(res => res.data))
    }
  },
  apiGetReceiptsByPage: function (
    appid,
    event_name,
    page_number,
  ) {
      var url2 =
        ConfigManager.getUsermgrAPI() +
        '/api/v5.0/user/receipts?appid=' +
        appid +
        '&event_name=' +
        event_name +
        page_number +
        '&limit=20'
      return BzbsApi.connectBzbsGet(url2, null).pipe(map(res => res.data))
  },
  apiGetReceiptsDetail: function (appid,event_name,ocr_key) {
    var url =
      ConfigManager.getUsermgrAPI() +
      '/api/v5.0/user/receipts/detail?appid=' +
      appid +
    '&event_name=' +
        event_name +
      '&ocr_key=' +
      ocr_key  
    return BzbsApi.connectBzbsGet(url, null).pipe(map(res => res.data))
  },
  apiGetReceiptsApproveDetail: function (appid, partition_key, row_key) {
    var url =
      ConfigManager.getUsermgrAPI() +
      '/api/v5.0/user/receipts/approve_detail?appid=' +
      appid +
      '&partition_key=' +
      partition_key +
      '&row_key=' +
      row_key
    return BzbsApi.connectBzbsGet(url, null).pipe(map(res => res.data))
  },
  apiGetListEventName: function (appid) {
    var url =
      ConfigManager.getUsermgrAPI() +
      '/api/v5.0/user/receipts/list_eventname?appid=' +
      appid
    return BzbsApi.connectBzbsGet(url, null).pipe(map(res => res.data))
  },
  apiPostApproveReceipt(params) {
    var url = ConfigManager.getUsermgrAPI() + '/api/v5.0/user/receipts/approve'
    return BzbsApi.connectBzbsPost(url, params)
  },
  apiPostRejectReceipt(params) {
    var url = ConfigManager.getUsermgrAPI() + '/api/v5.0/user/receipts/reject'
    return BzbsApi.connectBzbsPost(url, params)
  },
  apiPostResubmitReceipt(params) {
    var url = ConfigManager.getUsermgrAPI() + '/api/v5.0/user/receipts/resubmit'
    return BzbsApi.connectBzbsPost(url, params)
  },
  apiPostReceiptUpdate(params) {
    var url = ConfigManager.getUsermgrAPI() + '/api/v5.0/user/receipts/update'
    return BzbsApi.connectBzbsPost(url, params)
  },



  apiGetTagTypeList: function (appid, tag_group_rowkey, page_number) {
    var url =
      ConfigManager.getUsermgrAPI() +
      '/api/v5.0/taggroups/detail?appid=' +
      appid +
      '&tag_group_rowkey=' +
      tag_group_rowkey +
      '&page_number=' +
      page_number +
      '&limit=20'
    return BzbsApi.connectBzbsGet(url, null).pipe(map(res => res.data))
  },

  ////Subscription
  apiGetSubscriptionList: function (dataChannel) {
    var url = ConfigManager.getUsermgrAPI() + '/api/v5.0/user/get_subscription'
    const params = this.Dataarray(this.removenull(dataChannel))
    return BzbsApi.connectBzbsPostRawData(url, params)
  },
  apiGetSubscriptionDetail: function (appid, tranid) {
    var url =
      ConfigManager.getUsermgrAPI() +
      '/api/v5.0/user/get_subscription_detail?AppId=' +
      appid +
      '&tranid=' +
      tranid
    return BzbsApi.connectBzbsGet(url, null).pipe(map(res => res.data))
  },
  apiGetSubscriptionDetailItem: function (appid, tran_id, page) {
    var url =
      ConfigManager.getUsermgrAPI() +
      '/api/v1.0/user/purchasedetails?appid=' +
      appid +
      '&tran_id=' +
      tran_id +
      '&page_number=' +
      page +
      '&limit=20'
    return BzbsApi.connectBzbsGet(url, null).pipe(map(res => res.data))
  },

  ///Import Users
  apiGetImportUserList: function (queue_status, start_date, end_date, page) {
    const appid = Cache.getCache('app').id
    var url =
      ConfigManager.getUsermgrAPI() +
      '/api/v5.0/imports/users?appid=' +
      appid +
      '&queue_status=' +
      queue_status +
      '&start_date=' +
      start_date +
      '&end_date=' +
      end_date +
      '&page_number=' +
      page +
      '&limit=20'
    return BzbsApi.connectBzbsGet(url, null).pipe(map(res => res.data))
  },
  apiPostImportsUsers(dataList) {
    var url = ConfigManager.getUsermgrAPI() + '/api/v5.0/imports/users'
    const params = this.Dataarray(this.removenull(dataList))
    return BzbsApi.connectBzbsPostRawData(url, params)
  },
  apiGetImportUserListDetail: function (queue_id, page) {
    const appid = Cache.getCache('app').id
    var url =
      ConfigManager.getUsermgrAPI() +
      '/api/v5.0/imports/users/detail?appid=' +
      appid +
      '&queue_id=' +
      queue_id +
      '&page_number=' +
      page +
      '&limit=20'
    return BzbsApi.connectBzbsGet(url, null).pipe(map(res => res.data))
  },

  ///Userlevel Flag
  apiGetUserFlagList: function (user_id, Status) {
    const appid = Cache.getCache('app').id
    var url =
      ConfigManager.getUsermgrAPI() +
      '/api/v5.0/user/userflag?appid=' +
      appid +
      '&user_id=' +
      user_id +
      '&Status=' +
      Status +
      '&page_number=-1'
    return BzbsApi.connectBzbsGet(url, null).pipe(map(res => res.data))
  },
  apiDeleteLevelFlag(params) {
    var url = ConfigManager.getUsermgrAPI() + '/api/v5.0/user/userflag'
    return BzbsApi.connectBzbsDeleteData(url, params)
  },
  apiPostAddUserFlag(params) {
    var url = ConfigManager.getUsermgrAPI() + '/api/v5.0/user/userflag'
    return BzbsApi.connectBzbsPost(url, params)
  },

  ///whitelist
  apiPostWhiteList(params) {
    var url = ConfigManager.getUsermgrAPI() + '/api/v4.0/user/whitelist'
    return BzbsApi.connectBzbsPost(url, params)
  },
  ///UserFlag
  apiGetUserFlag: function () {
    const appid = Cache.getCache('app').id
    var url =
      ConfigManager.getUsermgrAPI() + '/api/v5.0/userflags?AppId=' + appid
    return BzbsApi.connectBzbsGet(url, null).pipe(map(res => res.data))
  },
  apiDeleteFlag(params) {
    var url = ConfigManager.getUsermgrAPI() + '/api/v5.0/userflags'
    return BzbsApi.connectBzbsDeleteData(url, params)
  },
  apiPutFlag(params) {
    var url = ConfigManager.getUsermgrAPI() + '/api/v5.0/userflags'
    return BzbsApi.connectBzbsPut(url, params)
  },
  apiPostFlag(params) {
    var url = ConfigManager.getUsermgrAPI() + '/api/v5.0/userflags'
    return BzbsApi.connectBzbsPost(url, params)
  },




  ///phase 11 
    apiGetPointDetail: function (params) {
    var url =
      ConfigManager.getUsermgrAPI() + '/api/v5.0/points/detail'
    return BzbsApi.connectBzbsPost(url, params)
  },
    
  //Callcenter
  apiPostSearchUser(params) {
    var url = ConfigManager.getUsermgrAPI() + '/api/v5.0/callcenter/users'
    return BzbsApi.connectBzbsPost(url, params)
  },

  apiGetAdjustPointCallcenter: function (appid, start_date, end_date, status, page) {
    var url =
      ConfigManager.getUsermgrAPI() +
      '/api/v5.0/user/adjustpoints?appid=' +
      appid +
      '&start_date=' +
      start_date +
      '&end_date=' +
      end_date +
      '&status=' +
      status +
      '&page_number=' +
      page +
      '&limit=20'
    return BzbsApi.connectBzbsGet(url, null).pipe(map(res => res.data))
  },
  apiGetImportsPointsCallcenter: function (
    appid,
    queue_status,
    start_date,
    end_date,
    page_number,
  ) {
    var url =
      ConfigManager.getUsermgrAPI() +
      '/api/v5.0/imports/adjustpoints?appid=' +
      appid +
      '&status=' +
      queue_status +
      '&start_date=' +
      start_date +
      '&end_date=' +
      end_date +
      '&page_number=' +
      page_number +
      '&limit=20'
    return BzbsApi.connectBzbsGet(url, null).pipe(map(res => res.data))
  },
   apiPostTranferPoint: function (params) {
    var url =
      ConfigManager.getUsermgrAPI() + '/api/v5.0/points/transfer'
    return BzbsApi.connectBzbsPost(url, params)
  },
   apiGetTranferPoints: function (
    status,
    start_date,
    end_date,
    page_number,
   ) {
     const appid = Cache.getCache('app').id
    var url =
      ConfigManager.getUsermgrAPI() +
      '/api/v5.0/points/transfers?appid=' +
      appid +
      '&status=' +
      status +
      '&start_date=' +
      start_date +
      '&end_date=' +
      end_date +
      '&page_number=' +
      page_number +
      '&limit=20'
    return BzbsApi.connectBzbsGet(url, null).pipe(map(res => res.data))
  },
   apiGetTranferPointByRefID: function (reference_id) {
     const appid = Cache.getCache('app').id
    var url =
      ConfigManager.getUsermgrAPI() +
      '/api/v5.0/points/transfer?appid=' +
      appid +
      '&reference_id=' +
      reference_id
    return BzbsApi.connectBzbsGet(url, null).pipe(map(res => res.data))
  },
  apiGetTranferPointsCallcenter: function (
    appid,
    status,
    start_date,
    end_date,
    page_number,
   ) {
    var url =
      ConfigManager.getUsermgrAPI() +
      '/api/v5.0/callcenter/user_transfer_points?appid=' +
      appid +
      '&status=' +
      status +
      '&start_date=' +
      start_date +
      '&end_date=' +
      end_date +
      '&page_number=' +
      page_number +
      '&limit=20'
    return BzbsApi.connectBzbsGet(url, null).pipe(map(res => res.data))
  },
   apiGetTranferPointByRefIDCallcenter: function (appid,reference_id) {
    var url =
      ConfigManager.getUsermgrAPI() +
      '/api/v5.0/points/transfer?appid=' +
      appid +
      '&reference_id=' +
      reference_id
    return BzbsApi.connectBzbsGet(url, null).pipe(map(res => res.data))
  },
  apiPostApproveTranferPoint(params) {
    var url = ConfigManager.getUsermgrAPI() + '/api/v5.0/points/approve_transfer'
    return BzbsApi.connectBzbsPost(url, params)
  },
  apiPostRejectTranferPoint(params) {
    var url = ConfigManager.getUsermgrAPI() + '/api/v5.0/points/reject_transfer'
    return BzbsApi.connectBzbsPost(url, params)
  },
}
