<template>
  <a-modal
    ref="myModal"
    wrap-class-name="modal-transfer"
    :visible="modalTransferPoint"
    :width="1200"
    :centered="true"
    :closable="false"
  >
    <template #title>
      <div class="d-flex">
        <div>Transfer Points</div>
      </div>
    </template>
    <div v-if="pagePoint == 1" class="bg-transfer">
      <div class="title">Account :</div>
      <div class="d-flex gap-3 mt-3">
        <div class="d-flex gap-2">
          <div class="fw-500">User ID :</div>
          <div class="text-grey-1">{{ userinfo.userid }}</div>
        </div>
        <div class="d-flex gap-2">
          <div class="fw-500">First Name :</div>
          <div class="text-grey-1">{{ userinfo.first_name }}</div>
        </div>
        <div class="d-flex gap-2">
          <div class="fw-500">Last Name :</div>
          <div class="text-grey-1">{{ userinfo.last_name }}</div>
        </div>
      </div>
      <div class="d-flex gap-3 mt-2 mb-3">
        <div class="d-flex gap-2">
          <div class="fw-500">Contact Number :</div>
          <div class="text-grey-1">
            {{
              userinfo.contact_number
                ? contactNumber(userinfo.contact_number)
                : '-'
            }}
          </div>
        </div>
        <div class="d-flex gap-2">
          <div class="fw-500">Email :</div>
          <div class="text-grey-1">{{ userinfo.email }}</div>
        </div>
        <div class="d-flex gap-2">
          <div class="fw-500">Points :</div>
          <div class="text-active">
            {{ dataPointsDetail?.points_balance?.toLocaleString() }} Points
          </div>
        </div>
      </div>
      <hr style="color: #e3e3e3; height: 3px" />
      <div class="title mt-3">Select your transfer account :</div>
      <a-input
        v-model:value="userid_tranfer"
        class="advanced-search mt-2"
        placeholder="Plase input User ID"
      >
        <template #prefix>
          <a>
            <search-outlined />
          </a>
        </template>
      </a-input>
      <div v-if="checkuser" class="lock">
        User ID not found, Please search User ID again.
      </div>
    </div>
    <div v-if="pagePoint == 2 || pagePoint == 3">
      <!-- <div ref="tablelist" class="text-webkit-center">
        <img class="pt-2" src="@/assets/icons/icon-tranfer-point.svg" />
        <div>Are you sure you want to confirm the points transfer?</div>
        <div class="point mt-3">{{ dataPointsDetail.points_balance }}</div>
        <div class="d-flex gap-3 mt-3 mb-3 justify-center">
          <div class="fw-500">Total Point Expire :</div>
          <div
            v-for="(item, index) of dataPointsDetail?.expiring_points"
            :key="index"
            class="d-flex gap-2"
          >
            <div class="text-grey-1">
              {{ item?.points }}
              Point
            </div>
            <div class="fraud">
              {{ formatDate(item?.date, 'DD/MMM/YYYY', -7) }}
            </div>
          </div>
        </div>
      </div> -->
      <a-timeline>
        <a-timeline-item :color="'gray'">
          <div class="bg-transfer">
            <div class="title">From Account :</div>
            <div class="d-flex gap-3 mt-3">
              <div class="d-flex gap-2">
                <div class="fw-500">User ID :</div>
                <div class="text-grey-1">{{ userinfo.userid }}</div>
              </div>
              <div class="d-flex gap-2">
                <div class="fw-500">First Name :</div>
                <div class="text-grey-1">
                  {{ userinfo.first_name }}
                </div>
              </div>
              <div class="d-flex gap-2">
                <div class="fw-500">Last Name :</div>
                <div class="text-grey-1">
                  {{ userinfo.last_name }}
                </div>
              </div>
            </div>
            <div v-if="dataPointsDetail" class="mt-3 mb-3">
              <div class="d-flex gap-3">
                <div class="">
                  <div class="d-flex gap-2">
                    <div class="fw-500">Points :</div>
                    <div class="text-active">
                      {{ dataPointsDetail?.points_balance?.toLocaleString() }}
                      Points
                    </div>
                  </div>
                </div>
                <div class="">
                  <div class="row">
                    <div class="col-5 fw-500">About to expire :</div>
                    <div class="col-7">
                      <div
                        v-for="(
                          item, index
                        ) of dataPointsDetail?.expiring_points"
                        :key="index"
                        class="w-100 d-flex justify-content-end"
                      >
                        <div class="expire-point">
                          <div class="text-grey-1">
                            {{ item?.points?.toLocaleString() }}
                            Points in
                          </div>
                          <div class="fraud">
                            {{ formatDate(item?.date, 'DD/MMM/YYYY', -7) }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </a-timeline-item>
        <a-timeline-item :color="'green'">
          <div class="bg-transfer">
            <div class="title">To Account :</div>
            <div class="d-flex gap-3 mt-3">
              <div class="d-flex gap-2">
                <div class="fw-500">User ID :</div>
                <div class="text-grey-1">{{ userdetail_tranfer.userid }}</div>
              </div>
              <div class="d-flex gap-2">
                <div class="fw-500">First Name :</div>
                <div class="text-grey-1">
                  {{ userdetail_tranfer.first_name }}
                </div>
              </div>
              <div class="d-flex gap-2">
                <div class="fw-500">Last Name :</div>
                <div class="text-grey-1">
                  {{ userdetail_tranfer.last_name }}
                </div>
              </div>
            </div>
            <div class="d-flex gap-3 mt-2 mb-3">
              <div class="d-flex gap-2">
                <div class="fw-500">Points :</div>
                <div class="text-active">
                  {{ dataPointsTFDetail?.points_balance?.toLocaleString() }}
                  Points
                </div>
              </div>
              <div class="d-flex gap-2">
                <div class="row">
                  <div class="col-5 fw-500">Points Expire :</div>
                  <div class="col-7">
                    <div
                      v-for="(
                        item, index
                      ) of dataPointsTFDetail?.expiring_points"
                      :key="index"
                      class="w-100 d-flex justify-content-end"
                    >
                      <div class="expire-point">
                        <div class="text-grey-1">
                          {{ item?.points?.toLocaleString() }}
                          Point in
                        </div>
                        <div class="fraud">
                          {{ formatDate(item?.date, 'DD/MMM/YYYY', -7) }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </a-timeline-item>
      </a-timeline>
      <div class="bg-transfer ml-5">
        <div class="d-flex justify-between">
          <div class="title">Points Transfer :</div>
          <div class="d-flex gap-2 title text-active">
            <div>{{ dataPointsDetail?.points_balance?.toLocaleString() }}</div>
            <div>Points</div>
          </div>
        </div>
        <div class="mt-2">
          <div><label class="fraud">*</label> Remark</div>
          <a-textarea
            v-model:value="remark"
            :maxlength="150"
            :auto-size="{ minRows: 5, maxRows: 5 }"
          />
        </div>
        <div>
          <a-checkbox class="my-2" v-model:checked="checknoti"
            >Send Notification
          </a-checkbox>
          <a-textarea
            v-if="checknoti"
            v-model:value="messagenoti"
            placeholder="Notification Message"
            :maxlength="150"
            :auto-size="{ minRows: 5, maxRows: 5 }"
          />
        </div>
      </div>
    </div>
    <template #footer>
      <div class="d-flex justify-between">
        <a-button @click="handleCancel()" type="default">Cancel</a-button>
        <a-button
          v-if="pagePoint == 1"
          @click="searchUser()"
          :disabled="!userid_tranfer"
          type="primary"
        >
          Next <arrow-right-outlined style="width: 12px"
        /></a-button>
        <a-button
          v-else
          type="primary"
          :disabled="!remark"
          @click="PostTranferPoint()"
        >
          Confirm</a-button
        >
      </div>
    </template>
  </a-modal>
</template>
<script>
import Util from '@/helper/Utility.js'
import BzbsUser from '@/core/UserManagement/callapi/BzbsUser'
import ConfigManager from '@/config/ConfigManager.js'
import CacheHelper from '@/helper/CacheHelper.js'
var Cache = new CacheHelper(ConfigManager.getCacheType())

export default {
  name: 'TranferPoint',
  components: {},
  data: function () {
    return {
      modalTransferPoint: false,
      pagePoint: 1,
      profile: [],
      userinfo:
        localStorage.getItem('userinfo') == 'undefined'
          ? null
          : JSON.parse(localStorage.getItem('userinfo')),
      dataPointsDetail: [],
      dataPointsTFDetail: [],
      userid_tranfer: '',
      userdetail_tranfer: {},
      checknoti: false,
      messagenoti: '',
      remark: '',
      checkuser: false,
    }
  },
  mounted() {},
  created: function () {},
  methods: {
    modalTranferAction(state, type, profile) {
      this[type] = state
      this.profile = profile
      this.checkuser = false
      if (state) {
        this.callAPiPointDetails()
      }
    },
    handleCancel() {
      this.pagePoint = 1
      this.dataPointsDetail = []
      this.dataPointsTFDetail = []
      this.userid_tranfer = ''
      this.userdetail_tranfer = {}
      this.checknoti = false
      this.messagenoti = ''
      this.remark = ''
      this.modalTransferPoint = false
    },
    nextPointPage(page) {
      this.pagePoint = page
    },
    contactNumber(number) {
      return Util.contactNumberFormat(number, '-')
    },
    searchUser() {
      this.$store.commit('setLoading', true)
      this.callApiPostuserDetail().then(() => {
        if (this.userdetail_tranfer) {
          this.callAPiPointTFDetails().then(() => {
            this.$store.commit('setLoading', false)
            this.pagePoint = 2
          })
        } else {
          this.$store.commit('setLoading', false)
          this.checkuser = true
        }
      })
    },
    callAPiPointDetails() {
      var data = {
        app_id: Cache.getCache('app').id,
        user_id: this.$route.params.id,
      }
      return new Promise((resolve, reject) => {
        BzbsUser.apiGetPointDetail(data)
          .then(res => {
            this.dataPointsDetail = res.data.result
            resolve(res.data.data)
          })
          .catch(error => {
            this.errorApi(error)
            reject(error)
          })
      })
    },
    callAPiPointTFDetails() {
      var data = {
        app_id: Cache.getCache('app').id,
        user_id: this.userid_tranfer,
      }
      return new Promise((resolve, reject) => {
        BzbsUser.apiGetPointDetail(data)
          .then(res => {
            this.dataPointsTFDetail = res.data.result
            resolve(res.data.data)
          })
          .catch(error => {
            this.errorApi(error)
            reject(error)
          })
      })
    },
    callApiPostuserDetail() {
      let params = {}
      params.userid = {
        criteria: 'And',
        value: this.userid_tranfer,
      }
      params.limit = 50
      params.page_number = 1
      return new Promise((resolve, reject) => {
        BzbsUser.apiPostUserList(params)
          .then(res => {
            this.userdetail_tranfer = res.data.result[0]
            resolve(res)
          })
          .catch(error => {
            this.$store.commit('setLoading', false)
            this.$error({
              content: error.response.data.error[0].message,
              centered: true,
              okType: 'default',
              autoFocusButton: null,
            })
            reject(error)
          })
      })
    },
    PostTranferPoint() {
      this.$store.commit('setLoading', true)
      var data = {
        app_id: Cache.getCache('app').id,
        transfer_from: this.$route.params.id,
        transfer_to: this.userdetail_tranfer.userid,
        points: this.dataPointsDetail.points_balance,
        remark: this.remark,
        send_notification: this.checknoti,
        notification_message: this.messagenoti,
      }
      return new Promise((resolve, reject) => {
        BzbsUser.apiPostTranferPoint(data)
          .then(res => {
            this.$store.commit('setLoading', false)
            this.modalTransferPoint = false
            this.$success({
              title: 'Success',
              okType: 'default',
              centered: true,
              onOk: () => {
                this.handleCancel()
              },
            })
            resolve(res.data.data)
          })
          .catch(error => {
            this.errorApi(error)
            reject(error)
          })
      })
    },
  },
}
</script>
<style lang="scss">
.modal-transfer {
  .ant-modal {
    color: #4a4a4a !important;
  }
  .title {
    font-size: 16px;
    font-weight: 700;
  }
  .text-grey-1 {
    color: #636363;
  }
  .ant-btn > .anticon {
    font-size: 16px !important;
  }
  .ant-modal-header {
    border-bottom: unset !important;
  }
  .bg-transfer {
    background-color: #ffffff;
    border-radius: 8px;
    padding: 10px;
  }
  .point {
    width: fit-content;
    font-size: 28px;
    padding: 0px 30px;
    color: #f9a601;
    font-weight: 500;
    background-color: #fff6e6;
  }
  .title-detail {
    margin-left: 8px;
    margin-top: 7px;
    display: flex;
    font-size: 13px;
    gap: 8px;
  }
  .expire-point {
    display: flex;
    min-width: max-content;
    gap: 8px;
  }
}
</style>
