<template>
  <div class="from">
    <!-- model :: {{ model }} -->
    <div v-if="Object.keys(model).length != 0">
      <div class="d-flex justify-between">
        <div class="pb-3 text-h">General information</div>
        <div class="d-flex gap-3">
          <div class="d-flex">
            <div class="pb-3 text-h">Username :</div>
            <div class="ml-2">{{ profile.username || '-' }}</div>
          </div>
          <div class="d-flex">
            <div class="pb-3 text-h">User Reference ID :</div>
            <div class="ml-2">{{ profile.ref_user_id || '-' }}</div>
          </div>
          <!-- <div class="d-flex">
          <div class="pb-3 text-h">LINE User ID : </div>
          <div>{{ profile.line_user_id }}</div>
        </div> -->
        </div>
      </div>
      <div class="mb-4">
        <div class="row">
          <div class="col-12 col-lg-4">
            <div class="h-col">First Name</div>
            <a-input
              v-model:value="model.first_name"
              :disabled="true"
              @change="disablebutton()"
            />
          </div>
          <div class="col-12 col-lg-4">
            <div class="h-col">Last Name</div>
            <a-input
              v-model:value="model.last_name"
              :disabled="true"
              @change="disablebutton()"
            />
          </div>
          <div class="col-4"></div>
        </div>
        <div class="row">
          <div class="col-12 col-lg-2">
            <div class="h-col">Birthday</div>
            <a-input
              v-model:value="model.birthday"
              :disabled="true"
              @change="disablebutton()"
            />
          </div>
          <div class="col-12 col-lg-3">
            <div class="h-col">Contact Number</div>
            <a-input
              v-model:value="model.contact_number"
              :disabled="true"
              @change="disablebutton()"
            />
          </div>
          <div class="col-12 col-lg-3">
            <div class="h-col">Email</div>
            <a-input
              v-model:value="model.email"
              :disabled="true"
              @change="disablebutton()"
            />
          </div>
          <div class="col-4"></div>
        </div>
        <div class="row">
          <div class="col-12 col-lg-8">
            <div class="h-col">Address</div>
            <a-textarea
              v-model:value="model.address"
              :disabled="true"
              @change="disablebutton()"
            />
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-lg-2">
            <div class="h-col">Zip Code</div>
            <a-input
              v-model:value="model.zipcode"
              :disabled="true"
              @change="disablebutton()"
            />
          </div>
          <div class="col-12 col-lg-3">
            <div class="h-col">Province</div>
            <a-select
              v-model:value="model.province"
              :options="itemsProvince"
              :disabled="true"
              @change="disablebutton()"
            />
          </div>
          <div class="col-12 col-lg-3">
            <div class="h-col">District</div>
            <a-select
              v-model:value="model.district"
              :options="itemsDistrict"
              :disabled="true"
              @change="disablebutton()"
            />
          </div>
          <div class="col-12 col-lg-3">
            <div class="h-col">Sub District</div>
            <a-select
              v-model:value="model.subdistrict"
              :options="itemsSubDistrict"
              :disabled="true"
              @change="disablebutton()"
            />
          </div>
        </div>
      </div>
      <hr />
      <div>
        <div class="mt-4 text-h">Delivery Address</div>
        <div
          v-if="model.delivery_address_infos.length == 0"
          class="text-center"
        >
          <img class="imgnodata" src="@/assets/icons/no-data.svg" />
          <div class="nodata">No Data</div>
        </div>
        <a-collapse
          v-for="(address, index) of model.delivery_address_infos"
          :key="`adressindex1-${index}`"
          class="px-0 mt-3"
          expandIconPosition="right"
        >
          <template #expandIcon="{ isActive }">
            <down-outlined :rotate="isActive ? 180 : 0" />
          </template>
          <a-collapse-panel
            :key="`adressindex1-${index}`"
            :header="
              'Address  ' +
              (index + 1) +
              (address.row_key == '001-shipping' ||
              address.row_key == '002-shipping' ||
              (address.is_active == false && address.is_default == false)
                ? ''
                : '  (' +
                  (address.is_default ? 'Default' : '') +
                  (address.is_default && address.is_active ? '|' : '') +
                  (address.is_active ? 'Active' : '') +
                  ')')
            "
          >
            <div class="px-4">
              <div
                v-show="
                  address.row_key != '001-shipping' &&
                  address.row_key != '002-shipping'
                "
                class="mt-4 flex"
              >
                <div>
                  <a-switch
                    size="small"
                    v-model:checked="address.is_active"
                    :disabled="true"
                    @change="disablebutton()"
                  />
                  Set Active
                </div>
                <div class="pl-3">
                  <a-switch
                    size="small"
                    v-model:checked="address.is_default"
                    :disabled="true"
                    @change="disablebutton()"
                  />
                  Set Default
                </div>
              </div>
              <div class="row">
                <div class="col-12 col-lg-4">
                  <div class="h-col">First Name</div>
                  <a-input
                    v-model:value="address.first_name"
                    :disabled="true"
                    @change="disablebutton()"
                  />
                </div>
                <div class="col-12 col-lg-4">
                  <div class="h-col">Last Name</div>
                  <a-input
                    v-model:value="address.last_name"
                    :disabled="true"
                    @change="disablebutton()"
                  />
                </div>
                <div class="col-4"></div>
              </div>
              <div class="row">
                <div class="col-12 col-lg-4">
                  <div class="h-col">Contact Number</div>
                  <a-input
                    v-model:value="address.contact_number"
                    :disabled="true"
                    @change="disablebutton()"
                  />
                </div>
                <div class="col-12 col-lg-4">
                  <div class="h-col">Email</div>
                  <a-input
                    v-model:value="address.email"
                    :disabled="true"
                    @change="disablebutton()"
                  />
                </div>
                <div class="col-4"></div>
              </div>
              <div class="row">
                <div class="col-12 col-lg-4">
                  <div class="h-col">Address Name</div>
                  <a-input
                    v-model:value="address.address_name"
                    :disabled="true"
                    @change="disablebutton()"
                  />
                </div>
                <div class="col-12 col-lg-4">
                  <div class="h-col">Address</div>
                  <a-textarea
                    v-model:value="address.address"
                    :disabled="true"
                    @change="disablebutton()"
                  />
                </div>
              </div>
              <div class="row">
                <div class="col-12 col-lg-2">
                  <div class="h-col">Zip Code</div>
                  <a-input
                    v-model:value="address.zipcode"
                    :disabled="true"
                    @change="disablebutton()"
                  />
                </div>
                <div class="col-12 col-lg-3">
                  <div class="h-col">Province</div>
                  <a-select
                    v-model:value="address.province_name"
                    :options="itemsProvinceDelivery[index]"
                    :disabled="true"
                    @change="disablebutton()"
                  />
                </div>
                <div class="col-12 col-lg-3">
                  <div class="h-col">District</div>
                  <a-select
                    v-model:value="address.district_name"
                    :options="itemsDistrictDelivery[index]"
                    :disabled="true"
                    @change="disablebutton()"
                  />
                </div>
                <div class="col-12 col-lg-3">
                  <div class="h-col">Sub District</div>
                  <a-select
                    v-model:value="address.sub_district_name"
                    :options="itemsSubDistrictDelivery[index]"
                    :disabled="true"
                    @change="disablebutton()"
                  />
                </div>
              </div>
              <div class="row">
                <div class="col-12 col-lg-8">
                  <div class="h-col">GPS Coordinates</div>
                  <v-input-text-inline-label
                    :inputValue="
                      setlatlong(address.latitude, address.longitude)
                    "
                    :inputLbl="{ name: 'GPS Coordinates', align: 'text-right' }"
                    :maxLength="100"
                    :errorInfo="errInfo[6]"
                    focusRing
                    :labelWidth="160"
                    bg="bg-input"
                    @input="disablebutton()"
                    @update:inputValue="updateaddresslatlng($event, index)"
                  ></v-input-text-inline-label>
                </div>
                <div class="col-3 mt-4" >
                  <a-button
                    :disabled="!address.latitude"
                    type="primary"
                    @click="showModal(index)"
                    ghost
                    >Choose From Map</a-button
                  >
                </div>
              </div>
            </div>
          </a-collapse-panel>
        </a-collapse>
        <div class="text-center my-4">
          <!-- <a-button class="btn-add" @click="addDeliveryAddress()"
            >+ Add Delivery Address</a-button
          > -->
        </div>
        <hr />
      </div>
      <div>
        <div class="my-4 text-h">Tax Address</div>
        <div
          v-if="model.tax_address_infos.length == 0"
          class="objType text-center"
        >
          <img class="imgnodata" src="@/assets/icons/no-data.svg" />
          <div class="nodata">No Data</div>
        </div>
        <a-collapse
          expandIconPosition="right"
          class="mt-3"
          v-for="(taxaddress, index) of model.tax_address_infos"
          :key="`taxindex1-${index}`"
        >
          <template #expandIcon="{ isActive }">
            <down-outlined :rotate="isActive ? 180 : 0" />
          </template>
          <a-collapse-panel
            key="`taxindex2-${index}`"
            class=""
            :header="
              'Tax address  ' +
              (index + 1) +
              (taxaddress.row_key == '001-shipping' ||
              taxaddress.row_key == '002-shipping' ||
              (taxaddress.is_active == false && taxaddress.is_default == false)
                ? ''
                : '  (' +
                  (taxaddress.is_default ? 'Default' : '') +
                  (taxaddress.is_default && taxaddress.is_active ? '|' : '') +
                  (taxaddress.is_active ? 'Active' : '') +
                  ')')
            "
          >
            <div class="px-4">
              <div
                v-show="
                  taxaddress.row_key != '001-shipping' ||
                  taxaddress.row_key != '002-shipping'
                "
                class="mb-4"
              >
                <div class="flex mt-4">
                  <div>
                    Set Active
                    <a-switch
                      class="ml-3"
                      size="small"
                      v-model:checked="taxaddress.is_active"
                      :disabled="true"
                      @change="disablebutton()"
                    />
                  </div>
                  <div class="ml-5">
                    Set Default<a-switch
                      class="ml-3"
                      size="small"
                      :disabled="true"
                      v-model:checked="taxaddress.is_default"
                      @change="disablebutton()"
                    />
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-3">
                  <a-radio-group
                    v-if="taxaddress.first_name && taxaddress.first_name != '-'"
                    @change="handleChange"
                    :disabled="true"
                    v-model:value="value"
                  >
                    <div class="d-flex">
                      <div>
                        <!-- <a-radio v-model:checked="Personal">Personal</a-radio> -->
                        <a-radio value="1">Personal</a-radio>
                      </div>
                      <div class="mr-5">
                        <!-- <a-radio v-model:checked="Corporate">Corporate</a-radio> -->
                        <a-radio value="2">Corporate</a-radio>
                      </div>
                    </div>
                  </a-radio-group>
                  <a-radio-group
                    v-else
                    @change="handleChange"
                    :disabled="true"
                    v-model:value="value2"
                  >
                    <div class="d-flex">
                      <div>
                        <!-- <a-radio v-model:checked="Personal">Personal</a-radio> -->
                        <a-radio value="1">Personal</a-radio>
                      </div>
                      <div class="mr-5">
                        <!-- <a-radio v-model:checked="Corporate">Corporate</a-radio> -->
                        <a-radio value="2">Corporate</a-radio>
                      </div>
                    </div>
                  </a-radio-group>
                </div>
              </div>

              <div v-if="taxaddress.first_name && taxaddress.first_name != '-'" class="row">
                <div class="col-12 col-lg-4">
                  <div class="h-col">First Name</div>
                  <a-input
                    v-model:value="taxaddress.first_name"
                    :disabled="true"
                    @change="disablebutton()"
                  />
                </div>
                <div class="col-12 col-lg-4">
                  <div class="h-col">Last Name</div>
                  <a-input
                    v-model:value="taxaddress.last_name"
                    :disabled="true"
                    @change="disablebutton()"
                  />
                </div>
                 <div class="col-12 col-lg-4"></div>
                <div class="col-12 col-lg-4">
                  <div class="h-col">Contact Number</div>
                  <a-input
                    v-model:value="taxaddress.contact_number"
                    :disabled="true"
                    @change="disablebutton()"
                  />
                </div>
                <div class="col-12 col-lg-4">
                  <div class="h-col">Tax Id</div>
                  <a-input
                    v-model:value="taxaddress.tax_id"
                    :disabled="true"
                    @change="disablebutton()"
                  />
                </div>
              </div>
              <div v-else class="row">
                <div class="col-12 col-lg-8">
                  <div class="h-col">Company Name</div>
                  <a-textarea
                    v-model:value="taxaddress.company_name"
                    :disabled="true"
                    @change="disablebutton()"
                  />
                </div>
                <div class="col-12 col-lg-4">
                  <div class="h-col">Tax ID</div>
                  <a-input
                    v-model:value="taxaddress.tax_id"
                    :disabled="true"
                    @change="disablebutton()"
                  />
                </div>
              </div>
              <div class="row">
                <div class="col-12 col-lg-8">
                  <div class="h-col">Address</div>
                  <a-textarea
                    v-model:value="taxaddress.address"
                    :disabled="true"
                    @change="disablebutton()"
                  />
                </div>
                <div class="col-12 col-lg-4">
                  <div class="h-col">Contact Number</div>
                  <a-input
                    v-model:value="taxaddress.contact_number"
                    :disabled="true"
                    @change="disablebutton()"
                  />
                </div>
              </div>
              <div class="row">
                <div class="col-12 col-lg-2">
                  <div class="h-col">Zip Code</div>
                  <a-input
                    v-model:value="taxaddress.zipcode"
                    :disabled="true"
                    @change="disablebutton()"
                  />
                </div>
                <div class="col-12 col-lg-3">
                  <div class="h-col">Province</div>
                  <a-select
                    v-model:value="taxaddress.province_name"
                    :options="itemsProvinceTax[index]"
                    :disabled="true"
                    @change="disablebutton()"
                  />
                </div>
                <div class="col-12 col-lg-3">
                  <div class="h-col">District</div>
                  <a-select
                    v-model:value="taxaddress.province_name"
                    :options="itemsProvinceTax[index]"
                    :disabled="true"
                    @change="disablebutton()"
                  />
                </div>
                <div class="col-12 col-lg-3">
                  <div class="h-col">Sub District</div>
                  <a-select
                    v-model:value="taxaddress.sub_district_name"
                    :options="itemsSubDistrictTax[index]"
                    :disabled="true"
                    @change="disablebutton()"
                  />
                </div>
              </div>
            </div>
          </a-collapse-panel>
        </a-collapse>

        <div class="text-center my-4">
          <!-- <a-button class="btn-add" @click="addTaxAddress()"
            >+ Add Tax Address</a-button
          > -->
        </div>
        <hr />
      </div>
      <div>
        <!-- <div class="text-center my-5">
          <a-button
            class="btn-search btn-update"
            type="primary"
            :disabled="disable"
            @click="UpdateAccount()"
            >Update</a-button
          >
        </div> -->
      </div>
    </div>
    <div v-else class="text-center">ไม่พบข้อมูล</div>
    <a-modal
      title="Select GPS Coordinates"
      :visible="visible"
      @cancel="handleCancel"
      width="1000px"
      okText="Save"
      :footer="null"
      :centered="true"
    >
      <GMapMap
        :center="center"
        :zoom="15"
        map-type-id="terrain"
        style="width: 100%; height: 400px"
      >
        <GMapCluster>
          <GMapMarker
            :key="index"
            v-for="(m, index) in markers"
            :position="m.position"
            :clickable="true"
            :draggable="true"
            @dragend="mark"
            @click="center = m.position"
          />
        </GMapCluster>
      </GMapMap>
    </a-modal>
  </div>
</template>

<script>
import VInputTextInlineLabel from '@/components/Input/label/InputTextInlineLabel.vue'
// import VInputTextArea from '@/components/Input/label/InputTextArea.vue'
// import VDropdownList from '@/components/Input/label/DropdownList.vue'
import BzbsUser from '@/core/UserManagement/callapi/BzbsUser'
import ConfigManager from '@/config/ConfigManager.js'
import CacheHelper from '@/helper/CacheHelper.js'
import { DownOutlined } from '@ant-design/icons-vue'
// import dayjs from "dayjs";
// import { GoogleMap, Marker } from 'vue3-google-map'

import _ from 'lodash'
var Cache = new CacheHelper(ConfigManager.getCacheType())

// helper
import { FormHelper } from '@/helper/FormHelper'
import moment from 'moment'

export default {
  name: 'UserProfileForm',
  props: {
    profile: {
      type: Object,
      default: null,
    },
    dataapp: {
      type: Object,
    },
  },
  components: {
    VInputTextInlineLabel,
    // VInputTextArea,
    // VDropdownList,
    DownOutlined,
    // GoogleMap,
    // Marker,
  },
  data: function () {
    return {
      // center: { lat: 13.75527787656555, lng: 100.5727475701194 },
      center: { lat: 13.75527787656555, lng: 100.5727475701194 },
      markers: [
        {
          position: {
            lat: 13.75527787656555,
            lng: 100.5727475701194,
          },
        }, // Along list of clusters
      ],
      // testdate:'Thu, 21 Jul 2022 04:13:59 GMT',
      disable: true,
      location: null,
      gettingLocation: false,
      errorStr: null,
      value: '1',
      value2: '2',
      SetActive: false,
      SetDefault: false,
      visible: false,
      showcompany: false,
      showpersonal: false,
      model: {},
      modalIndex: null,
      errInfo: FormHelper.genErrorInfo(10),
      itemsProvince: [{ text: 'Bangkok', value: 'Bangkok' }],
      itemsDistrict: [{ text: 'Huaykwang', value: 'Huaykwang' }],
      itemsSubDistrict: [{ text: 'Huaykwang', value: 'Huaykwang' }],
      itemsProvinceDelivery: [],
      itemsDistrictDelivery: [],
      itemsSubDistrictDelivery: [],
      itemsProvinceTax: [],
      itemsDistrictTax: [],
      itemsSubDistrictTax: [],
      formatdate: 'YYYY-MM-DD',
      dateFormat: 'YYYY-MM-DD',
      userinfo:
        localStorage.getItem('userinfo') == 'undefined'
          ? null
          : JSON.parse(localStorage.getItem('userinfo')),
    }
  },
  watch: {
    profile(newVal, oldVal) {
      if (newVal != oldVal) {
        this.setprofile(newVal)
      }
    },
  },
  mounted() {
    this.setprofile(this.profile)
  },
  created() {
    this.locateMe()
  },
  methods: {
    setlatlong(lat, long) {
      var la = lat || ' '
      var lg = long || ' '
      if (lat && long) {
        return la + ' , ' + lg
      }
      return la + '  ' + lg
    },
    oklatlng() {
      this.visible = false
      this.model.delivery_address_infos[this.modalIndex].latitude =
        this.markers[0].position.lat
      this.model.delivery_address_infos[this.modalIndex].longitude =
        this.markers[0].position.lng
      console.log(this.model.delivery_address_infos)
    },
    mark(event) {
      this.markers[0].position = {
        lat: event.latLng.lat(),
        lng: event.latLng.lng(),
      }
      console.log(this.markers[0])
      console.log(event.latLng.lng())
    },
    getdate(birthday) {
      return moment(birthday).format(this.formatdate)
    },
    updatefirstname(event) {
      this.model.first_name = event
    },
    updatelastname(event) {
      this.model.last_name = event
    },
    updatebirthday(event) {
      this.model.birthday = event
    },
    updatecontactnumber(event) {
      this.model.contact_number = event
    },
    updateemail(event) {
      this.model.email = event
    },
    updateaddress(event) {
      this.model.address = event
    },
    updateaddressfirstname(event, index) {
      this.model.delivery_address_infos[index].first_name = event
    },
    updateaddresslastname(event, index) {
      this.model.delivery_address_infos[index].last_name = event
    },
    updateaddresseamil(event, index) {
      this.model.delivery_address_infos[index].email = event
    },
    updateaddressaddress(event, index) {
      this.model.delivery_address_infos[index].first_name = event
    },
    updateaddresscontactnumber(event, index) {
      this.model.delivery_address_infos[index].contact_number = event
    },
    updateaddresslatlng(event, index) {
      this.model.delivery_address_infos[index].latitude = event
      this.model.delivery_address_infos[index].longitude = event
    },
    updatetaxtaxid(event, index) {
      this.model.tax_address_infos[index].tax_id = event
    },
    updatetaxfirstname(event, index) {
      this.model.tax_address_infos[index].first_name = event
    },
    updatetaxlastname(event, index) {
      this.model.tax_address_infos[index].last_name = event
    },
    updatetaxaddress(event, index) {
      this.model.tax_address_infos[index].address = event
    },
    updatetaxCompany(event, index) {
      this.model.tax_address_infos[index].Company = event
    },
    updateZipcode(event) {
      this.model.zipcode = event
    },
    updateDeliveryZipcode(event, index) {
      this.model.delivery_address_infos[index].zipcode = event
    },
    updateTaxZipcode(event, index) {
      this.model.tax_address_infos[index].zipcode = event
    },
    // onchangeDistrict() {
    //   this.itemsSubDistrict = []
    //   _.forEach(this.zipcodeData, item => {
    //     if (item.district_name_en == this.model.district)
    //       this.itemsSubDistrict.push({
    //         text: item.sub_district_name_en,
    //         value: item.sub_district_name_en,
    //       })
    //   })
    // },
    onChange() {
      if (this.model.zipcode.length == 5) {
        this.getZipCode(this.model.zipcode).then(res => {
          this.itemsSubDistrict = []
          this.itemsDistrict = []
          this.itemsProvince = []
          _.forEach(res.result, item => {
            this.itemsSubDistrict.push({
              text: item.sub_district_name,
              value: item.sub_district_code,
            })
            if (!this.itemsDistrict.find(x => x.value == item.district_code)) {
              this.itemsDistrict.push({
                text: item.district_name,
                value: item.district_code,
              })
            }
            if (!this.itemsProvince.find(x => x.value == item.province_code)) {
              this.itemsProvince.push({
                text: item.province_name,
                value: item.province_code,
              })
            }
          })
          this.model.province = this.itemsProvince[0].province_code
          this.model.district = this.itemsDistrict[0].district_code
          this.model.subdistrict = this.itemsSubDistrict[0].sub_district_code
        })
      } else {
        this.model.province = ''
        this.model.district = ''
        this.model.subdistrict = ''
        this.itemsProvince = []
        this.itemsDistrict = []
        this.itemsSubDistrict = []
      }
    },
    onChangeDeliveryZipcode(index) {
      if (this.model.delivery_address_infos[index].zipcode.length == 5) {
        this.getZipCode(this.model.delivery_address_infos[index].zipcode).then(
          res => {
            this.itemsSubDistrictDelivery[index] = []
            this.itemsDistrictDelivery[index] = []
            this.itemsProvinceDelivery[index] = []
            _.forEach(res.result, item => {
              this.itemsSubDistrictDelivery[index].push({
                text: item.sub_district_name,
                value: item.sub_district_code,
              })
              if (
                !this.itemsDistrictDelivery[index].find(
                  x => x.value == item.district_code,
                )
              ) {
                this.itemsDistrictDelivery[index].push({
                  text: item.district_name,
                  value: item.district_code,
                })
              }
              if (
                !this.itemsProvinceDelivery[index].find(
                  x => x.value == item.province_code,
                )
              ) {
                this.itemsProvinceDelivery[index].push({
                  text: item.province_name,
                  value: item.province_code,
                })
              }
            })
            this.model.province =
              this.itemsProvinceDelivery[index][0].province_code
            this.model.district =
              this.itemsDistrictDelivery[index][0].district_code
            this.model.subdistrict =
              this.itemsSubDistrictDelivery[index][0].sub_district_code
          },
        )
      }
    },
    onChangeTaxZipcode(index) {
      if (this.model.tax_address_infos[index].zipcode.length == 5) {
        this.getZipCode(this.model.tax_address_infos[index].zipcode).then(
          res => {
            this.itemsSubDistrictTax[index] = []
            this.itemsDistrictTax[index] = []
            this.itemsProvinceTax[index] = []
            _.forEach(res.result, item => {
              this.itemsSubDistrictTax[index].push({
                text: item.sub_district_name,
                value: item.sub_district_code,
              })
              if (
                !this.itemsDistrictTax[index].find(
                  x => x.value == item.district_code,
                )
              ) {
                this.itemsDistrictTax[index].push({
                  text: item.district_name,
                  value: item.district_code,
                })
              }
              if (
                !this.itemsProvinceTax[index].find(
                  x => x.value == item.province_name,
                )
              ) {
                this.itemsProvinceTax[index].push({
                  text: item.province_name,
                  value: item.province_code,
                })
              }
            })
            this.model.province = this.itemsProvinceTax[index][0].province_code
            this.model.district = this.itemsDistrictTax[index][0].district_code
            this.model.subdistrict =
              this.itemsSubDistrictTax[index][0].sub_district_code
          },
        )
      }
    },
    getZipCode(zip_code) {
      return new Promise((resolve, reject) => {
        BzbsUser.apiGetZipCode(zip_code).subscribe(
          res => {
            resolve(res)
          },
          err => {
            this.errorApi(err)
            reject(err)
          },
        )
      })
    },
    UpdateAccount() {
      this.model.delivery_address_infos.forEach((x, index) => {
        x.province_name = this.itemsProvinceDelivery[index]?.find(
          y =>
            y.value == this.model.delivery_address_infos[index].province_code,
        )?.text
        x.district_name = this.itemsDistrictDelivery[index]?.find(
          y =>
            y.value == this.model.delivery_address_infos[index].district_code,
        )?.text
        x.sub_district_name = this.itemsSubDistrictDelivery[index]?.find(
          y =>
            y.value ==
            this.model.delivery_address_infos[index].sub_district_code,
        )?.text
      })
      this.model.tax_address_infos.forEach((x, index) => {
        x.province_name = this.itemsProvinceTax[index]?.find(
          y => y.value == this.model.tax_address_infos[index].province_code,
        )?.text
        x.district_name = this.itemsDistrictTax[index]?.find(
          y => y.value == this.model.tax_address_infos[index].district_code,
        )?.text
        x.sub_district_name = this.itemsSubDistrictTax[index]?.find(
          y => y.value == this.model.tax_address_infos[index].sub_district_code,
        )?.text
      })
      var accountupdate = {
        appid: Cache.getCache('app').id,
        user_id: this.$route.params.id,
        user_info: {
          first_name: this.model.first_name,
          last_name: this.model.last_name,
          birthday: this.model.birthday,
          contact_number: this.model.contact_number,
          email: this.model.email,
          address: this.model.address,
          zipcode: this.model.zipcode,
          province_code: this.model.province,
          province_name: this.itemsProvince.find(
            x => x.value == this.model.province,
          )?.text,
          district_code: this.model.district,
          district_name: this.itemsDistrict.find(
            x => x.value == this.model.district,
          )?.text,
          sub_district_code: this.model.subdistrict,
          sub_district_name: this.itemsSubDistrict.find(
            x => x.value == this.model.subdistrict,
          )?.text,
        },
        delivery_address_infos: this.model.delivery_address_infos,
        tax_address_infos: this.model.tax_address_infos,
      }
      return new Promise((resolve, reject) => {
        BzbsUser.apiPutAccountInfoUpdate(accountupdate)
          .then(res => {
            if (
              res.data.errors &&
              res.data.errors == '401' &&
              res.data.message ==
                'A JWT specified in the request header is expired.'
            ) {
              this.errorResponse(res.data, true)
            }
            this.$success({
              title: 'Success',
              okType: 'default',
              centered: true,
            })
            resolve(res.data.data)
          })
          .catch(error => {
            this.errorApi(error)
            reject(error)
          })
      })
    },
    addDeliveryAddress() {
      this.model.delivery_address_infos.push({
        is_active: false,
        is_default: false,
        first_name: '',
        last_name: '',
        contact_number: '',
        email: '',
        address: '',
        zipcode: '',
        province_code: 0,
        province_name: '',
        district_code: 0,
        district_name: '',
        sub_district_code: 0,
        sub_district_name: '',
        latitude: 0,
        longitude: 0,
      })
    },
    addTaxAddress() {
      this.model.tax_address_infos.push({
        is_active: false,
        is_default: false,
        tax_id: '',
        first_name: '',
        last_name: '',
        contact_number: '',
        email: '',
        address: '',
        zipcode: '',
        province_code: 0,
        province_name: '',
        district_code: 0,
        district_name: '',
        sub_district_code: 0,
        sub_district_name: '',
      })
    },
    setprofile(obj) {
      this.model.first_name = obj.user_info?.first_name
        ? obj.user_info?.first_name
        : this.userinfo.first_name
      this.model.last_name = obj.user_info?.last_name
        ? obj.user_info?.last_name
        : this.userinfo.last_name
      this.model.birthday = obj.user_info?.birthday
        ? obj.user_info?.birthday
        : '-'
      this.model.contact_number = obj.user_info?.contact_number
        ? obj.user_info?.contact_number
        : '-'
      this.model.email = obj.user_info?.email ? obj.user_info?.email : '-'
      this.model.address = obj.user_info?.address ? obj.user_info?.address : '-'
      this.model.zipcode = obj.user_info?.zipcode ? obj.user_info?.zipcode : '-'
      this.model.province = obj.user_info?.province_name
        ? obj.user_info?.province_name
        : '-'
      this.model.district = obj.user_info?.district_name
        ? obj.user_info?.district_name
        : '-'
      this.model.subdistrict = obj.user_info?.sub_district_name
        ? obj.user_info?.sub_district_name
        : '-'
      this.itemsProvince = [
        {
          text: obj.user_info?.province_name,
          value: obj.user_info?.province_code,
        },
      ]
      this.itemsDistrict = [
        {
          text: obj.user_info?.district_name,
          value: obj.user_info?.district_code,
        },
      ]
      this.itemsSubDistrict = [
        {
          text: obj.user_info?.sub_district_name,
          value: obj.user_info?.sub_district_code,
        },
      ]
      _.forEach(obj.delivery_address_infos, (item, index) => {
        item.first_name = item.first_name ? item.first_name : '-'
        item.last_name = item.last_name ? item.last_name : '-'
        item.email = item.email? item.email : '-'
        item.contact_number = item.contact_number ? item.contact_number : '-'
        item.address = item.address? item.address : '-'
        item.address_name = item.address_name ? item.address_name : '-'
        item.zipcode = item.zipcode ? item.zipcode : '-'
        item.province_name = item.province_name ? item.province_name : '-'
        item.sub_district_name = item.sub_district_name ? item.sub_district_name : '-'
        item.district_name = item.district_name ? item.district_name : '-'
        item.address = item.address? item.address : '-'
        this.itemsProvinceDelivery[index] = [
          {
            text: item.province_name,
            value: item.province_code,
          },
        ]
        this.itemsDistrictDelivery[index] = [
          {
            text: item.district_name,
            value: item.district_code,
          },
        ]
        this.itemsSubDistrictDelivery[index] = [
          {
            text: item.sub_district_name,
            value: item.sub_district_code,
          },
        ]
      })
      _.forEach(obj.tax_address_infos, (item, index) => {
         item.first_name = item.first_name ? item.first_name : '-'
        item.last_name = item.last_name ? item.last_name : '-'
        item.contact_number = item.contact_number ? item.contact_number : '-'
        item.address = item.address? item.address : '-'
        item.zipcode = item.zipcode ? item.zipcode : '-'
        item.tax_id = item.tax_id ? item.tax_id : '-'
        item.province_name = item.province_name ? item.province_name : '-'
        item.sub_district_name = item.sub_district_name ? item.sub_district_name : '-'
        item.district_name = item.district_name ? item.district_name : '-'
        this.itemsProvinceTax[index] = [
          {
            text: item.province_name,
            value: item.province_code,
          },
        ]
        this.itemsDistrictTax[index] = [
          {
            text: item.district_name,
            value: item.district_code,
          },
        ]
        this.itemsSubDistrictTax[index] = [
          {
            text: item.sub_district_name,
            value: item.sub_district_code,
          },
        ]
      })
      this.model.delivery_address_infos = obj.delivery_address_infos
      this.model.tax_address_infos = obj.tax_address_infos
      // console.log(this.model)
    },
    handleChange(value) {
      console.log(value)
      this.showpersonal = this.value == '1'
      this.showcompany = this.value == '2'
    },
    showModal(index) {
      this.modalIndex = index

      if (
        this.model.delivery_address_infos[this.modalIndex].latitude ||
        this.model.delivery_address_infos[this.modalIndex].longitude
      ) {
        (this.center = {
          lat: this.model.delivery_address_infos[this.modalIndex].latitude,
          lng: this.model.delivery_address_infos[this.modalIndex].longitude,
        }),
          (this.markers = [
            {
              position: {
                lat: this.model.delivery_address_infos[this.modalIndex]
                  .latitude,
                lng: this.model.delivery_address_infos[this.modalIndex]
                  .longitude,
              },
            }, // Along list of clusters
          ])
      }
      if (
        !this.model.delivery_address_infos[this.modalIndex].latitude ||
        !this.model.delivery_address_infos[this.modalIndex].longitude
      ) {
        this.center = {
          lat: this.location.coords.latitude,
          lng: this.location.coords.longitude,
        }
        this.markers = [
          {
            position: {
              lat: this.location.coords.latitude,
              lng: this.location.coords.longitude,
            },
          }, // Along list of clusters
        ]
      }
      this.visible = true
    },
    async getLocation() {
      return new Promise((resolve, reject) => {
        if (!('geolocation' in navigator)) {
          reject(new Error('Geolocation is not available.'))
        }

        navigator.geolocation.getCurrentPosition(
          pos => {
            resolve(pos)
          },
          err => {
            reject(err)
          },
        )
      })
    },
    async locateMe() {
      this.setpermissionmaskdata()
      this.gettingLocation = true
      try {
        this.gettingLocation = false
        this.location = await this.getLocation()
      } catch (e) {
        this.gettingLocation = false
        this.errorStr = e.message
      }
    },
    handleCancel() {
      this.visible = false
    },
    disablebutton() {
      this.disable = false
    },
    onChangedate(date, dateString) {
      console.log(date, dateString)
    },
    formatBirthDate(date) {
      if (!this.permissionmaskdata) {
        if (date == '' || date == '-') {
          return '-'
        } else {
          return moment(date).format('DD / MMM / yyyy')
        }
      }
      if (this.permissionmaskdata) {
        if (date == '' || date == '-') {
          return '-'
        } else {
          return moment(date).format('DD / MMM / ****')
        }
      }
    },
    formatPhone(dirtyNumber) {
      if (!this.permissionmaskdata) {
        if (dirtyNumber == '-') return '-'
        return dirtyNumber
          .replace(/\D+/g, '')
          .replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3')
      }
      if (this.permissionmaskdata) {
        if (dirtyNumber == '*') return '*'
        return (
          dirtyNumber.replace(/\D+/g, '').replace(/(\d{3})(\d{3})/, '$1-$2') +
          '-****'
        )
      }
    },
    setpermissionmaskdata() {
      if (
        this.dataapp.features.find(
          x =>
            x.data_levels[0] != 'Mask-Data' ||
            x.data_levels[1] == 'Normal-Data',
        )
      ) {
        this.permissionmaskdata = false
      } else {
        this.permissionmaskdata = true
      }
    },
  },
}
</script>
<style lang="scss">
.from {
  .ant-select {
    width: -webkit-fill-available;
  }
  .ant-select-selector{
    border-color: #c9c9c9c9 !important;
  }
  input{
    border-color: #c9c9c9c9 !important;
  }
}
.nodata {
  color: #c4c4c4;
}
.imgnodata {
  width: 64px;
  margin: 0px auto;
}
.dropdownlist {
  height: 2.2rem !important;
}
.ant-timeline-item-head-blue {
  border-color: #666666 !important;
  background-color: #666666 !important;
}
.bg-input {
  background-color: #ffffff !important;
  border-radius: 4px !important;
  border: 1px solid #cecece !important;
}
.bg-input-disabled {
  background-color: #d9d9d96e !important;
  border-radius: 4px !important;
  border-color: #d9d9d9 !important;
  color: rgba(0, 0, 0, 0.25) !important;
  cursor: not-allowed !important;
}
.ant-collapse-content > .ant-collapse-content-box {
  padding: 6px 0px 16px 0px !important;
}
.btn-update {
  width: -webkit-fill-available !important;
  height: 50px !important;
}
.btn-add {
  background-color: #f5f9ff !important;
  color: #125eaf !important;
  border: dashed 1px #125eaf !important;
}
.btn-save-newpw {
  width: -webkit-fill-available !important;
  border-radius: 3px !important;
  background-color: #21a0fb !important;
  color: #ffffff !important;
}
.btn-deleteac {
  color: #ffffff;
  border-top-right-radius: 3px;
  border-top-left-radius: 3px;
  position: absolute;
  background-color: rgb(255, 105, 120);
  left: 0px;
  top: 0px;
  width: 100%;
}
.y-delete {
  border-radius: 3px;
  color: #ff6978 !important;
  text-decoration: underline !important;
}
.cancel-ac {
  color: #21a0fb !important;
  border-color: #21a0fb !important;
}
.text-h {
  font-weight: 600;
  color: #666;
}
.text-h-adress {
  color: #666666 !important;
}
.ant-collapse > .ant-collapse-item > .ant-collapse-header {
  color: #666666 !important;
}
.choose-map {
  width: -webkit-fill-available !important;
  border-color: #f79443 !important;
  height: 2.35rem !important;
  color: #f79443 !important;
}
.choose-map[disabled] {
  border-color: #d9d9d9 !important;
  color: #d9d9d9 !important;
  background-color: #ffffff !important;
}
.h-col {
  color: #4a4a4a;
}
</style>
